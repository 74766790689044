import { VFC } from "react";

import classNames from "classnames";

type ConditionalGridLayoutProps = {
  leftContent: JSX.Element;
  rightContent: JSX.Element;
  displayRightContent?: boolean;
};

/** Scrollable leftContent, and conditionally rendered right content */
const ConditionalGridLayout: VFC<ConditionalGridLayoutProps> = ({
  leftContent,
  rightContent,
  displayRightContent = false,
}) => (
  <div className="flex flex-col overflow-auto">
    <div
      className={classNames("p-4 grid gap-x-4", {
        "overflow-hidden": displayRightContent,
        "grid-cols-3": displayRightContent,
        "grid-cols-1": !displayRightContent,
      })}
    >
      {/* Left Content */}
      <div
        className={classNames("overflow-y-auto overflow-x-hidden flex items-center items-stretch", {
          "col-span-1": !displayRightContent,
          "col-span-2": displayRightContent,
          "justify-center": !displayRightContent,
          "justify-start": displayRightContent,
        })}
        style={{ scrollbarWidth: "thin" }}
      >
        <div
          className={classNames("mx-0.5", {
            "w-full": displayRightContent,
            "max-w-6xl": !displayRightContent,
          })}
        >
          {leftContent}
        </div>
      </div>

      {/* Right Content (conditionally rendered) */}
      <div className={classNames("flex col-span-1", { hidden: !displayRightContent })}>
        <div className="flex flex-grow">{rightContent}</div>
      </div>
    </div>
  </div>
);

export default ConditionalGridLayout;
