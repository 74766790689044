import { baseRestApi } from "api/rest/baseRestApi";
import { PaginatedQueryParams, SearchQueryParamsBase } from "api/rest/types/requests";
import { ApiResponsePaginatedCursor } from "api/rest/types/responses";
import { DateIsoString } from "types/DateIsoString";

type QueryParams = PaginatedQueryParams<
  SearchQueryParamsBase & {
    quoteRequestId?: number;
  }
>;

type QueryArgs = {
  queryParams: QueryParams;
};

type ConversationContact = {
  company_name: string | null | undefined;
  first_name: string | null | undefined;
  last_name: string | null | undefined;
  phone_number: string | null | undefined;
  email: string;
};

export type Conversation = {
  id: number;
  conversation_primary_contact?: ConversationContact;
  participants: ConversationContact[];
  lastMessageAt: DateIsoString | null;
};

type Response = ApiResponsePaginatedCursor<{
  conversations: Conversation[];
}>;

module.hot?.accept();

const findManyConversationsApi = baseRestApi.enhanceEndpoints({ addTagTypes: ["CONVERSATIONS"] }).injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (builder) => ({
    findManyConversations: builder.query<Response, QueryArgs>({
      query: ({ queryParams }) => ({
        url: `shipper/conversations/`,
        method: "GET",
        params: queryParams,
      }),
      providesTags: [{ type: "CONVERSATIONS" }],
    }),
  }),
});

export default findManyConversationsApi;
export const { useFindManyConversationsQuery } = findManyConversationsApi;
