import { ReactElement } from "react";

import { ApolloError } from "@apollo/client";
import { ObservableQuery } from "@apollo/client";
import { Box, Paper } from "@portex-pro/ui-components";
import QuoteRequestDetailsLTLView from "components/ltl/QuoteRequestDetailsLTLView";
import FeaturedBrokerDashboard from "features/featured-brokers/FeaturedBrokerDashboard";
import useLDFlag from "hooks/useLDFlag";
import { useTranslation } from "react-i18next";
import { isQuoteRequestStateTerminal } from "utils/isQuoteRequestStateTerminal";

import { PublicQuoteRequest, Query, QuoteRequestState } from "../../../../../api/types/generated-types";
import HtmlTitle from "../../../../../components/HtmlTitle";
import PortexAppBar from "../../../../../components/PortexAppBar";
import { useOnApolloError } from "../../../../../hooks/useOnApolloError";
import QuoteSubmissionClosed from "../QuoteSubmissionClosed";
import SubmitQuoteFormLTL from "./SubmitQuoteFormLTL";
import UrgentQuoteRequestAlert from "./UrgentQuoteRequestAlert";

const TOP_OF_PAGE_SELECTOR = "quote-submission-top-of-page";

const QuoteSubmissionLTL = ({
  publicQuoteRequest,
  refetchPublicQuoteRequest,
}: {
  publicQuoteRequest: PublicQuoteRequest;
  refetchPublicQuoteRequest: ObservableQuery<Pick<Query, "getPublicQuoteRequest">>["refetch"];
}): ReactElement => {
  const { t } = useTranslation("broker");
  const { onApolloError } = useOnApolloError({ componentName: "QuoteSubmissionLTL" });
  const enableBrokerAppBar = useLDFlag("enableBrokerAppBar");
  const enableBrokerQuotesPage = useLDFlag("enableBrokerQuotesPage");

  const { guid, deadline_respond_at, shipper_name, state } = publicQuoteRequest;
  const isTerminalState = isQuoteRequestStateTerminal(state);
  const quoteHasExpiration = !!deadline_respond_at;
  return (
    <>
      <HtmlTitle title={t("quoteSubmission.htmlTitle", { shipper_name })} />
      <PortexAppBar
        shipperName={shipper_name}
        useMarketingUrl
        hidePortexLogo={!!enableBrokerAppBar}
        backButtonTo={!!enableBrokerAppBar && !!enableBrokerQuotesPage && `/broker/quotes`}
      />

      <FeaturedBrokerDashboard quoteRequestGuid={publicQuoteRequest.guid}>
        <Box id={TOP_OF_PAGE_SELECTOR} />
        <>
          {isTerminalState ? <QuoteSubmissionClosed publicQuoteRequest={publicQuoteRequest} /> : null}
          {quoteHasExpiration && (
            <UrgentQuoteRequestAlert deadlineRespondAt={deadline_respond_at} booked={isTerminalState} />
          )}
          <Paper className={"Por-outlined-light"} elevation={8}>
            <QuoteRequestDetailsLTLView publicQuoteRequest={publicQuoteRequest} />
            <SubmitQuoteFormLTL
              quoteRequestGuid={guid}
              isTerminalState={isTerminalState}
              publicQuoteRequest={publicQuoteRequest}
              deadlineRespondAt={deadline_respond_at}
              onError={async (error: ApolloError) => {
                onApolloError("submitQuote")(error);
                const { data } = await refetchPublicQuoteRequest();
                if (
                  data.getPublicQuoteRequest?.state === QuoteRequestState.Booked ||
                  data.getPublicQuoteRequest?.state === QuoteRequestState.Closed ||
                  data.getPublicQuoteRequest?.state === QuoteRequestState.Canceled
                ) {
                  document.getElementById(TOP_OF_PAGE_SELECTOR)?.scrollIntoView({ behavior: "smooth" });
                }
              }}
            />
          </Paper>
        </>
      </FeaturedBrokerDashboard>
    </>
  );
};

export default QuoteSubmissionLTL;
