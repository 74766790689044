/* eslint-disable */
export type Optional<T> = T | undefined;
export type OptionalMaybe<T> = T | undefined | null;
export type MakeOptionalMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type RequiredNotNull<T> = Required<{ [P in keyof T]: NonNullable<T[P]> }>;
export type Ensure<T, K extends keyof T> = Omit<T, K> & RequiredNotNull<Pick<T, K>>;
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  GraphbackDateTime: Date;
};

/**
 * @db(name: 'accessorials')
 * @model(create: false, find: true, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type Accessorial = Node & {
  __typename?: 'Accessorial';
  id: Scalars['ID'];
  name: Scalars['String'];
  type?: Maybe<AccessorialType>;
  /** @oneToMany(field: 'accessorial', key: 'accessorial_id') */
  ltl_load_specs: Array<AccessorialsLtlLoadSpecs>;
  /** @oneToMany(field: 'accessorial', key: 'accessorial_id') */
  ltl_load_spec_templates: Array<AccessorialsLtlLoadSpecTemplates>;
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
};


/**
 * @db(name: 'accessorials')
 * @model(create: false, find: true, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type AccessorialLtl_Load_SpecsArgs = {
  filter?: Maybe<AccessorialsLtlLoadSpecsFilter>;
};


/**
 * @db(name: 'accessorials')
 * @model(create: false, find: true, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type AccessorialLtl_Load_Spec_TemplatesArgs = {
  filter?: Maybe<AccessorialsLtlLoadSpecTemplatesFilter>;
};

export type AccessorialFilter = {
  id?: Maybe<IdInput>;
  name?: Maybe<StringInput>;
  type?: Maybe<StringInput>;
  created_at?: Maybe<GraphbackDateTimeInput>;
  updated_at?: Maybe<GraphbackDateTimeInput>;
  and?: Maybe<Array<AccessorialFilter>>;
  or?: Maybe<Array<AccessorialFilter>>;
  not?: Maybe<AccessorialFilter>;
};

export type AccessorialResultList = {
  __typename?: 'AccessorialResultList';
  items: Array<Maybe<Accessorial>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
};

/**
 * @db(name: 'accessorials_ltl_load_specs')
 * @model(create: false, find: false, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type AccessorialsLtlLoadSpecs = {
  __typename?: 'AccessorialsLtlLoadSpecs';
  id: Scalars['ID'];
  /**
   * @manyToOne(field: 'accessorials_ltl_load_specs', key: 'shipper_id')
   * @index
   */
  shipper: Shipper;
  /**
   * @manyToOne(field: 'ltl_load_specs', key: 'accessorial_id')
   * @unique(name: 'accessorial_ltl_load_spec_unique')
   */
  accessorial: Accessorial;
  /**
   * @manyToOne(field: 'accessorials', key: 'ltl_load_spec_id')
   * @unique(name: 'accessorial_ltl_load_spec_unique')
   */
  ltl_load_spec: LtlLoadSpec;
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
};

export type AccessorialsLtlLoadSpecsFilter = {
  id?: Maybe<IdInput>;
  shipper_id?: Maybe<IdInput>;
  accessorial_id?: Maybe<IdInput>;
  ltl_load_spec_id?: Maybe<IdInput>;
  created_at?: Maybe<GraphbackDateTimeInput>;
  updated_at?: Maybe<GraphbackDateTimeInput>;
  and?: Maybe<Array<AccessorialsLtlLoadSpecsFilter>>;
  or?: Maybe<Array<AccessorialsLtlLoadSpecsFilter>>;
  not?: Maybe<AccessorialsLtlLoadSpecsFilter>;
};

/**
 * @db(name: 'accessorials_ltl_load_spec_templates')
 * @model(create: false, find: false, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type AccessorialsLtlLoadSpecTemplates = {
  __typename?: 'AccessorialsLtlLoadSpecTemplates';
  id: Scalars['ID'];
  /**
   * @manyToOne(field: 'accessorials_ltl_load_spec_templates', key: 'shipper_id')
   * @index
   */
  shipper: Shipper;
  /**
   * @manyToOne(field: 'ltl_load_spec_templates', key: 'accessorial_id')
   * @unique(name: 'accessorial_ltl_load_spec_template_unique')
   */
  accessorial: Accessorial;
  /**
   * @manyToOne(field: 'accessorials', key: 'ltl_load_spec_template_id')
   * @unique(name: 'accessorial_ltl_load_spec_template_unique')
   */
  ltl_load_spec_template: LtlLoadSpecTemplate;
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
};

export type AccessorialsLtlLoadSpecTemplatesFilter = {
  id?: Maybe<IdInput>;
  shipper_id?: Maybe<IdInput>;
  accessorial_id?: Maybe<IdInput>;
  ltl_load_spec_template_id?: Maybe<IdInput>;
  created_at?: Maybe<GraphbackDateTimeInput>;
  updated_at?: Maybe<GraphbackDateTimeInput>;
  and?: Maybe<Array<AccessorialsLtlLoadSpecTemplatesFilter>>;
  or?: Maybe<Array<AccessorialsLtlLoadSpecTemplatesFilter>>;
  not?: Maybe<AccessorialsLtlLoadSpecTemplatesFilter>;
};

export enum AccessorialType {
  Pickup = 'PICKUP',
  Delivery = 'DELIVERY'
}

/**
 * @db(name: 'addresses')
 * @model(create: false, find: true, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type Address = SoftDeletable & Node & {
  __typename?: 'Address';
  id: Scalars['ID'];
  /**
   * @db(type: 'text')
   * @default(value: 'UNKNOWN')
   */
  type: AddressType;
  /**
   * @manyToOne(field: 'addresses', key: 'shipper_id')
   * @index
   */
  shipper?: Maybe<Shipper>;
  name?: Maybe<Scalars['String']>;
  address_1?: Maybe<Scalars['String']>;
  address_2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  province_code?: Maybe<Scalars['String']>;
  province_name?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  country_name?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['String']>;
  lon?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  /** @oneToMany(field: 'address', key: 'address_id') */
  stops: Array<Stop>;
  /** @oneToMany(field: 'address', key: 'address_id') */
  stop_templates: Array<StopTemplate>;
  /** @oneToMany(field: 'address', key: 'address_id') */
  address_contacts: Array<AddressContact>;
  /** @oneToMany(field: 'origin_port', key: 'origin_port_id') */
  fcl_quotes_origin: Array<FclQuote>;
  /** @oneToMany(field: 'via_port', key: 'via_port_id') */
  fcl_quotes_via: Array<FclQuote>;
  /** @oneToMany(field: 'destination_port', key: 'destination_port_id') */
  fcl_quotes_destination: Array<FclQuote>;
  /** @oneToMany(field: 'destination_rail_ramp', key: 'destination_rail_ramp_id') */
  fcl_quotes_destination_rail_ramp: Array<FclQuote>;
  /** @oneToMany(field: 'origin_airport', key: 'origin_airport_id') */
  air_quotes_origin: Array<AirQuote>;
  /** @oneToMany(field: 'via_airport', key: 'via_airport_id') */
  air_quotes_via: Array<AirQuote>;
  /** @oneToMany(field: 'destination_airport', key: 'destination_airport_id') */
  air_quotes_destination: Array<AirQuote>;
  google_place_id?: Maybe<Scalars['String']>;
  /**
   * @summary This is the user-selected google places suggestion description: (see: google.maps.places.AutocompletePrediction)
   * @description This is exactly what the user saw when they clicked the option provided by google places autocomplete suggestions based on their search.
   * This is most useful for international addresses where the formatted address on the place result may be very different from the user selection on search.
   * @see https://developers.google.com/maps/documentation/places/web-service/autocomplete#PlaceAutocompletePrediction-description
   */
  google_place_description?: Maybe<Scalars['String']>;
  port_id?: Maybe<Scalars['String']>;
  port_name?: Maybe<Scalars['String']>;
  airport_id?: Maybe<Scalars['String']>;
  airport_name?: Maybe<Scalars['String']>;
  airport_iata_code?: Maybe<Scalars['String']>;
  /** @transient */
  formatted_long_name: Scalars['String'];
  /** @transient */
  formatted_short_name: Scalars['String'];
  /** @transient */
  iana_timezone: Scalars['String'];
  hours_start?: Maybe<Scalars['GraphbackDateTime']>;
  hours_end?: Maybe<Scalars['GraphbackDateTime']>;
  /** @manyToOne(field: 'deleted_addresses', key: 'deleted_by_id') */
  deleted_by?: Maybe<User>;
  /** @default(value: false) */
  is_created_by_broker?: Maybe<Scalars['Boolean']>;
  booking_notes: Scalars['String'];
  /** @index */
  deleted_at?: Maybe<Scalars['GraphbackDateTime']>;
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
};


/**
 * @db(name: 'addresses')
 * @model(create: false, find: true, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type AddressStopsArgs = {
  filter?: Maybe<StopFilter>;
};


/**
 * @db(name: 'addresses')
 * @model(create: false, find: true, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type AddressStop_TemplatesArgs = {
  filter?: Maybe<StopTemplateFilter>;
};


/**
 * @db(name: 'addresses')
 * @model(create: false, find: true, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type AddressAddress_ContactsArgs = {
  filter?: Maybe<AddressContactFilter>;
};


/**
 * @db(name: 'addresses')
 * @model(create: false, find: true, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type AddressFcl_Quotes_OriginArgs = {
  filter?: Maybe<FclQuoteFilter>;
};


/**
 * @db(name: 'addresses')
 * @model(create: false, find: true, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type AddressFcl_Quotes_ViaArgs = {
  filter?: Maybe<FclQuoteFilter>;
};


/**
 * @db(name: 'addresses')
 * @model(create: false, find: true, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type AddressFcl_Quotes_DestinationArgs = {
  filter?: Maybe<FclQuoteFilter>;
};


/**
 * @db(name: 'addresses')
 * @model(create: false, find: true, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type AddressFcl_Quotes_Destination_Rail_RampArgs = {
  filter?: Maybe<FclQuoteFilter>;
};


/**
 * @db(name: 'addresses')
 * @model(create: false, find: true, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type AddressAir_Quotes_OriginArgs = {
  filter?: Maybe<AirQuoteFilter>;
};


/**
 * @db(name: 'addresses')
 * @model(create: false, find: true, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type AddressAir_Quotes_ViaArgs = {
  filter?: Maybe<AirQuoteFilter>;
};


/**
 * @db(name: 'addresses')
 * @model(create: false, find: true, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type AddressAir_Quotes_DestinationArgs = {
  filter?: Maybe<AirQuoteFilter>;
};

/**
 * @db(name: 'address_contacts')
 * @model(create: false, find: false, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type AddressContact = Node & {
  __typename?: 'AddressContact';
  id: Scalars['ID'];
  /**
   * @manyToOne(field: 'address_contacts', key: 'shipper_id')
   * @index
   */
  shipper: Shipper;
  /**
   * @manyToOne(field: 'address_contacts', key: 'address_id')
   * @index
   */
  address: Address;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
};

export type AddressContactFilter = {
  id?: Maybe<IdInput>;
  shipper_id?: Maybe<IdInput>;
  address_id?: Maybe<IdInput>;
  first_name?: Maybe<StringInput>;
  last_name?: Maybe<StringInput>;
  phone_number?: Maybe<StringInput>;
  email?: Maybe<StringInput>;
  created_at?: Maybe<GraphbackDateTimeInput>;
  updated_at?: Maybe<GraphbackDateTimeInput>;
  and?: Maybe<Array<AddressContactFilter>>;
  or?: Maybe<Array<AddressContactFilter>>;
  not?: Maybe<AddressContactFilter>;
};

export type AddressContactPayload = {
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type AddressFilter = {
  id?: Maybe<IdInput>;
  type?: Maybe<StringInput>;
  shipper_id?: Maybe<IdInput>;
  name?: Maybe<StringInput>;
  address_1?: Maybe<StringInput>;
  address_2?: Maybe<StringInput>;
  city?: Maybe<StringInput>;
  province_code?: Maybe<StringInput>;
  province_name?: Maybe<StringInput>;
  zip?: Maybe<StringInput>;
  country_code?: Maybe<StringInput>;
  country_name?: Maybe<StringInput>;
  lat?: Maybe<StringInput>;
  lon?: Maybe<StringInput>;
  phone_number?: Maybe<StringInput>;
  google_place_id?: Maybe<StringInput>;
  google_place_description?: Maybe<StringInput>;
  port_id?: Maybe<StringInput>;
  port_name?: Maybe<StringInput>;
  airport_id?: Maybe<StringInput>;
  airport_name?: Maybe<StringInput>;
  airport_iata_code?: Maybe<StringInput>;
  hours_start?: Maybe<GraphbackDateTimeInput>;
  hours_end?: Maybe<GraphbackDateTimeInput>;
  deleted_by_id?: Maybe<IdInput>;
  is_created_by_broker?: Maybe<BooleanInput>;
  booking_notes?: Maybe<StringInput>;
  deleted_at?: Maybe<GraphbackDateTimeInput>;
  created_at?: Maybe<GraphbackDateTimeInput>;
  updated_at?: Maybe<GraphbackDateTimeInput>;
  and?: Maybe<Array<AddressFilter>>;
  or?: Maybe<Array<AddressFilter>>;
  not?: Maybe<AddressFilter>;
};

export type AddressResultList = {
  __typename?: 'AddressResultList';
  items: Array<Maybe<Address>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
};

export enum AddressType {
  Airport = 'AIRPORT',
  Seaport = 'SEAPORT',
  Railhead = 'RAILHEAD',
  Street = 'STREET',
  Unknown = 'UNKNOWN'
}

/** Incoterms specific to Mode.AIR */
export enum AirIncoterms {
  Fob = 'FOB',
  Exw = 'EXW',
  Fca = 'FCA',
  Cpt = 'CPT',
  Cip = 'CIP',
  Dap = 'DAP',
  Dpu = 'DPU',
  Ddp = 'DDP'
}

/**
 * @db(name: 'air_load_specs')
 * @model(create: false, find: false, findOne: false, update: true, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type AirLoadSpec = Node & {
  __typename?: 'AirLoadSpec';
  id: Scalars['ID'];
  /**
   * @manyToOne(field: 'air_load_specs', key: 'shipper_id')
   * @index
   */
  shipper: Shipper;
  /**
   * @index
   * @oneToOne(field: 'quote_request', key: 'quote_request_id')
   */
  quote_request: QuoteRequest;
  /** @oneToMany(field: 'air_load_spec', key: 'air_load_spec_id') */
  package_groups: Array<AirPackageGroup>;
  /** @db(type: 'text') */
  incoterms?: Maybe<AirIncoterms>;
  /** @db(type: 'text') */
  routing_type?: Maybe<RoutingType>;
  include_customs_clearance?: Maybe<Scalars['Boolean']>;
  /**
   * @db(type: 'json')
   * @default(value: [])
   */
  service_levels: Array<ServiceLevel>;
  total_weight?: Maybe<Scalars['Float']>;
  length_per_package?: Maybe<Scalars['Float']>;
  width_per_package?: Maybe<Scalars['Float']>;
  height_per_package?: Maybe<Scalars['Float']>;
  /**
   * @db(type: 'text')
   * @default(value: 'DIMENSIONS')
   */
  volume_format?: Maybe<VolumeFormat>;
  total_volume?: Maybe<Scalars['Float']>;
  item_quantity?: Maybe<Scalars['Int']>;
  /**
   * @db(type: 'text')
   * @default(value: 'KG')
   */
  weight_unit?: Maybe<WeightUnit>;
  /** @default(value: false) */
  is_overweight?: Maybe<Scalars['Boolean']>;
  temp?: Maybe<Scalars['Float']>;
  /**
   * @db(type: 'text')
   * @default(value: 'F')
   */
  temp_unit?: Maybe<TempUnit>;
  /** @db(type: 'text') */
  commodities?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
  cargo_ready_date?: Maybe<Scalars['GraphbackDateTime']>;
  target_delivery_date?: Maybe<Scalars['GraphbackDateTime']>;
};


/**
 * @db(name: 'air_load_specs')
 * @model(create: false, find: false, findOne: false, update: true, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type AirLoadSpecPackage_GroupsArgs = {
  filter?: Maybe<AirPackageGroupFilter>;
};

export type AirLoadSpecFilter = {
  id?: Maybe<IdInput>;
  shipper_id?: Maybe<IdInput>;
  quote_request_id?: Maybe<IdInput>;
  incoterms?: Maybe<StringInput>;
  routing_type?: Maybe<StringInput>;
  include_customs_clearance?: Maybe<BooleanInput>;
  service_levels?: Maybe<StringInput>;
  total_weight?: Maybe<FloatInput>;
  length_per_package?: Maybe<FloatInput>;
  width_per_package?: Maybe<FloatInput>;
  height_per_package?: Maybe<FloatInput>;
  volume_format?: Maybe<StringInput>;
  total_volume?: Maybe<FloatInput>;
  item_quantity?: Maybe<IntInput>;
  weight_unit?: Maybe<StringInput>;
  is_overweight?: Maybe<BooleanInput>;
  temp?: Maybe<FloatInput>;
  temp_unit?: Maybe<StringInput>;
  commodities?: Maybe<StringInput>;
  created_at?: Maybe<GraphbackDateTimeInput>;
  updated_at?: Maybe<GraphbackDateTimeInput>;
  cargo_ready_date?: Maybe<GraphbackDateTimeInput>;
  target_delivery_date?: Maybe<GraphbackDateTimeInput>;
  and?: Maybe<Array<AirLoadSpecFilter>>;
  or?: Maybe<Array<AirLoadSpecFilter>>;
  not?: Maybe<AirLoadSpecFilter>;
};

/**
 * @db(name: 'air_load_spec_templates')
 * @model(create: false, find: false, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type AirLoadSpecTemplate = Node & {
  __typename?: 'AirLoadSpecTemplate';
  id: Scalars['ID'];
  /**
   * @manyToOne(field: 'air_load_spec_templates', key: 'shipper_id')
   * @index
   */
  shipper: Shipper;
  /**
   * @index
   * @oneToOne(field: 'quote_request_template', key: 'quote_request_template_id')
   */
  quote_request_template: QuoteRequestTemplate;
  /** @oneToMany(field: 'air_load_spec_template', key: 'air_load_spec_template_id') */
  package_group_templates: Array<AirPackageGroupTemplate>;
  /** @db(type: 'text') */
  incoterms?: Maybe<AirIncoterms>;
  /** @db(type: 'text') */
  routing_type?: Maybe<RoutingType>;
  include_customs_clearance?: Maybe<Scalars['Boolean']>;
  /**
   * @db(type: 'json')
   * @default(value: [])
   */
  service_levels: Array<ServiceLevel>;
  total_weight?: Maybe<Scalars['Float']>;
  length_per_package?: Maybe<Scalars['Float']>;
  width_per_package?: Maybe<Scalars['Float']>;
  height_per_package?: Maybe<Scalars['Float']>;
  /**
   * @db(type: 'text')
   * @default(value: 'DIMENSIONS')
   */
  volume_format?: Maybe<VolumeFormat>;
  total_volume?: Maybe<Scalars['Float']>;
  item_quantity?: Maybe<Scalars['Int']>;
  /**
   * @db(type: 'text')
   * @default(value: 'KG')
   */
  weight_unit?: Maybe<WeightUnit>;
  /** @default(value: false) */
  is_overweight?: Maybe<Scalars['Boolean']>;
  temp?: Maybe<Scalars['Float']>;
  /**
   * @db(type: 'text')
   * @default(value: 'F')
   */
  temp_unit?: Maybe<TempUnit>;
  /** @db(type: 'text') */
  commodities?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
};


/**
 * @db(name: 'air_load_spec_templates')
 * @model(create: false, find: false, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type AirLoadSpecTemplatePackage_Group_TemplatesArgs = {
  filter?: Maybe<AirPackageGroupTemplateFilter>;
};

export type AirLoadSpecTemplateFilter = {
  id?: Maybe<IdInput>;
  shipper_id?: Maybe<IdInput>;
  quote_request_template_id?: Maybe<IdInput>;
  incoterms?: Maybe<StringInput>;
  routing_type?: Maybe<StringInput>;
  include_customs_clearance?: Maybe<BooleanInput>;
  service_levels?: Maybe<StringInput>;
  total_weight?: Maybe<FloatInput>;
  length_per_package?: Maybe<FloatInput>;
  width_per_package?: Maybe<FloatInput>;
  height_per_package?: Maybe<FloatInput>;
  volume_format?: Maybe<StringInput>;
  total_volume?: Maybe<FloatInput>;
  item_quantity?: Maybe<IntInput>;
  weight_unit?: Maybe<StringInput>;
  is_overweight?: Maybe<BooleanInput>;
  temp?: Maybe<FloatInput>;
  temp_unit?: Maybe<StringInput>;
  commodities?: Maybe<StringInput>;
  created_at?: Maybe<GraphbackDateTimeInput>;
  updated_at?: Maybe<GraphbackDateTimeInput>;
  and?: Maybe<Array<AirLoadSpecTemplateFilter>>;
  or?: Maybe<Array<AirLoadSpecTemplateFilter>>;
  not?: Maybe<AirLoadSpecTemplateFilter>;
};

/**
 * @db(name: 'air_package_groups')
 * @model(create: true, find: false, findOne: false, update: true, delete: true, subCreate: false, subUpdate: false, subDelete: false)
 */
export type AirPackageGroup = Node & {
  __typename?: 'AirPackageGroup';
  id: Scalars['ID'];
  /**
   * @manyToOne(field: 'air_package_groups', key: 'shipper_id')
   * @index
   */
  shipper: Shipper;
  /**
   * @manyToOne(field: 'package_groups', key: 'air_load_spec_id')
   * @index
   */
  air_load_spec: AirLoadSpec;
  /** @db(type: 'text') */
  packaging_type: PackagingType;
  /** @default(value: 1) */
  item_quantity: Scalars['Int'];
  weight_per_package: Scalars['Float'];
  length_per_package?: Maybe<Scalars['Float']>;
  height_per_package?: Maybe<Scalars['Float']>;
  width_per_package?: Maybe<Scalars['Float']>;
  /**
   * @db(type: 'text')
   * @default(value: 'VOLUME')
   */
  volume_format: VolumeFormat;
  volume_per_item?: Maybe<Scalars['Float']>;
  /** @db(type: 'text') */
  commodities?: Maybe<Scalars['String']>;
  /** @default(value: false) */
  is_hazardous?: Maybe<Scalars['Boolean']>;
  /** @db(type: 'text') */
  hazardous_goods_details?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
};

export type AirPackageGroupFilter = {
  id?: Maybe<IdInput>;
  shipper_id?: Maybe<IdInput>;
  air_load_spec_id?: Maybe<IdInput>;
  packaging_type?: Maybe<StringInput>;
  item_quantity?: Maybe<IntInput>;
  weight_per_package?: Maybe<FloatInput>;
  length_per_package?: Maybe<FloatInput>;
  height_per_package?: Maybe<FloatInput>;
  width_per_package?: Maybe<FloatInput>;
  volume_format?: Maybe<StringInput>;
  volume_per_item?: Maybe<FloatInput>;
  commodities?: Maybe<StringInput>;
  is_hazardous?: Maybe<BooleanInput>;
  hazardous_goods_details?: Maybe<StringInput>;
  created_at?: Maybe<GraphbackDateTimeInput>;
  updated_at?: Maybe<GraphbackDateTimeInput>;
  and?: Maybe<Array<AirPackageGroupFilter>>;
  or?: Maybe<Array<AirPackageGroupFilter>>;
  not?: Maybe<AirPackageGroupFilter>;
};

export type AirPackageGroupPayload = {
  packaging_type: PackagingType;
  item_quantity: Scalars['Int'];
  weight_per_package: Scalars['Float'];
  length_per_package?: Maybe<Scalars['Float']>;
  height_per_package?: Maybe<Scalars['Float']>;
  width_per_package?: Maybe<Scalars['Float']>;
  volume_format: VolumeFormat;
  volume_per_item?: Maybe<Scalars['Float']>;
};

/**
 * @db(name: 'air_package_group_templates')
 * @model(create: false, find: false, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type AirPackageGroupTemplate = Node & {
  __typename?: 'AirPackageGroupTemplate';
  id: Scalars['ID'];
  /**
   * @manyToOne(field: 'air_package_group_templates', key: 'shipper_id')
   * @index
   */
  shipper: Shipper;
  /**
   * @manyToOne(field: 'package_group_templates', key: 'air_load_spec_template_id')
   * @index
   */
  air_load_spec_template: AirLoadSpecTemplate;
  /** @db(type: 'text') */
  packaging_type: PackagingType;
  /** @default(value: 1) */
  item_quantity: Scalars['Int'];
  weight_per_package: Scalars['Float'];
  length_per_package?: Maybe<Scalars['Float']>;
  height_per_package?: Maybe<Scalars['Float']>;
  width_per_package?: Maybe<Scalars['Float']>;
  /**
   * @db(type: 'text')
   * @default(value: 'VOLUME')
   */
  volume_format: VolumeFormat;
  volume_per_item?: Maybe<Scalars['Float']>;
  /** @db(type: 'text') */
  commodities?: Maybe<Scalars['String']>;
  /** @default(value: false) */
  is_hazardous?: Maybe<Scalars['Boolean']>;
  /** @db(type: 'text') */
  hazardous_goods_details?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
};

export type AirPackageGroupTemplateFilter = {
  id?: Maybe<IdInput>;
  shipper_id?: Maybe<IdInput>;
  air_load_spec_template_id?: Maybe<IdInput>;
  packaging_type?: Maybe<StringInput>;
  item_quantity?: Maybe<IntInput>;
  weight_per_package?: Maybe<FloatInput>;
  length_per_package?: Maybe<FloatInput>;
  height_per_package?: Maybe<FloatInput>;
  width_per_package?: Maybe<FloatInput>;
  volume_format?: Maybe<StringInput>;
  volume_per_item?: Maybe<FloatInput>;
  commodities?: Maybe<StringInput>;
  is_hazardous?: Maybe<BooleanInput>;
  hazardous_goods_details?: Maybe<StringInput>;
  created_at?: Maybe<GraphbackDateTimeInput>;
  updated_at?: Maybe<GraphbackDateTimeInput>;
  and?: Maybe<Array<AirPackageGroupTemplateFilter>>;
  or?: Maybe<Array<AirPackageGroupTemplateFilter>>;
  not?: Maybe<AirPackageGroupTemplateFilter>;
};

/**
 * @db(name: 'airports')
 * @model(create: false, find: true, findOne: true, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type Airport = Node & {
  __typename?: 'Airport';
  id: Scalars['ID'];
  name: Scalars['String'];
  iata_code: Scalars['String'];
  lowercase_name: Scalars['String'];
  lowercase_iata_code: Scalars['String'];
  country_name?: Maybe<Scalars['String']>;
  country_code: Scalars['String'];
  city: Scalars['String'];
  province_code: Scalars['String'];
  province_name?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['String']>;
  lon?: Maybe<Scalars['String']>;
  /** @transient */
  iana_timezone: Scalars['String'];
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
};

export type AirportFilter = {
  id?: Maybe<IdInput>;
  name?: Maybe<StringInput>;
  iata_code?: Maybe<StringInput>;
  lowercase_name?: Maybe<StringInput>;
  lowercase_iata_code?: Maybe<StringInput>;
  country_name?: Maybe<StringInput>;
  country_code?: Maybe<StringInput>;
  city?: Maybe<StringInput>;
  province_code?: Maybe<StringInput>;
  province_name?: Maybe<StringInput>;
  lat?: Maybe<StringInput>;
  lon?: Maybe<StringInput>;
  created_at?: Maybe<GraphbackDateTimeInput>;
  updated_at?: Maybe<GraphbackDateTimeInput>;
  and?: Maybe<Array<AirportFilter>>;
  or?: Maybe<Array<AirportFilter>>;
  not?: Maybe<AirportFilter>;
};

export type AirportResultList = {
  __typename?: 'AirportResultList';
  items: Array<Maybe<Airport>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
};

/**
 * @db(name: 'air_quotes')
 * @model(create: false, find: false, findOne: true, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type AirQuote = Node & {
  __typename?: 'AirQuote';
  id: Scalars['ID'];
  /**
   * @manyToOne(field: 'air_quotes', key: 'shipper_id')
   * @index
   */
  shipper: Shipper;
  /**
   * @manyToOne(field: 'air_quotes', key: 'quote_request_id')
   * @index
   */
  quote_request: QuoteRequest;
  submitter_email: Scalars['String'];
  total_amount?: Maybe<Scalars['Float']>;
  rate_per_kg?: Maybe<Scalars['Float']>;
  volumetric_weight: Scalars['Float'];
  dim_factor: Scalars['Float'];
  valid_until: Scalars['GraphbackDateTime'];
  submitter_tz?: Maybe<Scalars['String']>;
  /**
   * @db(type: 'text')
   * @default(value: 'DRAFT')
   */
  status: QuoteStatus;
  company_name?: Maybe<Scalars['String']>;
  /** @db(type: 'text') */
  notes?: Maybe<Scalars['String']>;
  /** @db(type: 'text') */
  booking_notes?: Maybe<Scalars['String']>;
  /** @db(type: 'text') */
  notes_charges_insurance?: Maybe<Scalars['String']>;
  /** @db(type: 'text') */
  notes_charges_miscellaneous?: Maybe<Scalars['String']>;
  service_level: ServiceLevel;
  /** @manyToOne(field: 'air_quotes_origin', key: 'origin_airport_id') */
  origin_airport: Address;
  /** @manyToOne(field: 'air_quotes_via', key: 'via_airport_id') */
  via_airport?: Maybe<Address>;
  /** @manyToOne(field: 'air_quotes_destination', key: 'destination_airport_id') */
  destination_airport: Address;
  min_transit_time?: Maybe<Scalars['Int']>;
  max_transit_time?: Maybe<Scalars['Int']>;
  carrier?: Maybe<Scalars['String']>;
  /** @oneToMany(field: 'quote', key: 'quote_id') */
  quote_charges: Array<AirQuoteCharge>;
  /** @transient */
  quote_charges_totals: QuoteChargeTotals;
  /** @transient */
  chargeable_weight: Scalars['Float'];
  /** @oneToOne(field: 'load', key: 'load_id') */
  load?: Maybe<Load>;
  /** @transient */
  conversation_id?: Maybe<Scalars['ID']>;
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
};


/**
 * @db(name: 'air_quotes')
 * @model(create: false, find: false, findOne: true, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type AirQuoteQuote_ChargesArgs = {
  filter?: Maybe<AirQuoteChargeFilter>;
};

export type AirQuoteAirportPayload = {
  id?: Maybe<Scalars['ID']>;
  type?: Maybe<AddressType>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  province_code?: Maybe<Scalars['String']>;
  province_name?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  country_name?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['String']>;
  lon?: Maybe<Scalars['String']>;
  airport_id?: Maybe<Scalars['String']>;
  airport_name?: Maybe<Scalars['String']>;
  airport_iata_code?: Maybe<Scalars['String']>;
};

/**
 * @db(name: 'air_quote_charges')
 * @model(create: false, find: false, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type AirQuoteCharge = Node & {
  __typename?: 'AirQuoteCharge';
  id: Scalars['ID'];
  /**
   * @manyToOne(field: 'air_quote_charges', key: 'shipper_id')
   * @index
   */
  shipper: Shipper;
  /**
   * @manyToOne(field: 'air_quote_charges', key: 'quote_request_id')
   * @index
   */
  quote_request: QuoteRequest;
  /**
   * @manyToOne(field: 'quote_charges', key: 'quote_id')
   * @index
   */
  quote: AirQuote;
  name: Scalars['String'];
  type: ChargeType;
  /** item quantity * weight */
  quantity: Scalars['Float'];
  item_quantity?: Maybe<Scalars['Int']>;
  unit: ChargeUnitType;
  rate: Scalars['Float'];
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
};

export type AirQuoteChargeFilter = {
  id?: Maybe<IdInput>;
  shipper_id?: Maybe<IdInput>;
  quote_request_id?: Maybe<IdInput>;
  quote_id?: Maybe<IdInput>;
  name?: Maybe<StringInput>;
  type?: Maybe<StringInput>;
  quantity?: Maybe<FloatInput>;
  item_quantity?: Maybe<IntInput>;
  unit?: Maybe<StringInput>;
  rate?: Maybe<FloatInput>;
  created_at?: Maybe<GraphbackDateTimeInput>;
  updated_at?: Maybe<GraphbackDateTimeInput>;
  and?: Maybe<Array<AirQuoteChargeFilter>>;
  or?: Maybe<Array<AirQuoteChargeFilter>>;
  not?: Maybe<AirQuoteChargeFilter>;
};

export type AirQuoteChargePayload = {
  name: Scalars['String'];
  type: ChargeType;
  /** item quantity * weight */
  quantity: Scalars['Float'];
  item_quantity?: Maybe<Scalars['Int']>;
  unit: ChargeUnitType;
  rate: Scalars['Float'];
};

export type AirQuoteFilter = {
  id?: Maybe<IdInput>;
  shipper_id?: Maybe<IdInput>;
  quote_request_id?: Maybe<IdInput>;
  submitter_email?: Maybe<StringInput>;
  total_amount?: Maybe<FloatInput>;
  rate_per_kg?: Maybe<FloatInput>;
  volumetric_weight?: Maybe<FloatInput>;
  dim_factor?: Maybe<FloatInput>;
  valid_until?: Maybe<GraphbackDateTimeInput>;
  submitter_tz?: Maybe<StringInput>;
  status?: Maybe<StringInput>;
  company_name?: Maybe<StringInput>;
  notes?: Maybe<StringInput>;
  booking_notes?: Maybe<StringInput>;
  notes_charges_insurance?: Maybe<StringInput>;
  notes_charges_miscellaneous?: Maybe<StringInput>;
  service_level?: Maybe<StringInput>;
  origin_airport_id?: Maybe<IdInput>;
  via_airport_id?: Maybe<IdInput>;
  destination_airport_id?: Maybe<IdInput>;
  min_transit_time?: Maybe<IntInput>;
  max_transit_time?: Maybe<IntInput>;
  carrier?: Maybe<StringInput>;
  load_id?: Maybe<IdInput>;
  created_at?: Maybe<GraphbackDateTimeInput>;
  updated_at?: Maybe<GraphbackDateTimeInput>;
  and?: Maybe<Array<AirQuoteFilter>>;
  or?: Maybe<Array<AirQuoteFilter>>;
  not?: Maybe<AirQuoteFilter>;
};

export type AirQuoteTotals = {
  __typename?: 'AirQuoteTotals';
  total_amount: Scalars['Float'];
  rate_per_kg: Scalars['Float'];
};

export type BookQuoteInput = {
  quote_id: Scalars['String'];
  quote_request_id: Scalars['ID'];
  booking_notes?: Maybe<Scalars['String']>;
};

export type BooleanInput = {
  ne?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<Scalars['Boolean']>;
};

export type CancelQuoteRequestInput = {
  id: Scalars['ID'];
  /** @description user input reason for canceling the shipment. Optional and can be empty */
  reason?: Maybe<Scalars['String']>;
};

export type CargoDetailsAir = {
  perPackageHeight?: Maybe<Scalars['Float']>;
  perPackageLength?: Maybe<Scalars['Float']>;
  perPackageWidth?: Maybe<Scalars['Float']>;
  totalItems: Scalars['Int'];
  totalVolume?: Maybe<Scalars['Float']>;
  totalWeight: Scalars['Float'];
  volumeFormat: VolumeFormat;
};

export enum ChargeType {
  Customs = 'CUSTOMS',
  Destination = 'DESTINATION',
  Freight = 'FREIGHT',
  Insurance = 'INSURANCE',
  Origin = 'ORIGIN'
}

export enum ChargeUnitType {
  Container = 'CONTAINER',
  Shipment = 'SHIPMENT',
  Kg = 'KG'
}

export type CloseQuoteRequestInput = {
  id: Scalars['ID'];
  reason: CloseQuoteRequestReason;
  other_reason?: Maybe<Scalars['String']>;
  send_notification?: Maybe<Scalars['Boolean']>;
};

export enum CloseQuoteRequestReason {
  BookedOffPlatform = 'BOOKED_OFF_PLATFORM',
  Other = 'OTHER'
}

export type Company = {
  id: Scalars['ID'];
  /** @index */
  name: Scalars['String'];
  phone_number?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
};

export enum CompanyType {
  Shipper = 'SHIPPER'
}

/**
 * @db(name: 'contacts')
 * @model(create: false, find: true, findOne: true, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type Contact = SoftDeletable & Node & {
  __typename?: 'Contact';
  id: Scalars['ID'];
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  company_name: Scalars['String'];
  phone_number?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  /** @manyToOne(field: 'contacts', key: 'user_id') */
  user: User;
  /** @default(value: false) */
  is_from_chat: Scalars['Boolean'];
  /** @default(value: false) */
  is_internal?: Maybe<Scalars['Boolean']>;
  /**
   * @manyToOne(field: 'contacts', key: 'shipper_id')
   * @index
   */
  shipper?: Maybe<Shipper>;
  /** @oneToMany(field: 'contact', key: 'contact_id') */
  quote_requests: Array<ContactsQuoteRequests>;
  /** @manyToOne(field: 'deleted_contacts', key: 'deleted_by_id') */
  deleted_by?: Maybe<User>;
  /** @transient */
  tags: Array<Tag>;
  /** @index */
  deleted_at?: Maybe<Scalars['GraphbackDateTime']>;
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
};


/**
 * @db(name: 'contacts')
 * @model(create: false, find: true, findOne: true, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type ContactQuote_RequestsArgs = {
  filter?: Maybe<ContactsQuoteRequestsFilter>;
};

export type ContactFilter = {
  id?: Maybe<IdInput>;
  first_name?: Maybe<StringInput>;
  last_name?: Maybe<StringInput>;
  company_name?: Maybe<StringInput>;
  phone_number?: Maybe<StringInput>;
  city?: Maybe<StringInput>;
  state?: Maybe<StringInput>;
  user_id?: Maybe<IdInput>;
  is_from_chat?: Maybe<BooleanInput>;
  is_internal?: Maybe<BooleanInput>;
  shipper_id?: Maybe<IdInput>;
  deleted_by_id?: Maybe<IdInput>;
  deleted_at?: Maybe<GraphbackDateTimeInput>;
  created_at?: Maybe<GraphbackDateTimeInput>;
  updated_at?: Maybe<GraphbackDateTimeInput>;
  and?: Maybe<Array<ContactFilter>>;
  or?: Maybe<Array<ContactFilter>>;
  not?: Maybe<ContactFilter>;
};

export type ContactResultList = {
  __typename?: 'ContactResultList';
  items: Array<Maybe<Contact>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
};

/**
 * @db(name: 'contacts_quote_requests')
 * @model(create: true, find: true, findOne: false, update: false, delete: true, subCreate: false, subUpdate: false, subDelete: false)
 */
export type ContactsQuoteRequests = {
  __typename?: 'ContactsQuoteRequests';
  id: Scalars['ID'];
  /**
   * @manyToOne(field: 'quote_requests', key: 'contact_id')
   * @index
   * @unique(name: 'contact_quote_request_unique')
   */
  contact: Contact;
  /**
   * @manyToOne(field: 'recipients', key: 'quote_request_id')
   * @index
   * @unique(name: 'contact_quote_request_unique')
   */
  quote_request: QuoteRequest;
  /**
   * @manyToOne(field: 'contacts_quote_requests', key: 'shipper_id')
   * @index
   */
  shipper: Shipper;
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
};

export type ContactsQuoteRequestsFilter = {
  id?: Maybe<IdInput>;
  contact_id?: Maybe<IdInput>;
  quote_request_id?: Maybe<IdInput>;
  shipper_id?: Maybe<IdInput>;
  created_at?: Maybe<GraphbackDateTimeInput>;
  updated_at?: Maybe<GraphbackDateTimeInput>;
  and?: Maybe<Array<ContactsQuoteRequestsFilter>>;
  or?: Maybe<Array<ContactsQuoteRequestsFilter>>;
  not?: Maybe<ContactsQuoteRequestsFilter>;
};

export type ContactsQuoteRequestsResultList = {
  __typename?: 'ContactsQuoteRequestsResultList';
  items: Array<Maybe<ContactsQuoteRequests>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
};

/**
 * @db(name: 'containers')
 * @model(create: true, find: false, findOne: false, update: true, delete: true, subCreate: false, subUpdate: false, subDelete: false)
 */
export type Container = Node & {
  __typename?: 'Container';
  id: Scalars['ID'];
  /**
   * @manyToOne(field: 'containers', key: 'shipper_id')
   * @index
   */
  shipper: Shipper;
  /**
   * @manyToOne(field: 'containers', key: 'fcl_load_spec_id')
   * @index
   */
  fcl_load_spec: FclLoadSpec;
  /** @db(type: 'text') */
  size?: Maybe<ContainerSize>;
  /** @db(type: 'text') */
  type?: Maybe<ContainerType>;
  max_temp?: Maybe<Scalars['Float']>;
  min_temp?: Maybe<Scalars['Float']>;
  temp?: Maybe<Scalars['Float']>;
  /**
   * @db(type: 'text')
   * @default(value: 'F')
   */
  temp_unit?: Maybe<TempUnit>;
  /** @default(value: false) */
  is_hazardous?: Maybe<Scalars['Boolean']>;
  /** @default(value: false) */
  requires_genset?: Maybe<Scalars['Boolean']>;
  volume?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
};

export type ContainerFilter = {
  id?: Maybe<IdInput>;
  shipper_id?: Maybe<IdInput>;
  fcl_load_spec_id?: Maybe<IdInput>;
  size?: Maybe<StringInput>;
  type?: Maybe<StringInput>;
  max_temp?: Maybe<FloatInput>;
  min_temp?: Maybe<FloatInput>;
  temp?: Maybe<FloatInput>;
  temp_unit?: Maybe<StringInput>;
  is_hazardous?: Maybe<BooleanInput>;
  requires_genset?: Maybe<BooleanInput>;
  volume?: Maybe<FloatInput>;
  weight?: Maybe<FloatInput>;
  created_at?: Maybe<GraphbackDateTimeInput>;
  updated_at?: Maybe<GraphbackDateTimeInput>;
  and?: Maybe<Array<ContainerFilter>>;
  or?: Maybe<Array<ContainerFilter>>;
  not?: Maybe<ContainerFilter>;
};

export type ContainerPayload = {
  size?: Maybe<ContainerSize>;
  type?: Maybe<ContainerType>;
  max_temp?: Maybe<Scalars['Float']>;
  min_temp?: Maybe<Scalars['Float']>;
  requires_genset?: Maybe<Scalars['Boolean']>;
  volume?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

export enum ContainerSize {
  C_20 = 'C_20',
  C_40 = 'C_40',
  C_20Hc = 'C_20_HC',
  C_40Hc = 'C_40_HC',
  C_45Hc = 'C_45_HC'
}

/**
 * @db(name: 'container_templates')
 * @model(create: false, find: false, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type ContainerTemplate = Node & {
  __typename?: 'ContainerTemplate';
  id: Scalars['ID'];
  /**
   * @manyToOne(field: 'container_templates', key: 'shipper_id')
   * @index
   */
  shipper: Shipper;
  /**
   * @manyToOne(field: 'container_templates', key: 'fcl_load_spec_template_id')
   * @index
   */
  fcl_load_spec_template: FclLoadSpecTemplate;
  /** @db(type: 'text') */
  size?: Maybe<ContainerSize>;
  /** @db(type: 'text') */
  type?: Maybe<ContainerType>;
  max_temp?: Maybe<Scalars['Float']>;
  min_temp?: Maybe<Scalars['Float']>;
  temp?: Maybe<Scalars['Float']>;
  /**
   * @db(type: 'text')
   * @default(value: 'F')
   */
  temp_unit?: Maybe<TempUnit>;
  /** @default(value: false) */
  is_hazardous?: Maybe<Scalars['Boolean']>;
  /** @default(value: false) */
  requires_genset?: Maybe<Scalars['Boolean']>;
  volume?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
};

export type ContainerTemplateFilter = {
  id?: Maybe<IdInput>;
  shipper_id?: Maybe<IdInput>;
  fcl_load_spec_template_id?: Maybe<IdInput>;
  size?: Maybe<StringInput>;
  type?: Maybe<StringInput>;
  max_temp?: Maybe<FloatInput>;
  min_temp?: Maybe<FloatInput>;
  temp?: Maybe<FloatInput>;
  temp_unit?: Maybe<StringInput>;
  is_hazardous?: Maybe<BooleanInput>;
  requires_genset?: Maybe<BooleanInput>;
  volume?: Maybe<FloatInput>;
  weight?: Maybe<FloatInput>;
  created_at?: Maybe<GraphbackDateTimeInput>;
  updated_at?: Maybe<GraphbackDateTimeInput>;
  and?: Maybe<Array<ContainerTemplateFilter>>;
  or?: Maybe<Array<ContainerTemplateFilter>>;
  not?: Maybe<ContainerTemplateFilter>;
};

export enum ContainerType {
  Dry = 'DRY',
  Reefer = 'REEFER',
  OpenTop = 'OPEN_TOP',
  FlatRack = 'FLAT_RACK',
  Platform = 'PLATFORM'
}

export type CreateAddressInput = {
  shipper_id?: Maybe<Scalars['ID']>;
  type?: Maybe<AddressType>;
  name?: Maybe<Scalars['String']>;
  address_1?: Maybe<Scalars['String']>;
  address_2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  province_code?: Maybe<Scalars['String']>;
  province_name?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  country_name?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['String']>;
  lon?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  google_place_id?: Maybe<Scalars['String']>;
  google_place_description?: Maybe<Scalars['String']>;
  port_id?: Maybe<Scalars['String']>;
  port_name?: Maybe<Scalars['String']>;
  airport_id?: Maybe<Scalars['String']>;
  airport_name?: Maybe<Scalars['String']>;
  airport_iata_code?: Maybe<Scalars['String']>;
  hours_start?: Maybe<Scalars['GraphbackDateTime']>;
  hours_end?: Maybe<Scalars['GraphbackDateTime']>;
  booking_notes?: Maybe<Scalars['String']>;
};

export type CreateAirPackageGroupInput = {
  shipper_id: Scalars['ID'];
  air_load_spec_id: Scalars['ID'];
  packaging_type: PackagingType;
  item_quantity: Scalars['Int'];
  weight_per_package: Scalars['Float'];
  length_per_package?: Maybe<Scalars['Float']>;
  height_per_package?: Maybe<Scalars['Float']>;
  width_per_package?: Maybe<Scalars['Float']>;
  volume_format: VolumeFormat;
  volume_per_item?: Maybe<Scalars['Float']>;
  commodities?: Maybe<Scalars['String']>;
  is_hazardous?: Maybe<Scalars['Boolean']>;
  hazardous_goods_details?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
};

export type CreateContactInput = {
  email: Scalars['String'];
  company_name: Scalars['String'];
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  is_internal?: Maybe<Scalars['Boolean']>;
  tag_ids?: Maybe<Array<Scalars['ID']>>;
};

export type CreateContactsQuoteRequestsInput = {
  contact_id: Scalars['ID'];
  quote_request_id: Scalars['ID'];
  shipper_id: Scalars['ID'];
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
};

export type CreateContainerInput = {
  shipper_id: Scalars['ID'];
  fcl_load_spec_id: Scalars['ID'];
  size?: Maybe<ContainerSize>;
  type?: Maybe<ContainerType>;
  max_temp?: Maybe<Scalars['Float']>;
  min_temp?: Maybe<Scalars['Float']>;
  temp?: Maybe<Scalars['Float']>;
  temp_unit?: Maybe<TempUnit>;
  is_hazardous?: Maybe<Scalars['Boolean']>;
  requires_genset?: Maybe<Scalars['Boolean']>;
  volume?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
};

export type CreateDrayageLoadSpecInput = {
  shipper_id: Scalars['ID'];
  quote_request_id: Scalars['ID'];
  container_size?: Maybe<ContainerSize>;
  container_type?: Maybe<ContainerType>;
  commodities?: Maybe<Scalars['String']>;
  max_temp?: Maybe<Scalars['Float']>;
  min_temp?: Maybe<Scalars['Float']>;
  temp?: Maybe<Scalars['Float']>;
  temp_unit?: Maybe<TempUnit>;
  is_hazardous?: Maybe<Scalars['Boolean']>;
  requires_genset?: Maybe<Scalars['Boolean']>;
  total_weight?: Maybe<Scalars['Float']>;
  container_number?: Maybe<Scalars['String']>;
  ocean_bol_number?: Maybe<Scalars['String']>;
  weight_unit?: Maybe<WeightUnit>;
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
};

export type CreatePackageGroupInput = {
  shipper_id: Scalars['ID'];
  ltl_load_spec_id: Scalars['ID'];
  packaging_type?: Maybe<PackagingType>;
  packing_count?: Maybe<Scalars['Int']>;
  pallet_count?: Maybe<Scalars['Int']>;
  freight_class?: Maybe<FreightClass>;
  nmfc_code?: Maybe<Scalars['String']>;
  is_stackable?: Maybe<Scalars['Boolean']>;
  item_quantity: Scalars['Int'];
  weight_per_package?: Maybe<Scalars['Float']>;
  length_per_package?: Maybe<Scalars['Float']>;
  height_per_package?: Maybe<Scalars['Float']>;
  width_per_package?: Maybe<Scalars['Float']>;
  dim_unit: DimUnit;
  commodities?: Maybe<Scalars['String']>;
  is_hazardous?: Maybe<Scalars['Boolean']>;
  hazardous_goods_details?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
};

export type CreatePartnerTagInput = {
  tag: Scalars['String'];
};

export type CreatePreviewPublicQuoteRequestLinkInput = {
  quoteRequestId: Scalars['ID'];
};

export type CreateQuoteRequestFromExistingQuoteRequestInput = {
  quoteRequestId: Scalars['ID'];
};

export type CreateQuoteRequestTemplateFromQuoteRequestInput = {
  quoteRequestId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type CreateQuoteSubmissionLinkInput = {
  quoteRequestId: Scalars['ID'];
};

export type CreateShipperInput = {
  name: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type CreateStopInput = {
  shipper_id: Scalars['ID'];
  address_id?: Maybe<Scalars['ID']>;
  quote_request_id: Scalars['ID'];
  position: Scalars['Int'];
  note?: Maybe<Scalars['String']>;
  booking_notes: Scalars['String'];
  is_time_tbd?: Maybe<Scalars['Boolean']>;
  is_na?: Maybe<Scalars['Boolean']>;
  start?: Maybe<Scalars['GraphbackDateTime']>;
  end?: Maybe<Scalars['GraphbackDateTime']>;
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
  reference_number?: Maybe<Scalars['String']>;
  distance_from_prior_stop?: Maybe<Scalars['Int']>;
};

export type DataCell = {
  __typename?: 'DataCell';
  valueRaw: Scalars['String'];
  valueFormatted?: Maybe<Scalars['String']>;
};

export type DataRow = {
  __typename?: 'DataRow';
  /** @deprecated use 'cells' instead */
  fields: Array<Maybe<Scalars['String']>>;
  cells: Array<Maybe<DataCell>>;
};

export type DataTable = {
  __typename?: 'DataTable';
  column_names: Array<Maybe<Scalars['String']>>;
  rows: Array<DataRow>;
};

export type DateCount = {
  __typename?: 'DateCount';
  date: Scalars['GraphbackDateTime'];
  count: Scalars['Int'];
};

export type DeleteAddress = {
  id: Scalars['ID'];
};

export type DeleteContactInput = {
  id: Scalars['ID'];
};

export type DeleteFileInput = {
  id: Scalars['ID'];
};

export enum DimUnit {
  In = 'IN',
  Cm = 'CM'
}

/**
 * @db(name: 'drayage_load_specs')
 * @model(create: true, find: false, findOne: false, update: true, delete: true, subCreate: false, subUpdate: false, subDelete: false)
 */
export type DrayageLoadSpec = Node & {
  __typename?: 'DrayageLoadSpec';
  id: Scalars['ID'];
  /**
   * @manyToOne(field: 'drayage_load_specs', key: 'shipper_id')
   * @index
   */
  shipper: Shipper;
  /**
   * @manyToOne(field: 'drayage_load_specs', key: 'quote_request_id')
   * @index
   */
  quote_request: QuoteRequest;
  /** @db(type: 'text') */
  container_size?: Maybe<ContainerSize>;
  /** @db(type: 'text') */
  container_type?: Maybe<ContainerType>;
  /** @db(type: 'text') */
  commodities?: Maybe<Scalars['String']>;
  max_temp?: Maybe<Scalars['Float']>;
  min_temp?: Maybe<Scalars['Float']>;
  temp?: Maybe<Scalars['Float']>;
  /**
   * @db(type: 'text')
   * @default(value: 'F')
   */
  temp_unit?: Maybe<TempUnit>;
  /** @default(value: false) */
  is_hazardous?: Maybe<Scalars['Boolean']>;
  /** @default(value: false) */
  requires_genset?: Maybe<Scalars['Boolean']>;
  total_weight?: Maybe<Scalars['Float']>;
  container_number?: Maybe<Scalars['String']>;
  ocean_bol_number?: Maybe<Scalars['String']>;
  /**
   * @db(type: 'text')
   * @default(value: 'KG')
   */
  weight_unit?: Maybe<WeightUnit>;
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
};

export type DrayageLoadSpecFilter = {
  id?: Maybe<IdInput>;
  shipper_id?: Maybe<IdInput>;
  quote_request_id?: Maybe<IdInput>;
  container_size?: Maybe<StringInput>;
  container_type?: Maybe<StringInput>;
  commodities?: Maybe<StringInput>;
  max_temp?: Maybe<FloatInput>;
  min_temp?: Maybe<FloatInput>;
  temp?: Maybe<FloatInput>;
  temp_unit?: Maybe<StringInput>;
  is_hazardous?: Maybe<BooleanInput>;
  requires_genset?: Maybe<BooleanInput>;
  total_weight?: Maybe<FloatInput>;
  container_number?: Maybe<StringInput>;
  ocean_bol_number?: Maybe<StringInput>;
  weight_unit?: Maybe<StringInput>;
  created_at?: Maybe<GraphbackDateTimeInput>;
  updated_at?: Maybe<GraphbackDateTimeInput>;
  and?: Maybe<Array<DrayageLoadSpecFilter>>;
  or?: Maybe<Array<DrayageLoadSpecFilter>>;
  not?: Maybe<DrayageLoadSpecFilter>;
};

/**
 * @db(name: 'drayage_load_spec_templates')
 * @model(create: false, find: false, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type DrayageLoadSpecTemplate = Node & {
  __typename?: 'DrayageLoadSpecTemplate';
  id: Scalars['ID'];
  /**
   * @manyToOne(field: 'drayage_load_spec_templates', key: 'shipper_id')
   * @index
   */
  shipper: Shipper;
  /**
   * @manyToOne(field: 'drayage_load_spec_templates', key: 'quote_request_template_id')
   * @index
   */
  quote_request_template: QuoteRequestTemplate;
  /** @db(type: 'text') */
  container_size?: Maybe<ContainerSize>;
  /** @db(type: 'text') */
  container_type?: Maybe<ContainerType>;
  /** @db(type: 'text') */
  commodities?: Maybe<Scalars['String']>;
  max_temp?: Maybe<Scalars['Float']>;
  min_temp?: Maybe<Scalars['Float']>;
  temp?: Maybe<Scalars['Float']>;
  /**
   * @db(type: 'text')
   * @default(value: 'F')
   */
  temp_unit?: Maybe<TempUnit>;
  /** @default(value: false) */
  is_hazardous?: Maybe<Scalars['Boolean']>;
  /** @default(value: false) */
  requires_genset?: Maybe<Scalars['Boolean']>;
  total_weight?: Maybe<Scalars['Float']>;
  /**
   * @db(type: 'text')
   * @default(value: 'KG')
   */
  weight_unit?: Maybe<WeightUnit>;
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
};

export type DrayageLoadSpecTemplateFilter = {
  id?: Maybe<IdInput>;
  shipper_id?: Maybe<IdInput>;
  quote_request_template_id?: Maybe<IdInput>;
  container_size?: Maybe<StringInput>;
  container_type?: Maybe<StringInput>;
  commodities?: Maybe<StringInput>;
  max_temp?: Maybe<FloatInput>;
  min_temp?: Maybe<FloatInput>;
  temp?: Maybe<FloatInput>;
  temp_unit?: Maybe<StringInput>;
  is_hazardous?: Maybe<BooleanInput>;
  requires_genset?: Maybe<BooleanInput>;
  total_weight?: Maybe<FloatInput>;
  weight_unit?: Maybe<StringInput>;
  created_at?: Maybe<GraphbackDateTimeInput>;
  updated_at?: Maybe<GraphbackDateTimeInput>;
  and?: Maybe<Array<DrayageLoadSpecTemplateFilter>>;
  or?: Maybe<Array<DrayageLoadSpecTemplateFilter>>;
  not?: Maybe<DrayageLoadSpecTemplateFilter>;
};

export enum DriverPreference {
  None = 'NONE',
  Solo = 'SOLO',
  Team = 'TEAM'
}

export enum EquipmentType {
  All = 'ALL',
  Bulk = 'BULK',
  Dry = 'DRY',
  Flatbed = 'FLATBED',
  Rgn = 'RGN',
  Reefer = 'REEFER',
  FoodGradeTank = 'FOOD_GRADE_TANK',
  StepDeck = 'STEP_DECK',
  BoxTruck = 'BOX_TRUCK',
  SprinterVan = 'SPRINTER_VAN',
  Conestoga = 'CONESTOGA',
  Hotshot = 'HOTSHOT',
  Drayage = 'DRAYAGE',
  DrayageReefer = 'DRAYAGE_REEFER',
  CargoVan = 'CARGO_VAN',
  Dump = 'DUMP',
  PartialLoad = 'PARTIAL_LOAD',
  NaPartnersChoice = 'NA_PARTNERS_CHOICE',
  Other = 'OTHER'
}

/**
 * @db(name: 'fcl_load_specs')
 * @model(create: false, find: false, findOne: false, update: true, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type FclLoadSpec = Node & {
  __typename?: 'FclLoadSpec';
  id: Scalars['ID'];
  /**
   * @manyToOne(field: 'fcl_load_specs', key: 'shipper_id')
   * @index
   */
  shipper: Shipper;
  /**
   * @index
   * @oneToOne(field: 'quote_request', key: 'quote_request_id')
   */
  quote_request: QuoteRequest;
  /** @oneToMany(field: 'fcl_load_spec', key: 'fcl_load_spec_id') */
  containers: Array<Container>;
  /** @db(type: 'text') */
  incoterms?: Maybe<OceanIncoterms>;
  total_weight?: Maybe<Scalars['Float']>;
  /**
   * @db(type: 'text')
   * @default(value: 'KG')
   */
  weight_unit?: Maybe<WeightUnit>;
  /** @default(value: false) */
  is_overweight?: Maybe<Scalars['Boolean']>;
  include_customs_clearance?: Maybe<Scalars['Boolean']>;
  /**
   * @db(type: 'text')
   * @deprecated use `quote_types`.
   */
  quote_type?: Maybe<QuoteType>;
  /**
   * @db(type: 'json')
   * @default(value: [])
   */
  quote_types: Array<QuoteType>;
  /** @db(type: 'text') */
  routing_type?: Maybe<RoutingType>;
  /** @default(value: true) */
  is_palletized?: Maybe<Scalars['Boolean']>;
  /** @db(type: 'text') */
  packaging_type?: Maybe<PackagingType>;
  packing_count?: Maybe<Scalars['Int']>;
  pallet_count?: Maybe<Scalars['Int']>;
  /** @db(type: 'text') */
  commodities?: Maybe<Scalars['String']>;
  /** @default(value: false) */
  capacity_guarantee_required?: Maybe<Scalars['Boolean']>;
  /** @default(value: false) */
  direct_sailing_required?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
  cargo_ready_date?: Maybe<Scalars['GraphbackDateTime']>;
  target_delivery_date?: Maybe<Scalars['GraphbackDateTime']>;
};


/**
 * @db(name: 'fcl_load_specs')
 * @model(create: false, find: false, findOne: false, update: true, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type FclLoadSpecContainersArgs = {
  filter?: Maybe<ContainerFilter>;
};

export type FclLoadSpecFilter = {
  id?: Maybe<IdInput>;
  shipper_id?: Maybe<IdInput>;
  quote_request_id?: Maybe<IdInput>;
  incoterms?: Maybe<StringInput>;
  total_weight?: Maybe<FloatInput>;
  weight_unit?: Maybe<StringInput>;
  is_overweight?: Maybe<BooleanInput>;
  include_customs_clearance?: Maybe<BooleanInput>;
  quote_type?: Maybe<StringInput>;
  quote_types?: Maybe<StringInput>;
  routing_type?: Maybe<StringInput>;
  is_palletized?: Maybe<BooleanInput>;
  packaging_type?: Maybe<StringInput>;
  packing_count?: Maybe<IntInput>;
  pallet_count?: Maybe<IntInput>;
  commodities?: Maybe<StringInput>;
  capacity_guarantee_required?: Maybe<BooleanInput>;
  direct_sailing_required?: Maybe<BooleanInput>;
  created_at?: Maybe<GraphbackDateTimeInput>;
  updated_at?: Maybe<GraphbackDateTimeInput>;
  cargo_ready_date?: Maybe<GraphbackDateTimeInput>;
  target_delivery_date?: Maybe<GraphbackDateTimeInput>;
  and?: Maybe<Array<FclLoadSpecFilter>>;
  or?: Maybe<Array<FclLoadSpecFilter>>;
  not?: Maybe<FclLoadSpecFilter>;
};

/**
 * @db(name: 'fcl_load_spec_templates')
 * @model(create: false, find: false, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type FclLoadSpecTemplate = Node & {
  __typename?: 'FclLoadSpecTemplate';
  id: Scalars['ID'];
  /**
   * @manyToOne(field: 'fcl_load_spec_templates', key: 'shipper_id')
   * @index
   */
  shipper: Shipper;
  /**
   * @index
   * @oneToOne(field: 'quote_request_template', key: 'quote_request_template_id')
   */
  quote_request_template: QuoteRequestTemplate;
  /** @oneToMany(field: 'fcl_load_spec_template', key: 'fcl_load_spec_template_id') */
  container_templates: Array<ContainerTemplate>;
  /** @db(type: 'text') */
  incoterms?: Maybe<OceanIncoterms>;
  total_weight?: Maybe<Scalars['Float']>;
  /**
   * @db(type: 'text')
   * @default(value: 'KG')
   */
  weight_unit?: Maybe<WeightUnit>;
  /** @default(value: false) */
  is_overweight?: Maybe<Scalars['Boolean']>;
  include_customs_clearance?: Maybe<Scalars['Boolean']>;
  /**
   * @db(type: 'text')
   * @deprecated use `quote_types`.
   */
  quote_type?: Maybe<QuoteType>;
  /**
   * @db(type: 'json')
   * @default(value: [])
   */
  quote_types: Array<QuoteType>;
  /** @db(type: 'text') */
  routing_type?: Maybe<RoutingType>;
  /** @default(value: true) */
  is_palletized?: Maybe<Scalars['Boolean']>;
  /** @db(type: 'text') */
  packaging_type?: Maybe<PackagingType>;
  pallet_count?: Maybe<Scalars['Int']>;
  packing_count?: Maybe<Scalars['Int']>;
  /** @db(type: 'text') */
  commodities?: Maybe<Scalars['String']>;
  /** @default(value: false) */
  capacity_guarantee_required?: Maybe<Scalars['Boolean']>;
  /** @default(value: false) */
  direct_sailing_required?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
};


/**
 * @db(name: 'fcl_load_spec_templates')
 * @model(create: false, find: false, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type FclLoadSpecTemplateContainer_TemplatesArgs = {
  filter?: Maybe<ContainerTemplateFilter>;
};

export type FclLoadSpecTemplateFilter = {
  id?: Maybe<IdInput>;
  shipper_id?: Maybe<IdInput>;
  quote_request_template_id?: Maybe<IdInput>;
  incoterms?: Maybe<StringInput>;
  total_weight?: Maybe<FloatInput>;
  weight_unit?: Maybe<StringInput>;
  is_overweight?: Maybe<BooleanInput>;
  include_customs_clearance?: Maybe<BooleanInput>;
  quote_type?: Maybe<StringInput>;
  quote_types?: Maybe<StringInput>;
  routing_type?: Maybe<StringInput>;
  is_palletized?: Maybe<BooleanInput>;
  packaging_type?: Maybe<StringInput>;
  pallet_count?: Maybe<IntInput>;
  packing_count?: Maybe<IntInput>;
  commodities?: Maybe<StringInput>;
  capacity_guarantee_required?: Maybe<BooleanInput>;
  direct_sailing_required?: Maybe<BooleanInput>;
  created_at?: Maybe<GraphbackDateTimeInput>;
  updated_at?: Maybe<GraphbackDateTimeInput>;
  and?: Maybe<Array<FclLoadSpecTemplateFilter>>;
  or?: Maybe<Array<FclLoadSpecTemplateFilter>>;
  not?: Maybe<FclLoadSpecTemplateFilter>;
};

/**
 * @db(name: 'fcl_quotes')
 * @model(create: false, find: false, findOne: true, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type FclQuote = Node & {
  __typename?: 'FclQuote';
  id: Scalars['ID'];
  /**
   * @manyToOne(field: 'fcl_quotes', key: 'shipper_id')
   * @index
   */
  shipper: Shipper;
  /**
   * @manyToOne(field: 'fcl_quotes', key: 'quote_request_id')
   * @index
   */
  quote_request: QuoteRequest;
  submitter_email: Scalars['String'];
  total_amount?: Maybe<Scalars['Float']>;
  rate_per_container?: Maybe<Scalars['Float']>;
  valid_until: Scalars['GraphbackDateTime'];
  submitter_tz?: Maybe<Scalars['String']>;
  /**
   * @db(type: 'text')
   * @default(value: 'DRAFT')
   */
  status: QuoteStatus;
  company_name?: Maybe<Scalars['String']>;
  /** @db(type: 'text') */
  notes?: Maybe<Scalars['String']>;
  /** @db(type: 'text') */
  booking_notes?: Maybe<Scalars['String']>;
  /** @db(type: 'text') */
  notes_charges_insurance?: Maybe<Scalars['String']>;
  /** @db(type: 'text') */
  notes_charges_miscellaneous?: Maybe<Scalars['String']>;
  benchmark?: Maybe<Scalars['Float']>;
  type: QuoteType;
  /** @manyToOne(field: 'fcl_quotes_origin', key: 'origin_port_id') */
  origin_port: Address;
  /** @manyToOne(field: 'fcl_quotes_via', key: 'via_port_id') */
  via_port?: Maybe<Address>;
  /** @manyToOne(field: 'fcl_quotes_destination', key: 'destination_port_id') */
  destination_port: Address;
  min_transit_time?: Maybe<Scalars['Int']>;
  max_transit_time?: Maybe<Scalars['Int']>;
  carrier?: Maybe<Scalars['String']>;
  /** @oneToMany(field: 'quote', key: 'quote_id') */
  quote_charges: Array<FclQuoteCharge>;
  /** @db(type: 'text') */
  inland_mode_of_transport?: Maybe<InlandModeOfTransportType>;
  /** @manyToOne(field: 'fcl_quotes_destination_rail_ramp', key: 'destination_rail_ramp_id') */
  destination_rail_ramp?: Maybe<Address>;
  /** @transient */
  quote_charges_totals: QuoteChargeTotals;
  capacity_guarantee: Scalars['Boolean'];
  /** @oneToOne(field: 'load', key: 'load_id') */
  load?: Maybe<Load>;
  /** @transient */
  conversation_id?: Maybe<Scalars['ID']>;
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
};


/**
 * @db(name: 'fcl_quotes')
 * @model(create: false, find: false, findOne: true, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type FclQuoteQuote_ChargesArgs = {
  filter?: Maybe<FclQuoteChargeFilter>;
};

/**
 * @db(name: 'fcl_quote_charges')
 * @model(create: false, find: false, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type FclQuoteCharge = Node & {
  __typename?: 'FclQuoteCharge';
  id: Scalars['ID'];
  /**
   * @manyToOne(field: 'fcl_quote_charges', key: 'shipper_id')
   * @index
   */
  shipper: Shipper;
  /**
   * @manyToOne(field: 'fcl_quote_charges', key: 'quote_request_id')
   * @index
   */
  quote_request: QuoteRequest;
  /**
   * @manyToOne(field: 'quote_charges', key: 'quote_id')
   * @index
   */
  quote: FclQuote;
  name: Scalars['String'];
  type: ChargeType;
  quantity: Scalars['Int'];
  unit: ChargeUnitType;
  rate: Scalars['Float'];
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
};

export type FclQuoteChargeFilter = {
  id?: Maybe<IdInput>;
  shipper_id?: Maybe<IdInput>;
  quote_request_id?: Maybe<IdInput>;
  quote_id?: Maybe<IdInput>;
  name?: Maybe<StringInput>;
  type?: Maybe<StringInput>;
  quantity?: Maybe<IntInput>;
  unit?: Maybe<StringInput>;
  rate?: Maybe<FloatInput>;
  created_at?: Maybe<GraphbackDateTimeInput>;
  updated_at?: Maybe<GraphbackDateTimeInput>;
  and?: Maybe<Array<FclQuoteChargeFilter>>;
  or?: Maybe<Array<FclQuoteChargeFilter>>;
  not?: Maybe<FclQuoteChargeFilter>;
};

export type FclQuoteChargePayload = {
  name: Scalars['String'];
  type: ChargeType;
  quantity: Scalars['Int'];
  unit: ChargeUnitType;
  rate: Scalars['Float'];
};

export type FclQuoteFilter = {
  id?: Maybe<IdInput>;
  shipper_id?: Maybe<IdInput>;
  quote_request_id?: Maybe<IdInput>;
  submitter_email?: Maybe<StringInput>;
  total_amount?: Maybe<FloatInput>;
  rate_per_container?: Maybe<FloatInput>;
  valid_until?: Maybe<GraphbackDateTimeInput>;
  submitter_tz?: Maybe<StringInput>;
  status?: Maybe<StringInput>;
  company_name?: Maybe<StringInput>;
  notes?: Maybe<StringInput>;
  booking_notes?: Maybe<StringInput>;
  notes_charges_insurance?: Maybe<StringInput>;
  notes_charges_miscellaneous?: Maybe<StringInput>;
  benchmark?: Maybe<FloatInput>;
  type?: Maybe<StringInput>;
  origin_port_id?: Maybe<IdInput>;
  via_port_id?: Maybe<IdInput>;
  destination_port_id?: Maybe<IdInput>;
  min_transit_time?: Maybe<IntInput>;
  max_transit_time?: Maybe<IntInput>;
  carrier?: Maybe<StringInput>;
  inland_mode_of_transport?: Maybe<StringInput>;
  destination_rail_ramp_id?: Maybe<IdInput>;
  capacity_guarantee?: Maybe<BooleanInput>;
  load_id?: Maybe<IdInput>;
  created_at?: Maybe<GraphbackDateTimeInput>;
  updated_at?: Maybe<GraphbackDateTimeInput>;
  and?: Maybe<Array<FclQuoteFilter>>;
  or?: Maybe<Array<FclQuoteFilter>>;
  not?: Maybe<FclQuoteFilter>;
};

export type FclQuotePortPayload = {
  id?: Maybe<Scalars['ID']>;
  type?: Maybe<AddressType>;
  name?: Maybe<Scalars['String']>;
  address_1?: Maybe<Scalars['String']>;
  address_2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  province_code?: Maybe<Scalars['String']>;
  province_name?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  country_name?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['String']>;
  lon?: Maybe<Scalars['String']>;
  google_place_id?: Maybe<Scalars['String']>;
  google_place_description?: Maybe<Scalars['String']>;
  port_id?: Maybe<Scalars['String']>;
  port_name?: Maybe<Scalars['String']>;
};

export type FclQuoteTotals = {
  __typename?: 'FclQuoteTotals';
  total_amount: Scalars['Float'];
  rate_per_container: Scalars['Float'];
};

/**
 * @db(name: 'files')
 * @model(create: false, find: false, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type File = NodeUuid & SoftDeletable & {
  __typename?: 'File';
  /**
   * @description This is an uuid & is also used as the key in S3
   * @see https://docs.aws.amazon.com/AmazonS3/latest/userguide/object-keys.html
   * @id
   */
  id: Scalars['String'];
  name: Scalars['String'];
  extension: Scalars['String'];
  /** @transient */
  url: Scalars['String'];
  /** @oneToMany(field: 'file', key: 'file_id') */
  files_quotes: Array<FilesQuotes>;
  /**
   * @manyToOne(field: 'files', key: 'created_by_id')
   * @index
   */
  created_by: User;
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
  /** @manyToOne(field: 'deleted_files', key: 'deleted_by_id') */
  deleted_by?: Maybe<User>;
  deleted_at?: Maybe<Scalars['GraphbackDateTime']>;
};


/**
 * @db(name: 'files')
 * @model(create: false, find: false, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type FileFiles_QuotesArgs = {
  filter?: Maybe<FilesQuotesFilter>;
};

export type FileFilter = {
  id?: Maybe<StringInput>;
  name?: Maybe<StringInput>;
  extension?: Maybe<StringInput>;
  created_by_id?: Maybe<IdInput>;
  created_at?: Maybe<GraphbackDateTimeInput>;
  updated_at?: Maybe<GraphbackDateTimeInput>;
  deleted_by_id?: Maybe<IdInput>;
  deleted_at?: Maybe<GraphbackDateTimeInput>;
  and?: Maybe<Array<FileFilter>>;
  or?: Maybe<Array<FileFilter>>;
  not?: Maybe<FileFilter>;
};

/**
 * @db(name: 'files_quotes')
 * @model(create: false, find: false, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type FilesQuotes = Node & {
  __typename?: 'FilesQuotes';
  id: Scalars['ID'];
  /**
   * @manyToOne(field: 'files_quotes', key: 'shipper_id')
   * @index
   */
  shipper: Shipper;
  /** @index */
  quote_id: Scalars['String'];
  /** @transient */
  quote: QuoteTypes;
  /**
   * @manyToOne(field: 'files_quotes', key: 'file_id')
   * @index
   */
  file: File;
  mode: Mode;
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
};

export type FilesQuotesFilter = {
  id?: Maybe<IdInput>;
  shipper_id?: Maybe<IdInput>;
  quote_id?: Maybe<StringInput>;
  file_id?: Maybe<StringInput>;
  mode?: Maybe<StringInput>;
  created_at?: Maybe<GraphbackDateTimeInput>;
  updated_at?: Maybe<GraphbackDateTimeInput>;
  and?: Maybe<Array<FilesQuotesFilter>>;
  or?: Maybe<Array<FilesQuotesFilter>>;
  not?: Maybe<FilesQuotesFilter>;
};

export type FindAddressesForModeInput = {
  mode: Mode;
  excludeAddressTypes?: Maybe<Array<AddressType>>;
};

export type FindAddressesForModeResult = {
  __typename?: 'FindAddressesForModeResult';
  items: Array<Address>;
};

export type FindContainersForQuoteRequestInput = {
  quoteRequestId: Scalars['ID'];
};

export type FindContainersForQuoteRequestResult = {
  __typename?: 'FindContainersForQuoteRequestResult';
  items: Array<Container>;
};

export type FindFilesForQuoteInput = {
  quoteId: Scalars['ID'];
  mode: Mode;
};

export type FindStopsForQuoteRequestInput = {
  quoteRequestId: Scalars['ID'];
};

export type FindStopsForQuoteRequestResult = {
  __typename?: 'FindStopsForQuoteRequestResult';
  items: Array<Stop>;
};

export type FloatInput = {
  ne?: Maybe<Scalars['Float']>;
  eq?: Maybe<Scalars['Float']>;
  le?: Maybe<Scalars['Float']>;
  lt?: Maybe<Scalars['Float']>;
  ge?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  between?: Maybe<Array<Scalars['Float']>>;
};

export enum FreightClass {
  Fc_50 = 'FC_50',
  Fc_55 = 'FC_55',
  Fc_60 = 'FC_60',
  Fc_65 = 'FC_65',
  Fc_70 = 'FC_70',
  Fc_77_5 = 'FC_77_5',
  Fc_85 = 'FC_85',
  Fc_92_5 = 'FC_92_5',
  Fc_100 = 'FC_100',
  Fc_110 = 'FC_110',
  Fc_125 = 'FC_125',
  Fc_150 = 'FC_150',
  Fc_175 = 'FC_175',
  Fc_200 = 'FC_200',
  Fc_250 = 'FC_250',
  Fc_300 = 'FC_300',
  Fc_400 = 'FC_400',
  Fc_500 = 'FC_500'
}

export type FreightSpendByLane = {
  __typename?: 'FreightSpendByLane';
  origin: Scalars['String'];
  destination: Scalars['String'];
  number_of_loads_moved: Scalars['Int'];
  total_amount: Scalars['Float'];
  mode: Mode;
};

export type FreightSpendByLaneInput = {
  mode?: Maybe<Mode>;
  equipment_type: EquipmentType;
  start?: Maybe<Scalars['GraphbackDateTime']>;
  end?: Maybe<Scalars['GraphbackDateTime']>;
  orderBy?: Maybe<Order>;
  count?: Maybe<Scalars['Int']>;
};

export type FreightSpendByLaneResponse = {
  __typename?: 'FreightSpendByLaneResponse';
  start: Scalars['GraphbackDateTime'];
  end: Scalars['GraphbackDateTime'];
  equipment_type: EquipmentType;
  items: Array<FreightSpendByLane>;
};

export type FreightWavesLocationPayload = {
  address_1?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  province_code?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['String']>;
  lon?: Maybe<Scalars['String']>;
};

export type FreightWavesRateBenchmark = {
  __typename?: 'FreightWavesRateBenchmark';
  status: FreightWavesRateBenchmarkStatus;
  confidenceScore: Scalars['Int'];
  low: Scalars['Int'];
  high: Scalars['Int'];
  medianAverage: Scalars['Int'];
};

export enum FreightWavesRateBenchmarkStatus {
  Ok = 'OK',
  NoResults = 'NO_RESULTS',
  UnsupportedEquipmentType = 'UNSUPPORTED_EQUIPMENT_TYPE'
}

/** @description FreightWaves currently only supports van and reefer. */
export enum FreightWavesSupportedEquipmentType {
  Van = 'VAN',
  Reefer = 'REEFER'
}

/**
 * @db(name: 'ftl_load_specs')
 * @model(create: false, find: false, findOne: false, update: true, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type FtlLoadSpec = Node & {
  __typename?: 'FtlLoadSpec';
  id: Scalars['ID'];
  /**
   * @manyToOne(field: 'ftl_load_specs', key: 'shipper_id')
   * @index
   */
  shipper: Shipper;
  /**
   * @manyToOne(field: 'ftl_load_specs', key: 'quote_request_id')
   * @index
   */
  quote_request: QuoteRequest;
  /** @db(type: 'text') */
  trailer_size?: Maybe<TrailerSize>;
  /** @db(type: 'text') */
  trailer_type?: Maybe<FtlTrailerType>;
  /** @db(type: 'text') */
  commodities?: Maybe<Scalars['String']>;
  /** @default(value: false) */
  is_palletized?: Maybe<Scalars['Boolean']>;
  /**
   * @db(type: 'text')
   * @default(value: 'LB')
   */
  weight_unit?: Maybe<WeightUnit>;
  max_temp?: Maybe<Scalars['Float']>;
  min_temp?: Maybe<Scalars['Float']>;
  temp?: Maybe<Scalars['Float']>;
  /**
   * @db(type: 'text')
   * @default(value: 'F')
   */
  temp_unit?: Maybe<TempUnit>;
  /** @db(type: 'text') */
  packaging_type?: Maybe<PackagingType>;
  packing_count?: Maybe<Scalars['Int']>;
  pallet_type?: Maybe<PalletType>;
  pallet_count?: Maybe<Scalars['Int']>;
  unit_count?: Maybe<Scalars['Int']>;
  total_weight?: Maybe<Scalars['Float']>;
  weight_per_unit?: Maybe<Scalars['Float']>;
  /**
   * @default(value: 1)
   * @description
   * Minimum value is 1.
   * `0` in an invalid value
   */
  truck_quantity?: Maybe<Scalars['Int']>;
  /** @oneToMany(field: 'ftl_load_spec', key: 'ftl_load_spec_id') */
  trucks: Array<Truck>;
  /** @default(value: 'NONE') */
  driver_preference?: Maybe<DriverPreference>;
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
};


/**
 * @db(name: 'ftl_load_specs')
 * @model(create: false, find: false, findOne: false, update: true, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type FtlLoadSpecTrucksArgs = {
  filter?: Maybe<TruckFilter>;
};

export type FtlLoadSpecFilter = {
  id?: Maybe<IdInput>;
  shipper_id?: Maybe<IdInput>;
  quote_request_id?: Maybe<IdInput>;
  trailer_size?: Maybe<StringInput>;
  trailer_type?: Maybe<StringInput>;
  commodities?: Maybe<StringInput>;
  is_palletized?: Maybe<BooleanInput>;
  weight_unit?: Maybe<StringInput>;
  max_temp?: Maybe<FloatInput>;
  min_temp?: Maybe<FloatInput>;
  temp?: Maybe<FloatInput>;
  temp_unit?: Maybe<StringInput>;
  packaging_type?: Maybe<StringInput>;
  packing_count?: Maybe<IntInput>;
  pallet_type?: Maybe<StringInput>;
  pallet_count?: Maybe<IntInput>;
  unit_count?: Maybe<IntInput>;
  total_weight?: Maybe<FloatInput>;
  weight_per_unit?: Maybe<FloatInput>;
  truck_quantity?: Maybe<IntInput>;
  driver_preference?: Maybe<StringInput>;
  created_at?: Maybe<GraphbackDateTimeInput>;
  updated_at?: Maybe<GraphbackDateTimeInput>;
  and?: Maybe<Array<FtlLoadSpecFilter>>;
  or?: Maybe<Array<FtlLoadSpecFilter>>;
  not?: Maybe<FtlLoadSpecFilter>;
};

/**
 * @db(name: 'ftl_load_spec_templates')
 * @model(create: false, find: false, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type FtlLoadSpecTemplate = Node & {
  __typename?: 'FtlLoadSpecTemplate';
  id: Scalars['ID'];
  /**
   * @manyToOne(field: 'ftl_load_spec_templates', key: 'shipper_id')
   * @index
   */
  shipper: Shipper;
  /**
   * @manyToOne(field: 'ftl_load_spec_templates', key: 'quote_request_template_id')
   * @index
   */
  quote_request_template: QuoteRequestTemplate;
  /** @db(type: 'text') */
  trailer_size?: Maybe<TrailerSize>;
  /** @db(type: 'text') */
  trailer_type?: Maybe<FtlTrailerType>;
  /** @db(type: 'text') */
  commodities?: Maybe<Scalars['String']>;
  /** @default(value: false) */
  is_palletized?: Maybe<Scalars['Boolean']>;
  /**
   * @db(type: 'text')
   * @default(value: 'LB')
   */
  weight_unit?: Maybe<WeightUnit>;
  max_temp?: Maybe<Scalars['Float']>;
  min_temp?: Maybe<Scalars['Float']>;
  temp?: Maybe<Scalars['Float']>;
  /**
   * @db(type: 'text')
   * @default(value: 'F')
   */
  temp_unit?: Maybe<TempUnit>;
  /** @db(type: 'text') */
  packaging_type?: Maybe<PackagingType>;
  /** @oneToOne(field: 'truck_template', key: 'truck_template_id') */
  truck_template?: Maybe<TruckTemplate>;
  /** @default(value: 'NONE') */
  driver_preference?: Maybe<DriverPreference>;
  total_weight?: Maybe<Scalars['Float']>;
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
};

export type FtlLoadSpecTemplateFilter = {
  id?: Maybe<IdInput>;
  shipper_id?: Maybe<IdInput>;
  quote_request_template_id?: Maybe<IdInput>;
  trailer_size?: Maybe<StringInput>;
  trailer_type?: Maybe<StringInput>;
  commodities?: Maybe<StringInput>;
  is_palletized?: Maybe<BooleanInput>;
  weight_unit?: Maybe<StringInput>;
  max_temp?: Maybe<FloatInput>;
  min_temp?: Maybe<FloatInput>;
  temp?: Maybe<FloatInput>;
  temp_unit?: Maybe<StringInput>;
  packaging_type?: Maybe<StringInput>;
  truck_template_id?: Maybe<IdInput>;
  driver_preference?: Maybe<StringInput>;
  total_weight?: Maybe<FloatInput>;
  created_at?: Maybe<GraphbackDateTimeInput>;
  updated_at?: Maybe<GraphbackDateTimeInput>;
  and?: Maybe<Array<FtlLoadSpecTemplateFilter>>;
  or?: Maybe<Array<FtlLoadSpecTemplateFilter>>;
  not?: Maybe<FtlLoadSpecTemplateFilter>;
};

export enum FtlTrailerType {
  DryVan = 'DRY_VAN',
  Flatbed = 'FLATBED',
  Rgn = 'RGN',
  Reefer = 'REEFER',
  FoodGradeTank = 'FOOD_GRADE_TANK',
  Bulk = 'BULK',
  StepDeck = 'STEP_DECK',
  BoxTruck = 'BOX_TRUCK',
  SprinterVan = 'SPRINTER_VAN',
  Conestoga = 'CONESTOGA',
  Hotshot = 'HOTSHOT',
  Drayage = 'DRAYAGE',
  DrayageReefer = 'DRAYAGE_REEFER',
  CargoVan = 'CARGO_VAN',
  Dump = 'DUMP',
  PartialLoad = 'PARTIAL_LOAD',
  NaPartnersChoice = 'NA_PARTNERS_CHOICE',
  Other = 'OTHER'
}

export type GenerateUrlForFileUploadInput = {
  quoteId: Scalars['ID'];
  mode: Mode;
  name: Scalars['String'];
  extension: Scalars['String'];
};

export type GenerateUrlForFileUploadResult = {
  __typename?: 'GenerateUrlForFileUploadResult';
  id: Scalars['ID'];
  url: Scalars['String'];
};

export type Get7DayRateBenchmarkInput = {
  quoteRequestId: Scalars['ID'];
};

export type GetAirQuoteTotalsInput = {
  quote_charges: Array<AirQuoteChargePayload>;
};

export type GetBenchmarkFtlInput = {
  equipmentType: FreightWavesSupportedEquipmentType;
  origin: FreightWavesLocationPayload;
  destination: FreightWavesLocationPayload;
};

export type GetFclQuoteTotalsInput = {
  quote_charges: Array<FclQuoteChargePayload>;
};

export type GetPortexAdmins = {
  __typename?: 'GetPortexAdmins';
  items: Array<User>;
};

export type GetShipperPartnerTagsInput = {
  search: Scalars['String'];
};

export type GetShippersResult = {
  __typename?: 'GetShippersResult';
  items: Array<Shipper>;
};


export type GraphbackDateTimeInput = {
  ne?: Maybe<Scalars['GraphbackDateTime']>;
  eq?: Maybe<Scalars['GraphbackDateTime']>;
  le?: Maybe<Scalars['GraphbackDateTime']>;
  lt?: Maybe<Scalars['GraphbackDateTime']>;
  ge?: Maybe<Scalars['GraphbackDateTime']>;
  gt?: Maybe<Scalars['GraphbackDateTime']>;
  in?: Maybe<Array<Scalars['GraphbackDateTime']>>;
  between?: Maybe<Array<Scalars['GraphbackDateTime']>>;
};

export type HideQuoteRequestInput = {
  hide: Scalars['Boolean'];
  /** @example PTX-123456 */
  portex_id: Scalars['String'];
};

export type IdInput = {
  ne?: Maybe<Scalars['ID']>;
  eq?: Maybe<Scalars['ID']>;
  le?: Maybe<Scalars['ID']>;
  lt?: Maybe<Scalars['ID']>;
  ge?: Maybe<Scalars['ID']>;
  gt?: Maybe<Scalars['ID']>;
  in?: Maybe<Array<Scalars['ID']>>;
};

export type ImpersonateCompanyInput = {
  shipperId?: Maybe<Scalars['ID']>;
};

export enum InlandModeOfTransportType {
  OverTheRoad = 'OVER_THE_ROAD',
  Rail = 'RAIL'
}

export type InsightInput = {
  start?: Maybe<Scalars['GraphbackDateTime']>;
  end?: Maybe<Scalars['GraphbackDateTime']>;
  modes: Array<Mode>;
};

export type InsightReportFileRequest = {
  __typename?: 'InsightReportFileRequest';
  csv_data?: Maybe<Scalars['String']>;
};

export type InsightReportInput = {
  start?: Maybe<Scalars['GraphbackDateTime']>;
  end?: Maybe<Scalars['GraphbackDateTime']>;
  /** @description defaults to Mode.FTL  */
  mode?: Maybe<Mode>;
};

export type InsightReportRequest = {
  __typename?: 'InsightReportRequest';
  report_table: DataTable;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
};

export type IntInput = {
  ne?: Maybe<Scalars['Int']>;
  eq?: Maybe<Scalars['Int']>;
  le?: Maybe<Scalars['Int']>;
  lt?: Maybe<Scalars['Int']>;
  ge?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  between?: Maybe<Array<Scalars['Int']>>;
};

export type InviteShipperInput = {
  email: Scalars['String'];
  shipperId: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

/**
 * @db(name: 'loads')
 * @model(create: false, find: false, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type Load = SoftDeletable & Node & {
  __typename?: 'Load';
  id: Scalars['ID'];
  /**
   * @manyToOne(field: 'loads', key: 'shipper_id')
   * @index
   */
  shipper: Shipper;
  /**
   * @index
   * @oneToOne(field: 'quote_request', key: 'quote_request_id')
   */
  quote_request: QuoteRequest;
  /** @oneToOne(field: 'air_quote', key: 'air_quote_id') */
  air_quote?: Maybe<AirQuote>;
  /** @oneToOne(field: 'fcl_quote', key: 'fcl_quote_id') */
  fcl_quote?: Maybe<FclQuote>;
  /** @oneToOne(field: 'ltl_quote', key: 'ltl_quote_id') */
  ltl_quote?: Maybe<LtlQuote>;
  /** @oneToOne(field: 'quote', key: 'quote_id') */
  quote?: Maybe<Quote>;
  partner: Scalars['String'];
  total_amount: Scalars['Float'];
  benchmark?: Maybe<Scalars['Float']>;
  /**
   * @summary normalized string field that's specific for each mode
   * FTL & LTL: `{city}, {province}`
   * FCL: `{city}, {country_code}`
   * AIR: `{airport_iata_code} - {airport_name}`
   */
  origin: Scalars['String'];
  /**
   * @summary normalized string field that's specific for each mode
   * FTL & LTL: `{city}, {province}`
   * FCL: `{city}, {country_code}`
   * AIR: `{airport_iata_code} - {airport_name}`
   */
  destination: Scalars['String'];
  mode: Mode;
  /** FTL & LTL Only */
  equipment_type?: Maybe<EquipmentType>;
  /** FTL & LTL Only */
  pickup_at?: Maybe<Scalars['GraphbackDateTime']>;
  /** FTL & LTL Only */
  delivery_at?: Maybe<Scalars['GraphbackDateTime']>;
  /** @manyToOne(field: 'deleted_loads', key: 'deleted_by_id') */
  deleted_by?: Maybe<User>;
  deleted_at?: Maybe<Scalars['GraphbackDateTime']>;
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
};

export type LoadFilter = {
  id?: Maybe<IdInput>;
  shipper_id?: Maybe<IdInput>;
  quote_request_id?: Maybe<IdInput>;
  air_quote_id?: Maybe<IdInput>;
  fcl_quote_id?: Maybe<IdInput>;
  ltl_quote_id?: Maybe<IdInput>;
  quote_id?: Maybe<IdInput>;
  partner?: Maybe<StringInput>;
  total_amount?: Maybe<FloatInput>;
  benchmark?: Maybe<FloatInput>;
  origin?: Maybe<StringInput>;
  destination?: Maybe<StringInput>;
  mode?: Maybe<StringInput>;
  equipment_type?: Maybe<StringInput>;
  pickup_at?: Maybe<GraphbackDateTimeInput>;
  delivery_at?: Maybe<GraphbackDateTimeInput>;
  deleted_by_id?: Maybe<IdInput>;
  deleted_at?: Maybe<GraphbackDateTimeInput>;
  created_at?: Maybe<GraphbackDateTimeInput>;
  updated_at?: Maybe<GraphbackDateTimeInput>;
  and?: Maybe<Array<LoadFilter>>;
  or?: Maybe<Array<LoadFilter>>;
  not?: Maybe<LoadFilter>;
};

/**
 * @db(name: 'ltl_load_specs')
 * @model(create: false, find: false, findOne: false, update: true, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type LtlLoadSpec = Node & {
  __typename?: 'LtlLoadSpec';
  id: Scalars['ID'];
  /**
   * @manyToOne(field: 'ltl_load_specs', key: 'shipper_id')
   * @index
   */
  shipper: Shipper;
  /**
   * @index
   * @oneToOne(field: 'quote_request', key: 'quote_request_id')
   */
  quote_request: QuoteRequest;
  /** @oneToMany(field: 'ltl_load_spec', key: 'ltl_load_spec_id') */
  package_groups: Array<PackageGroup>;
  /** @oneToMany(field: 'ltl_load_spec', key: 'ltl_load_spec_id') */
  accessorials: Array<AccessorialsLtlLoadSpecs>;
  /** @transient */
  weight?: Maybe<Scalars['Float']>;
  /** @deprecated use 'weight' instead */
  total_weight?: Maybe<Scalars['Float']>;
  /**
   * @db(type: 'text')
   * @default(value: 'LB')
   */
  weight_unit?: Maybe<WeightUnit>;
  /**
   * @db(type: 'text')
   * @default(value: 'DRY_VAN')
   */
  trailer_type?: Maybe<LtlTrailerType>;
  /** @oneToOne(field: 'truck', key: 'truck_id') */
  truck?: Maybe<Truck>;
  max_temp?: Maybe<Scalars['Float']>;
  min_temp?: Maybe<Scalars['Float']>;
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
};


/**
 * @db(name: 'ltl_load_specs')
 * @model(create: false, find: false, findOne: false, update: true, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type LtlLoadSpecPackage_GroupsArgs = {
  filter?: Maybe<PackageGroupFilter>;
};


/**
 * @db(name: 'ltl_load_specs')
 * @model(create: false, find: false, findOne: false, update: true, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type LtlLoadSpecAccessorialsArgs = {
  filter?: Maybe<AccessorialsLtlLoadSpecsFilter>;
};

export type LtlLoadSpecFilter = {
  id?: Maybe<IdInput>;
  shipper_id?: Maybe<IdInput>;
  quote_request_id?: Maybe<IdInput>;
  total_weight?: Maybe<FloatInput>;
  weight_unit?: Maybe<StringInput>;
  trailer_type?: Maybe<StringInput>;
  truck_id?: Maybe<IdInput>;
  max_temp?: Maybe<FloatInput>;
  min_temp?: Maybe<FloatInput>;
  created_at?: Maybe<GraphbackDateTimeInput>;
  updated_at?: Maybe<GraphbackDateTimeInput>;
  and?: Maybe<Array<LtlLoadSpecFilter>>;
  or?: Maybe<Array<LtlLoadSpecFilter>>;
  not?: Maybe<LtlLoadSpecFilter>;
};

/**
 * @db(name: 'ltl_load_spec_templates')
 * @model(create: false, find: false, findOne: true, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type LtlLoadSpecTemplate = Node & {
  __typename?: 'LtlLoadSpecTemplate';
  id: Scalars['ID'];
  /**
   * @manyToOne(field: 'ltl_load_spec_templates', key: 'shipper_id')
   * @index
   */
  shipper: Shipper;
  /**
   * @index
   * @oneToOne(field: 'quote_request_template', key: 'quote_request_template_id')
   */
  quote_request_template: QuoteRequestTemplate;
  /** @oneToMany(field: 'ltl_load_spec_template', key: 'ltl_load_spec_template_id') */
  package_group_templates: Array<PackageGroupTemplate>;
  /** @oneToMany(field: 'ltl_load_spec_template', key: 'ltl_load_spec_template_id') */
  accessorials: Array<AccessorialsLtlLoadSpecTemplates>;
  total_weight?: Maybe<Scalars['Float']>;
  /**
   * @db(type: 'text')
   * @default(value: 'LB')
   */
  weight_unit?: Maybe<WeightUnit>;
  /**
   * @db(type: 'text')
   * @default(value: 'DRY_VAN')
   */
  trailer_type?: Maybe<LtlTrailerType>;
  max_temp?: Maybe<Scalars['Float']>;
  min_temp?: Maybe<Scalars['Float']>;
  /** @oneToOne(field: 'truck_template', key: 'truck_template_id') */
  truck_template?: Maybe<TruckTemplate>;
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
};


/**
 * @db(name: 'ltl_load_spec_templates')
 * @model(create: false, find: false, findOne: true, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type LtlLoadSpecTemplatePackage_Group_TemplatesArgs = {
  filter?: Maybe<PackageGroupTemplateFilter>;
};


/**
 * @db(name: 'ltl_load_spec_templates')
 * @model(create: false, find: false, findOne: true, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type LtlLoadSpecTemplateAccessorialsArgs = {
  filter?: Maybe<AccessorialsLtlLoadSpecTemplatesFilter>;
};

export type LtlLoadSpecTemplateFilter = {
  id?: Maybe<IdInput>;
  shipper_id?: Maybe<IdInput>;
  quote_request_template_id?: Maybe<IdInput>;
  total_weight?: Maybe<FloatInput>;
  weight_unit?: Maybe<StringInput>;
  trailer_type?: Maybe<StringInput>;
  max_temp?: Maybe<FloatInput>;
  min_temp?: Maybe<FloatInput>;
  truck_template_id?: Maybe<IdInput>;
  created_at?: Maybe<GraphbackDateTimeInput>;
  updated_at?: Maybe<GraphbackDateTimeInput>;
  and?: Maybe<Array<LtlLoadSpecTemplateFilter>>;
  or?: Maybe<Array<LtlLoadSpecTemplateFilter>>;
  not?: Maybe<LtlLoadSpecTemplateFilter>;
};

export type LtlPublicPackageGroup = {
  __typename?: 'LtlPublicPackageGroup';
  commodities?: Maybe<Scalars['String']>;
  hazardous_goods_details?: Maybe<Scalars['String']>;
  is_hazardous?: Maybe<Scalars['Boolean']>;
  item_quantity: Scalars['Int'];
  packaging_type: PackagingType;
  per_package_dimensions_height?: Maybe<Scalars['Float']>;
  per_package_dimensions_length?: Maybe<Scalars['Float']>;
  per_package_dimensions_unit: DimUnit;
  per_package_dimensions_width?: Maybe<Scalars['Float']>;
  per_package_weight_unit: WeightUnit;
  per_package_weight: Scalars['Float'];
  freight_class?: Maybe<FreightClass>;
  nmfc_code?: Maybe<Scalars['String']>;
  is_stackable?: Maybe<Scalars['Boolean']>;
};

/**
 * @db(name: 'ltl_quotes')
 * @model(create: false, find: false, findOne: true, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type LtlQuote = Node & {
  __typename?: 'LtlQuote';
  id: Scalars['ID'];
  /**
   * @manyToOne(field: 'ltl_quotes', key: 'shipper_id')
   * @index
   */
  shipper: Shipper;
  /**
   * @manyToOne(field: 'ltl_quotes', key: 'quote_request_id')
   * @index
   */
  quote_request: QuoteRequest;
  submitter_email: Scalars['String'];
  total_amount: Scalars['Float'];
  valid_until: Scalars['GraphbackDateTime'];
  submitter_tz?: Maybe<Scalars['String']>;
  /**
   * @db(type: 'text')
   * @default(value: 'SUBMITTED')
   */
  status: QuoteStatus;
  company_name?: Maybe<Scalars['String']>;
  /** @db(type: 'text') */
  notes?: Maybe<Scalars['String']>;
  /** @db(type: 'text') */
  booking_notes?: Maybe<Scalars['String']>;
  min_transit_time?: Maybe<Scalars['Int']>;
  max_transit_time?: Maybe<Scalars['Int']>;
  /** @oneToOne(field: 'load', key: 'load_id') */
  load?: Maybe<Load>;
  /** @transient */
  conversation_id?: Maybe<Scalars['ID']>;
  /**
   * @db(type: 'text')
   * @default(value: '')
   */
  carrier_name: Scalars['String'];
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
};

export type LtlQuoteFilter = {
  id?: Maybe<IdInput>;
  shipper_id?: Maybe<IdInput>;
  quote_request_id?: Maybe<IdInput>;
  submitter_email?: Maybe<StringInput>;
  total_amount?: Maybe<FloatInput>;
  valid_until?: Maybe<GraphbackDateTimeInput>;
  submitter_tz?: Maybe<StringInput>;
  status?: Maybe<StringInput>;
  company_name?: Maybe<StringInput>;
  notes?: Maybe<StringInput>;
  booking_notes?: Maybe<StringInput>;
  min_transit_time?: Maybe<IntInput>;
  max_transit_time?: Maybe<IntInput>;
  load_id?: Maybe<IdInput>;
  carrier_name?: Maybe<StringInput>;
  created_at?: Maybe<GraphbackDateTimeInput>;
  updated_at?: Maybe<GraphbackDateTimeInput>;
  and?: Maybe<Array<LtlQuoteFilter>>;
  or?: Maybe<Array<LtlQuoteFilter>>;
  not?: Maybe<LtlQuoteFilter>;
};

export enum LtlTrailerType {
  DryVan = 'DRY_VAN',
  Reefer = 'REEFER',
  Hotshot = 'HOTSHOT',
  Flatbed = 'FLATBED',
  StepDeck = 'STEP_DECK',
  NaPartnersChoice = 'NA_PARTNERS_CHOICE'
}

export enum Mode {
  Drayage = 'DRAYAGE',
  Ftl = 'FTL',
  Ltl = 'LTL',
  Fcl = 'FCL',
  Lcl = 'LCL',
  Air = 'AIR'
}

export enum ModeVolume {
  ModeVolume_0 = 'MODE_VOLUME_0',
  ModeVolume_1 = 'MODE_VOLUME_1',
  ModeVolume_2 = 'MODE_VOLUME_2',
  ModeVolume_3 = 'MODE_VOLUME_3',
  ModeVolume_4 = 'MODE_VOLUME_4'
}

export type MutateAirLoadSpecInput = {
  id: Scalars['ID'];
  shipper_id?: Maybe<Scalars['ID']>;
  quote_request_id?: Maybe<Scalars['ID']>;
  incoterms?: Maybe<AirIncoterms>;
  routing_type?: Maybe<RoutingType>;
  include_customs_clearance?: Maybe<Scalars['Boolean']>;
  service_levels?: Maybe<Array<Maybe<ServiceLevel>>>;
  total_weight?: Maybe<Scalars['Float']>;
  length_per_package?: Maybe<Scalars['Float']>;
  width_per_package?: Maybe<Scalars['Float']>;
  height_per_package?: Maybe<Scalars['Float']>;
  volume_format?: Maybe<VolumeFormat>;
  total_volume?: Maybe<Scalars['Float']>;
  item_quantity?: Maybe<Scalars['Int']>;
  weight_unit?: Maybe<WeightUnit>;
  is_overweight?: Maybe<Scalars['Boolean']>;
  temp?: Maybe<Scalars['Float']>;
  temp_unit?: Maybe<TempUnit>;
  commodities?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
  cargo_ready_date?: Maybe<Scalars['GraphbackDateTime']>;
  target_delivery_date?: Maybe<Scalars['GraphbackDateTime']>;
};

export type MutateAirPackageGroupInput = {
  id: Scalars['ID'];
  shipper_id?: Maybe<Scalars['ID']>;
  air_load_spec_id?: Maybe<Scalars['ID']>;
  packaging_type?: Maybe<PackagingType>;
  item_quantity?: Maybe<Scalars['Int']>;
  weight_per_package?: Maybe<Scalars['Float']>;
  length_per_package?: Maybe<Scalars['Float']>;
  height_per_package?: Maybe<Scalars['Float']>;
  width_per_package?: Maybe<Scalars['Float']>;
  volume_format?: Maybe<VolumeFormat>;
  volume_per_item?: Maybe<Scalars['Float']>;
  commodities?: Maybe<Scalars['String']>;
  is_hazardous?: Maybe<Scalars['Boolean']>;
  hazardous_goods_details?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
};

export type MutateContactsQuoteRequestsInput = {
  id: Scalars['ID'];
  contact_id?: Maybe<Scalars['ID']>;
  quote_request_id?: Maybe<Scalars['ID']>;
  shipper_id?: Maybe<Scalars['ID']>;
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
};

export type MutateContainerInput = {
  id: Scalars['ID'];
  shipper_id?: Maybe<Scalars['ID']>;
  fcl_load_spec_id?: Maybe<Scalars['ID']>;
  size?: Maybe<ContainerSize>;
  type?: Maybe<ContainerType>;
  max_temp?: Maybe<Scalars['Float']>;
  min_temp?: Maybe<Scalars['Float']>;
  temp?: Maybe<Scalars['Float']>;
  temp_unit?: Maybe<TempUnit>;
  is_hazardous?: Maybe<Scalars['Boolean']>;
  requires_genset?: Maybe<Scalars['Boolean']>;
  volume?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
};

export type MutateDrayageLoadSpecInput = {
  id: Scalars['ID'];
  shipper_id?: Maybe<Scalars['ID']>;
  quote_request_id?: Maybe<Scalars['ID']>;
  container_size?: Maybe<ContainerSize>;
  container_type?: Maybe<ContainerType>;
  commodities?: Maybe<Scalars['String']>;
  max_temp?: Maybe<Scalars['Float']>;
  min_temp?: Maybe<Scalars['Float']>;
  temp?: Maybe<Scalars['Float']>;
  temp_unit?: Maybe<TempUnit>;
  is_hazardous?: Maybe<Scalars['Boolean']>;
  requires_genset?: Maybe<Scalars['Boolean']>;
  total_weight?: Maybe<Scalars['Float']>;
  container_number?: Maybe<Scalars['String']>;
  ocean_bol_number?: Maybe<Scalars['String']>;
  weight_unit?: Maybe<WeightUnit>;
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
};

export type MutateFclLoadSpecInput = {
  id: Scalars['ID'];
  shipper_id?: Maybe<Scalars['ID']>;
  quote_request_id?: Maybe<Scalars['ID']>;
  incoterms?: Maybe<OceanIncoterms>;
  total_weight?: Maybe<Scalars['Float']>;
  weight_unit?: Maybe<WeightUnit>;
  is_overweight?: Maybe<Scalars['Boolean']>;
  include_customs_clearance?: Maybe<Scalars['Boolean']>;
  quote_type?: Maybe<QuoteType>;
  quote_types?: Maybe<Array<Maybe<QuoteType>>>;
  routing_type?: Maybe<RoutingType>;
  is_palletized?: Maybe<Scalars['Boolean']>;
  packaging_type?: Maybe<PackagingType>;
  packing_count?: Maybe<Scalars['Int']>;
  pallet_count?: Maybe<Scalars['Int']>;
  commodities?: Maybe<Scalars['String']>;
  capacity_guarantee_required?: Maybe<Scalars['Boolean']>;
  direct_sailing_required?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
  cargo_ready_date?: Maybe<Scalars['GraphbackDateTime']>;
  target_delivery_date?: Maybe<Scalars['GraphbackDateTime']>;
};

export type MutateFtlLoadSpecInput = {
  id: Scalars['ID'];
  shipper_id?: Maybe<Scalars['ID']>;
  quote_request_id?: Maybe<Scalars['ID']>;
  trailer_size?: Maybe<TrailerSize>;
  trailer_type?: Maybe<FtlTrailerType>;
  commodities?: Maybe<Scalars['String']>;
  is_palletized?: Maybe<Scalars['Boolean']>;
  weight_unit?: Maybe<WeightUnit>;
  max_temp?: Maybe<Scalars['Float']>;
  min_temp?: Maybe<Scalars['Float']>;
  temp?: Maybe<Scalars['Float']>;
  temp_unit?: Maybe<TempUnit>;
  packaging_type?: Maybe<PackagingType>;
  packing_count?: Maybe<Scalars['Int']>;
  pallet_type?: Maybe<PalletType>;
  pallet_count?: Maybe<Scalars['Int']>;
  unit_count?: Maybe<Scalars['Int']>;
  total_weight?: Maybe<Scalars['Float']>;
  weight_per_unit?: Maybe<Scalars['Float']>;
  truck_quantity?: Maybe<Scalars['Int']>;
  driver_preference?: Maybe<DriverPreference>;
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
};

export type MutateLtlLoadSpecInput = {
  id: Scalars['ID'];
  shipper_id?: Maybe<Scalars['ID']>;
  quote_request_id?: Maybe<Scalars['ID']>;
  total_weight?: Maybe<Scalars['Float']>;
  weight_unit?: Maybe<WeightUnit>;
  trailer_type?: Maybe<LtlTrailerType>;
  truck_id?: Maybe<Scalars['ID']>;
  max_temp?: Maybe<Scalars['Float']>;
  min_temp?: Maybe<Scalars['Float']>;
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
};

export type MutatePackageGroupInput = {
  id: Scalars['ID'];
  shipper_id?: Maybe<Scalars['ID']>;
  ltl_load_spec_id?: Maybe<Scalars['ID']>;
  packaging_type?: Maybe<PackagingType>;
  packing_count?: Maybe<Scalars['Int']>;
  pallet_count?: Maybe<Scalars['Int']>;
  freight_class?: Maybe<FreightClass>;
  nmfc_code?: Maybe<Scalars['String']>;
  is_stackable?: Maybe<Scalars['Boolean']>;
  item_quantity?: Maybe<Scalars['Int']>;
  weight_per_package?: Maybe<Scalars['Float']>;
  length_per_package?: Maybe<Scalars['Float']>;
  height_per_package?: Maybe<Scalars['Float']>;
  width_per_package?: Maybe<Scalars['Float']>;
  dim_unit?: Maybe<DimUnit>;
  commodities?: Maybe<Scalars['String']>;
  is_hazardous?: Maybe<Scalars['Boolean']>;
  hazardous_goods_details?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
};

export type MutateStopInput = {
  id: Scalars['ID'];
  shipper_id?: Maybe<Scalars['ID']>;
  address_id?: Maybe<Scalars['ID']>;
  quote_request_id?: Maybe<Scalars['ID']>;
  position?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  booking_notes?: Maybe<Scalars['String']>;
  is_time_tbd?: Maybe<Scalars['Boolean']>;
  is_na?: Maybe<Scalars['Boolean']>;
  start?: Maybe<Scalars['GraphbackDateTime']>;
  end?: Maybe<Scalars['GraphbackDateTime']>;
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
  reference_number?: Maybe<Scalars['String']>;
  distance_from_prior_stop?: Maybe<Scalars['Int']>;
};

export type MutateUserDefaultInput = {
  id: Scalars['ID'];
  user_id?: Maybe<Scalars['ID']>;
  plain_text_email?: Maybe<Scalars['Boolean']>;
  per_truck_notes?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  updateCargoDetails: QuoteRequest;
  createAddress?: Maybe<Address>;
  deleteAddress?: Maybe<Scalars['Boolean']>;
  updateAddressLabel?: Maybe<Address>;
  createContact?: Maybe<Contact>;
  deleteContact?: Maybe<Scalars['Boolean']>;
  updatePartner?: Maybe<Contact>;
  updateTeamMember?: Maybe<Contact>;
  updateContainersForQuoteRequest: QuoteRequest;
  replaceAccessorials?: Maybe<LtlLoadSpec>;
  cancelQuoteRequest: Scalars['Boolean'];
  closeQuoteRequest?: Maybe<Scalars['Boolean']>;
  createEmptyQuoteRequest?: Maybe<QuoteRequest>;
  createQuoteRequestFromTemplate?: Maybe<QuoteRequest>;
  createQuoteSubmissionLink?: Maybe<Scalars['String']>;
  createQuoteRequestFromExistingQuoteRequest?: Maybe<QuoteRequest>;
  sendNewQuoteRequestEmail?: Maybe<Scalars['Boolean']>;
  submitQuoteRequest?: Maybe<QuoteRequest>;
  updateQuoteRequest?: Maybe<QuoteRequest>;
  updateQuoteRequestPartners?: Maybe<QuoteRequest>;
  updateQuoteRequestTeam?: Maybe<QuoteRequest>;
  updateQuoteRequestRouting?: Maybe<QuoteRequest>;
  updateQuoteRequestRoutingDetails: QuoteRequest;
  updateQuoteRequestQuoteTypes: QuoteRequest;
  updateQuoteRequestWeightUnit?: Maybe<QuoteRequest>;
  createQuoteRequestTemplateFromQuoteRequest?: Maybe<QuoteRequestTemplate>;
  createPreviewPublicQuoteRequestLink?: Maybe<Scalars['String']>;
  updateStopsForQuoteRequest: QuoteRequest;
  updateStopDetails: Stop;
  completeSignupInvitation: User;
  updateSelf?: Maybe<User>;
  updateTruck?: Maybe<Truck>;
  updateTrucksForQuoteRequest: QuoteRequest;
  deleteFile: Scalars['Boolean'];
  generateUrlForFileUpload: GenerateUrlForFileUploadResult;
  /** (Portex Admin Only) */
  sendTestEmail: Scalars['Boolean'];
  /** (Portex Admin Only) */
  switchShipper: Scalars['Boolean'];
  /** (Portex Admin Only) */
  createShipper: Shipper;
  /** (Portex Admin Only) */
  impersonateCompany: User;
  /** (Portex Admin Only) */
  stopImpersonation: User;
  /** (Portex Admin Only) */
  inviteShipper: Scalars['Boolean'];
  /** (Portex Admin Only) */
  hideQuoteRequest: Scalars['Boolean'];
  /**
   * (Portex Super Admin Only)
   * development only
   */
  sendTransactionalEmail: SendTransactionalEmailResult;
  bookQuote: Scalars['Boolean'];
  submitAirQuote: Scalars['Boolean'];
  submitFclQuote: Scalars['Boolean'];
  submitQuote: Scalars['Boolean'];
  submitLtlQuote: Scalars['Boolean'];
  onboardShipper?: Maybe<SignupSurveyShipper>;
  createPartnerTag: Tag;
  createAirPackageGroup?: Maybe<AirPackageGroup>;
  updateAirPackageGroup?: Maybe<AirPackageGroup>;
  deleteAirPackageGroup?: Maybe<AirPackageGroup>;
  updateAirLoadSpec?: Maybe<AirLoadSpec>;
  createStop?: Maybe<Stop>;
  updateStop?: Maybe<Stop>;
  deleteStop?: Maybe<Stop>;
  updateFtlLoadSpec?: Maybe<FtlLoadSpec>;
  createDrayageLoadSpec?: Maybe<DrayageLoadSpec>;
  updateDrayageLoadSpec?: Maybe<DrayageLoadSpec>;
  deleteDrayageLoadSpec?: Maybe<DrayageLoadSpec>;
  updateLtlLoadSpec?: Maybe<LtlLoadSpec>;
  updateFclLoadSpec?: Maybe<FclLoadSpec>;
  createPackageGroup?: Maybe<PackageGroup>;
  updatePackageGroup?: Maybe<PackageGroup>;
  deletePackageGroup?: Maybe<PackageGroup>;
  createContainer?: Maybe<Container>;
  updateContainer?: Maybe<Container>;
  deleteContainer?: Maybe<Container>;
  createContactsQuoteRequests?: Maybe<ContactsQuoteRequests>;
  deleteContactsQuoteRequests?: Maybe<ContactsQuoteRequests>;
  updateUserDefault?: Maybe<UserDefault>;
};


export type MutationUpdateCargoDetailsArgs = {
  input: UpdateCargoDetailsInput;
};


export type MutationCreateAddressArgs = {
  input: CreateAddressInput;
};


export type MutationDeleteAddressArgs = {
  input: DeleteAddress;
};


export type MutationUpdateAddressLabelArgs = {
  input: UpdateAddressLabelInput;
};


export type MutationCreateContactArgs = {
  input: CreateContactInput;
};


export type MutationDeleteContactArgs = {
  input: DeleteContactInput;
};


export type MutationUpdatePartnerArgs = {
  input: UpdatePartnerInput;
};


export type MutationUpdateTeamMemberArgs = {
  input: UpdateTeamMemberInput;
};


export type MutationUpdateContainersForQuoteRequestArgs = {
  input: UpdateContainersForQuoteRequestInput;
};


export type MutationReplaceAccessorialsArgs = {
  input: ReplaceAccessorialsInput;
};


export type MutationCancelQuoteRequestArgs = {
  input: CancelQuoteRequestInput;
};


export type MutationCloseQuoteRequestArgs = {
  input: CloseQuoteRequestInput;
};


export type MutationCreateEmptyQuoteRequestArgs = {
  mode: Mode;
};


export type MutationCreateQuoteRequestFromTemplateArgs = {
  input: Scalars['ID'];
};


export type MutationCreateQuoteSubmissionLinkArgs = {
  input: CreateQuoteSubmissionLinkInput;
};


export type MutationCreateQuoteRequestFromExistingQuoteRequestArgs = {
  input: CreateQuoteRequestFromExistingQuoteRequestInput;
};


export type MutationSendNewQuoteRequestEmailArgs = {
  input: SendNewQuoteRequestEmailInput;
};


export type MutationSubmitQuoteRequestArgs = {
  input: SubmitQuoteRequestInput;
};


export type MutationUpdateQuoteRequestArgs = {
  input: UpdateQuoteRequestInput;
};


export type MutationUpdateQuoteRequestPartnersArgs = {
  input: UpdateQuoteRequestRecipientsInput;
};


export type MutationUpdateQuoteRequestTeamArgs = {
  input: UpdateQuoteRequestRecipientsInput;
};


export type MutationUpdateQuoteRequestRoutingArgs = {
  input: UpdateQuoteRequestRoutingInput;
};


export type MutationUpdateQuoteRequestRoutingDetailsArgs = {
  input: UpdateQuoteRequestRoutingDetailsInput;
};


export type MutationUpdateQuoteRequestQuoteTypesArgs = {
  input: UpdateQuoteRequestQuoteTypesInput;
};


export type MutationUpdateQuoteRequestWeightUnitArgs = {
  input: UpdateQuoteRequestWeightUnitInput;
};


export type MutationCreateQuoteRequestTemplateFromQuoteRequestArgs = {
  input: CreateQuoteRequestTemplateFromQuoteRequestInput;
};


export type MutationCreatePreviewPublicQuoteRequestLinkArgs = {
  input: CreatePreviewPublicQuoteRequestLinkInput;
};


export type MutationUpdateStopsForQuoteRequestArgs = {
  input: UpdateStopsForQuoteRequestInput;
};


export type MutationUpdateStopDetailsArgs = {
  input: UpdateStopDetailsInput;
};


export type MutationUpdateSelfArgs = {
  input: UpdateSelfInput;
};


export type MutationUpdateTruckArgs = {
  input: UpdateTruckInput;
};


export type MutationUpdateTrucksForQuoteRequestArgs = {
  input: UpdateTrucksForQuoteRequestInput;
};


export type MutationDeleteFileArgs = {
  input: DeleteFileInput;
};


export type MutationGenerateUrlForFileUploadArgs = {
  input: GenerateUrlForFileUploadInput;
};


export type MutationSendTestEmailArgs = {
  input: SendTestEmailInput;
};


export type MutationSwitchShipperArgs = {
  input: SwitchShipperInput;
};


export type MutationCreateShipperArgs = {
  input: CreateShipperInput;
};


export type MutationImpersonateCompanyArgs = {
  input: ImpersonateCompanyInput;
};


export type MutationStopImpersonationArgs = {
  input?: Maybe<StopImpersonationInput>;
};


export type MutationInviteShipperArgs = {
  input: InviteShipperInput;
};


export type MutationHideQuoteRequestArgs = {
  input: HideQuoteRequestInput;
};


export type MutationSendTransactionalEmailArgs = {
  input: SendTransactionalEmailInput;
};


export type MutationBookQuoteArgs = {
  input: BookQuoteInput;
};


export type MutationSubmitAirQuoteArgs = {
  input: SubmitAirQuoteInput;
};


export type MutationSubmitFclQuoteArgs = {
  input: SubmitFclQuoteInput;
};


export type MutationSubmitQuoteArgs = {
  input: SubmitQuoteInput;
};


export type MutationSubmitLtlQuoteArgs = {
  input: SubmitLtlQuoteInput;
};


export type MutationOnboardShipperArgs = {
  input: OnboardShipperInput;
};


export type MutationCreatePartnerTagArgs = {
  input: CreatePartnerTagInput;
};


export type MutationCreateAirPackageGroupArgs = {
  input: CreateAirPackageGroupInput;
};


export type MutationUpdateAirPackageGroupArgs = {
  input: MutateAirPackageGroupInput;
};


export type MutationDeleteAirPackageGroupArgs = {
  input: MutateAirPackageGroupInput;
};


export type MutationUpdateAirLoadSpecArgs = {
  input: MutateAirLoadSpecInput;
};


export type MutationCreateStopArgs = {
  input: CreateStopInput;
};


export type MutationUpdateStopArgs = {
  input: MutateStopInput;
};


export type MutationDeleteStopArgs = {
  input: MutateStopInput;
};


export type MutationUpdateFtlLoadSpecArgs = {
  input: MutateFtlLoadSpecInput;
};


export type MutationCreateDrayageLoadSpecArgs = {
  input: CreateDrayageLoadSpecInput;
};


export type MutationUpdateDrayageLoadSpecArgs = {
  input: MutateDrayageLoadSpecInput;
};


export type MutationDeleteDrayageLoadSpecArgs = {
  input: MutateDrayageLoadSpecInput;
};


export type MutationUpdateLtlLoadSpecArgs = {
  input: MutateLtlLoadSpecInput;
};


export type MutationUpdateFclLoadSpecArgs = {
  input: MutateFclLoadSpecInput;
};


export type MutationCreatePackageGroupArgs = {
  input: CreatePackageGroupInput;
};


export type MutationUpdatePackageGroupArgs = {
  input: MutatePackageGroupInput;
};


export type MutationDeletePackageGroupArgs = {
  input: MutatePackageGroupInput;
};


export type MutationCreateContainerArgs = {
  input: CreateContainerInput;
};


export type MutationUpdateContainerArgs = {
  input: MutateContainerInput;
};


export type MutationDeleteContainerArgs = {
  input: MutateContainerInput;
};


export type MutationCreateContactsQuoteRequestsArgs = {
  input: CreateContactsQuoteRequestsInput;
};


export type MutationDeleteContactsQuoteRequestsArgs = {
  input: MutateContactsQuoteRequestsInput;
};


export type MutationUpdateUserDefaultArgs = {
  input: MutateUserDefaultInput;
};

export type Node = {
  id: Scalars['ID'];
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
};

export type NodeUuid = {
  id: Scalars['String'];
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
};

/** Incoterms specific to Ocean modes (FCL / LCL) */
export enum OceanIncoterms {
  Fob = 'FOB',
  Exw = 'EXW',
  Fca = 'FCA',
  Fas = 'FAS',
  Cfr = 'CFR',
  Cif = 'CIF',
  Cpt = 'CPT',
  Cip = 'CIP',
  Dap = 'DAP',
  Dpu = 'DPU',
  Ddp = 'DDP'
}

export type OnboardShipperInput = {
  mode_ftl: Scalars['Boolean'];
  mode_fcl: Scalars['Boolean'];
  mode_air: Scalars['Boolean'];
  volume_ftl: ModeVolume;
  volume_fcl: ModeVolume;
  volume_air: ModeVolume;
  partner_count: PartnerCount;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  job_title?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  company_name: Scalars['String'];
};

export enum Order {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type OrderByFilterInput = {
  field: Scalars['String'];
  order?: Maybe<SortDirectionFilterEnum>;
};

export type OrderByInput = {
  field: Scalars['String'];
  order?: Maybe<SortDirectionEnum>;
};

export enum OutlookMode {
  MessageRead = 'MESSAGE_READ',
  MessageCompose = 'MESSAGE_COMPOSE'
}

/**
 * @db(name: 'package_groups')
 * @model(create: true, find: false, findOne: false, update: true, delete: true, subCreate: false, subUpdate: false, subDelete: false)
 */
export type PackageGroup = Node & {
  __typename?: 'PackageGroup';
  id: Scalars['ID'];
  /**
   * @manyToOne(field: 'package_groups', key: 'shipper_id')
   * @index
   */
  shipper: Shipper;
  /**
   * @manyToOne(field: 'package_groups', key: 'ltl_load_spec_id')
   * @index
   */
  ltl_load_spec: LtlLoadSpec;
  /** @db(type: 'text') */
  packaging_type?: Maybe<PackagingType>;
  packing_count?: Maybe<Scalars['Int']>;
  pallet_count?: Maybe<Scalars['Int']>;
  /** @db(type: 'text') */
  freight_class?: Maybe<FreightClass>;
  nmfc_code?: Maybe<Scalars['String']>;
  /** @default(value: true) */
  is_stackable?: Maybe<Scalars['Boolean']>;
  /** @default(value: 1) */
  item_quantity: Scalars['Int'];
  weight_per_package?: Maybe<Scalars['Float']>;
  length_per_package?: Maybe<Scalars['Float']>;
  height_per_package?: Maybe<Scalars['Float']>;
  width_per_package?: Maybe<Scalars['Float']>;
  /**
   * @db(type: 'text')
   * @default(value: 'IN')
   */
  dim_unit: DimUnit;
  /** @db(type: 'text') */
  commodities?: Maybe<Scalars['String']>;
  /** @default(value: false) */
  is_hazardous?: Maybe<Scalars['Boolean']>;
  /** @db(type: 'text') */
  hazardous_goods_details?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
};

export type PackageGroupFilter = {
  id?: Maybe<IdInput>;
  shipper_id?: Maybe<IdInput>;
  ltl_load_spec_id?: Maybe<IdInput>;
  packaging_type?: Maybe<StringInput>;
  packing_count?: Maybe<IntInput>;
  pallet_count?: Maybe<IntInput>;
  freight_class?: Maybe<StringInput>;
  nmfc_code?: Maybe<StringInput>;
  is_stackable?: Maybe<BooleanInput>;
  item_quantity?: Maybe<IntInput>;
  weight_per_package?: Maybe<FloatInput>;
  length_per_package?: Maybe<FloatInput>;
  height_per_package?: Maybe<FloatInput>;
  width_per_package?: Maybe<FloatInput>;
  dim_unit?: Maybe<StringInput>;
  commodities?: Maybe<StringInput>;
  is_hazardous?: Maybe<BooleanInput>;
  hazardous_goods_details?: Maybe<StringInput>;
  created_at?: Maybe<GraphbackDateTimeInput>;
  updated_at?: Maybe<GraphbackDateTimeInput>;
  and?: Maybe<Array<PackageGroupFilter>>;
  or?: Maybe<Array<PackageGroupFilter>>;
  not?: Maybe<PackageGroupFilter>;
};

/**
 * @db(name: 'package_group_templates')
 * @model(create: false, find: false, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type PackageGroupTemplate = Node & {
  __typename?: 'PackageGroupTemplate';
  id: Scalars['ID'];
  /**
   * @manyToOne(field: 'package_group_templates', key: 'shipper_id')
   * @index
   */
  shipper: Shipper;
  /**
   * @manyToOne(field: 'package_group_templates', key: 'ltl_load_spec_template_id')
   * @index
   */
  ltl_load_spec_template: LtlLoadSpecTemplate;
  /** @db(type: 'text') */
  packaging_type?: Maybe<PackagingType>;
  /** @db(type: 'text') */
  freight_class?: Maybe<FreightClass>;
  nmfc_code?: Maybe<Scalars['String']>;
  /** @default(value: true) */
  is_stackable?: Maybe<Scalars['Boolean']>;
  /** @default(value: 1) */
  item_quantity: Scalars['Int'];
  weight_per_package?: Maybe<Scalars['Float']>;
  length_per_package?: Maybe<Scalars['Float']>;
  height_per_package?: Maybe<Scalars['Float']>;
  width_per_package?: Maybe<Scalars['Float']>;
  /**
   * @db(type: 'text')
   * @default(value: 'IN')
   */
  dim_unit: DimUnit;
  /** @db(type: 'text') */
  commodities?: Maybe<Scalars['String']>;
  /** @default(value: false) */
  is_hazardous?: Maybe<Scalars['Boolean']>;
  /** @db(type: 'text') */
  hazardous_goods_details?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
};

export type PackageGroupTemplateFilter = {
  id?: Maybe<IdInput>;
  shipper_id?: Maybe<IdInput>;
  ltl_load_spec_template_id?: Maybe<IdInput>;
  packaging_type?: Maybe<StringInput>;
  freight_class?: Maybe<StringInput>;
  nmfc_code?: Maybe<StringInput>;
  is_stackable?: Maybe<BooleanInput>;
  item_quantity?: Maybe<IntInput>;
  weight_per_package?: Maybe<FloatInput>;
  length_per_package?: Maybe<FloatInput>;
  height_per_package?: Maybe<FloatInput>;
  width_per_package?: Maybe<FloatInput>;
  dim_unit?: Maybe<StringInput>;
  commodities?: Maybe<StringInput>;
  is_hazardous?: Maybe<BooleanInput>;
  hazardous_goods_details?: Maybe<StringInput>;
  created_at?: Maybe<GraphbackDateTimeInput>;
  updated_at?: Maybe<GraphbackDateTimeInput>;
  and?: Maybe<Array<PackageGroupTemplateFilter>>;
  or?: Maybe<Array<PackageGroupTemplateFilter>>;
  not?: Maybe<PackageGroupTemplateFilter>;
};

export enum PackagingType {
  Pallets_48_48 = 'PALLETS_48_48',
  Pallets_48_40 = 'PALLETS_48_40',
  Cartons = 'CARTONS',
  Pallets = 'PALLETS',
  Bags = 'BAGS',
  Bales = 'BALES',
  Barrels = 'BARRELS',
  Boxes = 'BOXES',
  Bundles = 'BUNDLES',
  Coils = 'COILS',
  Containers = 'CONTAINERS',
  Crates = 'CRATES',
  Rolls = 'ROLLS',
  Skids = 'SKIDS',
  Totes = 'TOTES',
  Other = 'OTHER'
}

export type PageInput = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type PageRequest = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export enum PalletType {
  Pallet_48_40 = 'PALLET_48_40',
  Pallet_42_42 = 'PALLET_42_42',
  Pallet_48_48 = 'PALLET_48_48',
  Pallet_40_48 = 'PALLET_40_48',
  Pallet_48_42 = 'PALLET_48_42',
  Pallet_40_40 = 'PALLET_40_40',
  Pallet_48_45 = 'PALLET_48_45',
  Pallet_44_44 = 'PALLET_44_44',
  Pallet_36_36 = 'PALLET_36_36',
  Pallet_48_36 = 'PALLET_48_36',
  Pallet_45_455 = 'PALLET_45_455',
  Pallet_48_20 = 'PALLET_48_20',
  PalletNotSpecified = 'PALLET_NOT_SPECIFIED'
}

export type PartnerAcceptanceRateStatsInput = {
  insightInput: InsightInput;
  orderBy?: Maybe<Order>;
  count?: Maybe<Scalars['Int']>;
};

export type PartnerAcceptanceRateStatsRequest = {
  __typename?: 'PartnerAcceptanceRateStatsRequest';
  stats: Array<PartnerStat>;
};

export type PartnerCostStatsInput = {
  insightInput: InsightInput;
  quoteStatus?: Maybe<QuoteStatus>;
  orderBy?: Maybe<Order>;
  count?: Maybe<Scalars['Int']>;
};

export type PartnerCostStatsRequest = {
  __typename?: 'PartnerCostStatsRequest';
  stats: Array<PartnerStat>;
};

export enum PartnerCount {
  PartnerCount_0 = 'PARTNER_COUNT_0',
  PartnerCount_1 = 'PARTNER_COUNT_1',
  PartnerCount_2 = 'PARTNER_COUNT_2',
  PartnerCount_3 = 'PARTNER_COUNT_3'
}

export type PartnerFilter = {
  email?: Maybe<StringFilterInput>;
  first_name?: Maybe<StringFilterInput>;
  last_name?: Maybe<StringFilterInput>;
  company_name?: Maybe<StringFilterInput>;
  phone_number?: Maybe<StringFilterInput>;
  city?: Maybe<StringFilterInput>;
  state?: Maybe<StringFilterInput>;
  tags?: Maybe<Array<Scalars['ID']>>;
  or?: Maybe<Array<PartnerFilter>>;
};

export type PartnerResultList = ResultList & {
  __typename?: 'PartnerResultList';
  items: Array<Maybe<Contact>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
};

export type PartnerStat = {
  __typename?: 'PartnerStat';
  partnerName: Scalars['String'];
  value: Scalars['Float'];
};

export type PartnerVolumeStatsInput = {
  insightInput: InsightInput;
  quoteStatus?: Maybe<QuoteStatus>;
  orderBy?: Maybe<Order>;
  count?: Maybe<Scalars['Int']>;
};

export type PartnerVolumeStatsRequest = {
  __typename?: 'PartnerVolumeStatsRequest';
  stats: Array<PartnerStat>;
};

/**
 * @db(name: 'ports')
 * @model(create: false, find: true, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type Port = Node & {
  __typename?: 'Port';
  id: Scalars['ID'];
  name: Scalars['String'];
  lowercase_name: Scalars['String'];
  country_name: Scalars['String'];
  country_code: Scalars['String'];
  city: Scalars['String'];
  province_code?: Maybe<Scalars['String']>;
  province_name?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['String']>;
  lon?: Maybe<Scalars['String']>;
  /** @transient */
  iana_timezone: Scalars['String'];
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
};

export type PortFilter = {
  id?: Maybe<IdInput>;
  name?: Maybe<StringInput>;
  lowercase_name?: Maybe<StringInput>;
  country_name?: Maybe<StringInput>;
  country_code?: Maybe<StringInput>;
  city?: Maybe<StringInput>;
  province_code?: Maybe<StringInput>;
  province_name?: Maybe<StringInput>;
  lat?: Maybe<StringInput>;
  lon?: Maybe<StringInput>;
  created_at?: Maybe<GraphbackDateTimeInput>;
  updated_at?: Maybe<GraphbackDateTimeInput>;
  and?: Maybe<Array<PortFilter>>;
  or?: Maybe<Array<PortFilter>>;
  not?: Maybe<PortFilter>;
};

export type PortResultList = {
  __typename?: 'PortResultList';
  items: Array<Maybe<Port>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
};

export type PreviewPublicQuoteRequestInput = {
  quoteRequestId: Scalars['ID'];
};

export type PublicChargeAir = {
  __typename?: 'PublicChargeAir';
  name: Scalars['String'];
  type: ChargeType;
  /** item quantity * weight */
  quantity: Scalars['Float'];
  item_quantity?: Maybe<Scalars['Int']>;
  unit: ChargeUnitType;
};

export type PublicChargeFcl = {
  __typename?: 'PublicChargeFcl';
  name: Scalars['String'];
  type: ChargeType;
  quantity: Scalars['Int'];
  unit: ChargeUnitType;
};

export type PublicContainer = {
  __typename?: 'PublicContainer';
  size: ContainerSize;
  type: ContainerType;
  max_temp?: Maybe<Scalars['Float']>;
  min_temp?: Maybe<Scalars['Float']>;
  requires_genset: Scalars['Boolean'];
  volume?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

export type PublicLocation = {
  __typename?: 'PublicLocation';
  address_id: Scalars['String'];
  position: Scalars['Int'];
  formatted_long_name: Scalars['String'];
  formatted_short_name: Scalars['String'];
  port_name: Scalars['String'];
  airport_iata_code: Scalars['String'];
  airport_name: Scalars['String'];
  type: AddressType;
  city: Scalars['String'];
  province_code: Maybe<Scalars['String']>;
  province_name: Maybe<Scalars['String']>;
  country_code: Scalars['String'];
  country_name: Scalars['String'];
  zip: Scalars['String'];
  start?: Maybe<Scalars['GraphbackDateTime']>;
  end?: Maybe<Scalars['GraphbackDateTime']>;
  is_time_tbd: Scalars['Boolean'];
  iana_timezone: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type PublicPackageGroup = {
  __typename?: 'PublicPackageGroup';
  commodities?: Maybe<Scalars['String']>;
  hazardous_goods_details?: Maybe<Scalars['String']>;
  is_hazardous?: Maybe<Scalars['Boolean']>;
  item_quantity: Scalars['Int'];
  packaging_type: PackagingType;
  per_package_dimensions_height?: Maybe<Scalars['Float']>;
  per_package_dimensions_length?: Maybe<Scalars['Float']>;
  per_package_dimensions_unit: DimUnit;
  per_package_dimensions_width?: Maybe<Scalars['Float']>;
  per_package_volume_unit: VolumeFormat;
  per_package_volume?: Maybe<Scalars['Float']>;
  per_package_weight_unit: WeightUnit;
  per_package_weight: Scalars['Float'];
};

export type PublicQuoteRequest = {
  __typename?: 'PublicQuoteRequest';
  shipper_id: Scalars['String'];
  accessorials: Array<Accessorial>;
  capacity_guarantee_required: Scalars['Boolean'];
  cargo_ready_date_tz: Scalars['String'];
  cargo_ready_date?: Maybe<Scalars['GraphbackDateTime']>;
  charges: Array<PublicChargeFcl>;
  charges_air: Array<PublicChargeAir>;
  commodities?: Maybe<Scalars['String']>;
  containers: Array<PublicContainer>;
  deadline_respond_at?: Maybe<Scalars['GraphbackDateTime']>;
  direct_sailing_required: Scalars['Boolean'];
  guid: Scalars['String'];
  hazardous_goods_details: Scalars['String'];
  include_customs_clearance: Scalars['Boolean'];
  incoterms_air?: Maybe<AirIncoterms>;
  incoterms_ocean?: Maybe<OceanIncoterms>;
  insurance_required: Scalars['Boolean'];
  is_hazardous: Scalars['Boolean'];
  is_overweight: Scalars['Boolean'];
  item_packaging: Scalars['String'];
  item_quantity?: Maybe<Scalars['Int']>;
  item_type?: Maybe<PackagingType>;
  locations: Array<Maybe<PublicLocation>>;
  ltl_trailer_type?: Maybe<LtlTrailerType>;
  max_temp?: Maybe<Scalars['Float']>;
  min_temp?: Maybe<Scalars['Float']>;
  mode: Mode;
  notes?: Maybe<Scalars['String']>;
  package_groups: Array<PublicPackageGroup>;
  package_groups_ltl: Array<LtlPublicPackageGroup>;
  packing_count?: Maybe<Scalars['Int']>;
  pallet_count?: Maybe<Scalars['Int']>;
  pallet_type: PalletType;
  /** @deprecated use dimensions from individual items in 'package_groups' */
  per_package_dimensions_height?: Maybe<Scalars['Float']>;
  /** @deprecated use dimensions from individual items in 'package_groups' */
  per_package_dimensions_length?: Maybe<Scalars['Float']>;
  /** @deprecated use dimensions from individual items in 'package_groups' */
  per_package_dimensions_width?: Maybe<Scalars['Float']>;
  portex_id: Scalars['String'];
  quote_type?: Maybe<QuoteType>;
  quote_types: Array<QuoteType>;
  reference_number: Scalars['String'];
  routing_type?: Maybe<RoutingType>;
  service_levels: Array<ServiceLevel>;
  shipper_name: Scalars['String'];
  state: QuoteRequestState;
  subject_line: Scalars['String'];
  target_delivery_date_tz: Scalars['String'];
  target_delivery_date?: Maybe<Scalars['GraphbackDateTime']>;
  temp_unit?: Maybe<TempUnit>;
  temp?: Maybe<Scalars['Float']>;
  total_volume?: Maybe<Scalars['Float']>;
  total_weight?: Maybe<Scalars['Float']>;
  trailer_size?: Maybe<TrailerSize>;
  trailer_type?: Maybe<FtlTrailerType>;
  truck_quantity?: Maybe<Scalars['Int']>;
  /** @default(value: 'NONE') */
  driver_preference?: Maybe<DriverPreference>;
  type?: Maybe<RequestType>;
  /**  @summary derived from `QuoteRequest.unloaded_details`  */
  unloading_details?: Maybe<UnloadingDetails>;
  volume_format?: Maybe<VolumeFormat>;
  weight_unit?: Maybe<WeightUnit>;
  goods_value?: Maybe<Scalars['Float']>;
  carrier_routing_pref_notes?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  findAddressesForMode: FindAddressesForModeResult;
  findContainersForQuoteRequest: FindContainersForQuoteRequestResult;
  getPublicQuoteRequest?: Maybe<PublicQuoteRequest>;
  previewPublicQuoteRequest?: Maybe<PublicQuoteRequest>;
  viewPublicQuoteRequest?: Maybe<PublicQuoteRequest>;
  getFreightSpendByLane: FreightSpendByLaneResponse;
  getInsightReport?: Maybe<InsightReportRequest>;
  getInsightReportFile?: Maybe<InsightReportFileRequest>;
  getQuoteCounts?: Maybe<QuoteCountsRequest>;
  getQuoteCosts?: Maybe<QuoteCostsRequest>;
  getQuoteFrequency?: Maybe<QuoteFrequencyRequest>;
  getPartnerVolumeStats?: Maybe<PartnerVolumeStatsRequest>;
  getPartnerCostStats?: Maybe<PartnerCostStatsRequest>;
  getPartnerAcceptanceRateStats?: Maybe<PartnerAcceptanceRateStatsRequest>;
  findStopsForQuoteRequest: FindStopsForQuoteRequestResult;
  getCurrentUser: User;
  getCurrentUserDefault: UserDefault;
  findFilesForQuote: Array<File>;
  get7DayRateBenchmark: FreightWavesRateBenchmark;
  getBenchmarkFTL: FreightWavesRateBenchmark;
  /** (Portex Admin Only) */
  getShippers: GetShippersResult;
  getPortexAdmins: GetPortexAdmins;
  getAirQuoteTotals: AirQuoteTotals;
  getFclQuoteTotals: FclQuoteTotals;
  getShipperPartnerTags: Array<Tag>;
  findPartners: PartnerResultList;
  findAddresses: AddressResultList;
  getContact?: Maybe<Contact>;
  findContacts: ContactResultList;
  getLtlLoadSpecTemplate?: Maybe<LtlLoadSpecTemplate>;
  getQuoteRequest?: Maybe<QuoteRequest>;
  findQuoteRequests: QuoteRequestResultList;
  findQuoteRequestTemplates: QuoteRequestTemplateResultList;
  findContactsQuoteRequests: ContactsQuoteRequestsResultList;
  getQuote?: Maybe<Quote>;
  getAirQuote?: Maybe<AirQuote>;
  getFclQuote?: Maybe<FclQuote>;
  getLtlQuote?: Maybe<LtlQuote>;
  findAccessorials: AccessorialResultList;
  getAirport?: Maybe<Airport>;
  findAirports: AirportResultList;
  findPorts: PortResultList;
  getTempToken?: Maybe<TempToken>;
};


export type QueryFindAddressesForModeArgs = {
  input: FindAddressesForModeInput;
};


export type QueryFindContainersForQuoteRequestArgs = {
  input: FindContainersForQuoteRequestInput;
};


export type QueryPreviewPublicQuoteRequestArgs = {
  input?: Maybe<PreviewPublicQuoteRequestInput>;
};


export type QueryViewPublicQuoteRequestArgs = {
  input: ViewPublicQuoteRequestInput;
};


export type QueryGetFreightSpendByLaneArgs = {
  input: FreightSpendByLaneInput;
};


export type QueryGetInsightReportArgs = {
  input: InsightReportInput;
  page?: Maybe<PageInput>;
};


export type QueryGetInsightReportFileArgs = {
  input: InsightReportInput;
};


export type QueryGetQuoteCountsArgs = {
  input: InsightInput;
};


export type QueryGetQuoteCostsArgs = {
  input: InsightInput;
};


export type QueryGetQuoteFrequencyArgs = {
  input: InsightInput;
  timeScale: QuoteFrequencyTimeScale;
};


export type QueryGetPartnerVolumeStatsArgs = {
  input: PartnerVolumeStatsInput;
};


export type QueryGetPartnerCostStatsArgs = {
  input: PartnerCostStatsInput;
};


export type QueryGetPartnerAcceptanceRateStatsArgs = {
  input: PartnerAcceptanceRateStatsInput;
};


export type QueryFindStopsForQuoteRequestArgs = {
  input: FindStopsForQuoteRequestInput;
};


export type QueryFindFilesForQuoteArgs = {
  input: FindFilesForQuoteInput;
};


export type QueryGet7DayRateBenchmarkArgs = {
  input: Get7DayRateBenchmarkInput;
};


export type QueryGetBenchmarkFtlArgs = {
  input: GetBenchmarkFtlInput;
};


export type QueryGetAirQuoteTotalsArgs = {
  input: GetAirQuoteTotalsInput;
};


export type QueryGetFclQuoteTotalsArgs = {
  input: GetFclQuoteTotalsInput;
};


export type QueryGetShipperPartnerTagsArgs = {
  input?: Maybe<GetShipperPartnerTagsInput>;
};


export type QueryFindPartnersArgs = {
  filter?: Maybe<PartnerFilter>;
  page?: Maybe<PageInput>;
  orderBy?: Maybe<OrderByFilterInput>;
};


export type QueryFindAddressesArgs = {
  filter?: Maybe<AddressFilter>;
  page?: Maybe<PageRequest>;
  orderBy?: Maybe<OrderByInput>;
};


export type QueryGetContactArgs = {
  id: Scalars['ID'];
};


export type QueryFindContactsArgs = {
  filter?: Maybe<ContactFilter>;
  page?: Maybe<PageRequest>;
  orderBy?: Maybe<OrderByInput>;
};


export type QueryGetLtlLoadSpecTemplateArgs = {
  id: Scalars['ID'];
};


export type QueryGetQuoteRequestArgs = {
  id: Scalars['ID'];
};


export type QueryFindQuoteRequestsArgs = {
  filter?: Maybe<QuoteRequestFilter>;
  page?: Maybe<PageRequest>;
  orderBy?: Maybe<OrderByInput>;
};


export type QueryFindQuoteRequestTemplatesArgs = {
  filter?: Maybe<QuoteRequestTemplateFilter>;
  page?: Maybe<PageRequest>;
  orderBy?: Maybe<OrderByInput>;
};


export type QueryFindContactsQuoteRequestsArgs = {
  filter?: Maybe<ContactsQuoteRequestsFilter>;
  page?: Maybe<PageRequest>;
  orderBy?: Maybe<OrderByInput>;
};


export type QueryGetQuoteArgs = {
  id: Scalars['ID'];
};


export type QueryGetAirQuoteArgs = {
  id: Scalars['ID'];
};


export type QueryGetFclQuoteArgs = {
  id: Scalars['ID'];
};


export type QueryGetLtlQuoteArgs = {
  id: Scalars['ID'];
};


export type QueryFindAccessorialsArgs = {
  filter?: Maybe<AccessorialFilter>;
  page?: Maybe<PageRequest>;
  orderBy?: Maybe<OrderByInput>;
};


export type QueryGetAirportArgs = {
  id: Scalars['ID'];
};


export type QueryFindAirportsArgs = {
  filter?: Maybe<AirportFilter>;
  page?: Maybe<PageRequest>;
  orderBy?: Maybe<OrderByInput>;
};


export type QueryFindPortsArgs = {
  filter?: Maybe<PortFilter>;
  page?: Maybe<PageRequest>;
  orderBy?: Maybe<OrderByInput>;
};


export type QueryGetTempTokenArgs = {
  id: Scalars['ID'];
};

/**
 * @db(name: 'quotes')
 * @model(create: false, find: false, findOne: true, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type Quote = Node & {
  __typename?: 'Quote';
  id: Scalars['ID'];
  /**
   * @manyToOne(field: 'quotes', key: 'shipper_id')
   * @index
   */
  shipper: Shipper;
  /**
   * @manyToOne(field: 'quotes', key: 'quote_request_id')
   * @index
   */
  quote_request: QuoteRequest;
  submitter_email: Scalars['String'];
  total_amount: Scalars['Float'];
  rate_per_load: Scalars['Float'];
  valid_until: Scalars['GraphbackDateTime'];
  submitter_tz?: Maybe<Scalars['String']>;
  /**
   * @db(type: 'text')
   * @default(value: 'SUBMITTED')
   */
  status: QuoteStatus;
  company_name?: Maybe<Scalars['String']>;
  /** @db(type: 'text') */
  notes?: Maybe<Scalars['String']>;
  /** @db(type: 'text') */
  booking_notes?: Maybe<Scalars['String']>;
  benchmark?: Maybe<Scalars['Float']>;
  /** @oneToOne(field: 'load', key: 'load_id') */
  load?: Maybe<Load>;
  /** @transient */
  conversation_id?: Maybe<Scalars['ID']>;
  /** @transient */
  files: Array<File>;
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
};

export type QuoteChargeTotals = {
  __typename?: 'QuoteChargeTotals';
  origin: Scalars['Float'];
  freight: Scalars['Float'];
  destination: Scalars['Float'];
  customs: Scalars['Float'];
  insurance: Scalars['Float'];
};

export type QuoteContactStatus = {
  __typename?: 'QuoteContactStatus';
  contact?: Maybe<Contact>;
  status?: Maybe<QuoteContactStatusField>;
};

export enum QuoteContactStatusField {
  Pending = 'PENDING',
  Responded = 'RESPONDED',
  NotRequested = 'NOT_REQUESTED'
}

export type QuoteCostsRequest = {
  __typename?: 'QuoteCostsRequest';
  returnedCost: Scalars['Float'];
  bookedCost: Scalars['Float'];
};

export type QuoteCountsRequest = {
  __typename?: 'QuoteCountsRequest';
  requestedCount: Scalars['Int'];
  returnedCount: Scalars['Int'];
  bookedCount: Scalars['Int'];
};

export type QuoteFilter = {
  id?: Maybe<IdInput>;
  shipper_id?: Maybe<IdInput>;
  quote_request_id?: Maybe<IdInput>;
  submitter_email?: Maybe<StringInput>;
  total_amount?: Maybe<FloatInput>;
  rate_per_load?: Maybe<FloatInput>;
  valid_until?: Maybe<GraphbackDateTimeInput>;
  submitter_tz?: Maybe<StringInput>;
  status?: Maybe<StringInput>;
  company_name?: Maybe<StringInput>;
  notes?: Maybe<StringInput>;
  booking_notes?: Maybe<StringInput>;
  benchmark?: Maybe<FloatInput>;
  load_id?: Maybe<IdInput>;
  created_at?: Maybe<GraphbackDateTimeInput>;
  updated_at?: Maybe<GraphbackDateTimeInput>;
  and?: Maybe<Array<QuoteFilter>>;
  or?: Maybe<Array<QuoteFilter>>;
  not?: Maybe<QuoteFilter>;
};

export type QuoteFrequencyRequest = {
  __typename?: 'QuoteFrequencyRequest';
  result: Array<DateCount>;
};

export enum QuoteFrequencyTimeScale {
  Daily = 'DAILY',
  Weekly = 'WEEKLY',
  Monthly = 'MONTHLY'
}

/**
 * @db(name: 'quote_requests')
 * @model(create: false, find: true, findOne: true, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type QuoteRequest = Node & {
  __typename?: 'QuoteRequest';
  id: Scalars['ID'];
  guid?: Maybe<Scalars['String']>;
  /** @db(type: 'text') */
  mode: Mode;
  /**
   * @default(value: 'QUOTE')
   * @db(type: 'text')
   */
  type?: Maybe<RequestType>;
  /**
   * @manyToOne(field: 'quote_requests', key: 'shipper_id')
   * @index
   */
  shipper: Shipper;
  /**
   * @manyToOne(field: 'quote_requests', key: 'user_id')
   * @index
   */
  user: User;
  /** @oneToMany(field: 'quote_request', key: 'quote_request_id') */
  quotes: Array<Quote>;
  /** @oneToMany(field: 'quote_request', key: 'quote_request_id') */
  fcl_quotes: Array<FclQuote>;
  /** @oneToMany(field: 'quote_request', key: 'quote_request_id') */
  air_quotes: Array<AirQuote>;
  /** @oneToMany(field: 'quote_request', key: 'quote_request_id') */
  ltl_quotes: Array<LtlQuote>;
  /** @oneToMany(field: 'quote_request', key: 'quote_request_id') */
  fcl_quote_charges: Array<FclQuoteCharge>;
  /** @oneToMany(field: 'quote_request', key: 'quote_request_id') */
  air_quote_charges: Array<AirQuoteCharge>;
  /** @oneToMany(field: 'quote_request', key: 'quote_request_id') */
  stops: Array<Stop>;
  /** @oneToMany(field: 'quote_request', key: 'quote_request_id') */
  drayage_load_specs: Array<DrayageLoadSpec>;
  /** @oneToMany(field: 'quote_request', key: 'quote_request_id') */
  ftl_load_specs: Array<FtlLoadSpec>;
  /** @oneToOne(field: 'ltl_load_spec', key: 'ltl_load_spec_id') */
  ltl_load_spec?: Maybe<LtlLoadSpec>;
  /** @oneToOne(field: 'fcl_load_spec', key: 'fcl_load_spec_id') */
  fcl_load_spec?: Maybe<FclLoadSpec>;
  /** @oneToOne(field: 'air_load_spec', key: 'air_load_spec_id') */
  air_load_spec?: Maybe<AirLoadSpec>;
  /** @db(type: 'text') */
  note?: Maybe<Scalars['String']>;
  /**
   * @db(type: 'text')
   * @summary Shipments that have containers require Unloading Details to help the Freight Forwarder understand how the container needs to be unloaded.
   * @see https://portexpro.atlassian.net/browse/POR-2327
   */
  unloading_details?: Maybe<UnloadingDetails>;
  is_hazardous?: Maybe<Scalars['Boolean']>;
  /** @db(type: 'text') */
  hazardous_goods_details?: Maybe<Scalars['String']>;
  /** @default(value: false) */
  insurance_required?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
  /** @oneToMany(field: 'quote_request', key: 'quote_request_id') */
  recipients: Array<ContactsQuoteRequests>;
  deadline_respond_at?: Maybe<Scalars['GraphbackDateTime']>;
  /** @default(value: false) */
  is_visible?: Maybe<Scalars['Boolean']>;
  /**
   * @default(value: 'DRAFT')
   * @db(type: 'text')
   */
  state: QuoteRequestState;
  reference_number?: Maybe<Scalars['String']>;
  requested_at?: Maybe<Scalars['GraphbackDateTime']>;
  /** @db(length: 10000) */
  email_html_body?: Maybe<Scalars['String']>;
  portex_id?: Maybe<Scalars['String']>;
  /** @oneToOne(field: 'booked_quote', key: 'booked_quote_id') */
  booked_quote?: Maybe<Quote>;
  /** @oneToOne(field: 'booked_fcl_quote', key: 'booked_fcl_quote_id') */
  booked_fcl_quote?: Maybe<FclQuote>;
  /** @oneToOne(field: 'booked_air_quote', key: 'booked_air_quote_id') */
  booked_air_quote?: Maybe<AirQuote>;
  /** @oneToOne(field: 'booked_ltl_quote', key: 'booked_ltl_quote_id') */
  booked_ltl_quote?: Maybe<LtlQuote>;
  booked_at?: Maybe<Scalars['GraphbackDateTime']>;
  is_plain_text?: Maybe<Scalars['Boolean']>;
  /** @manyToOne(field: 'quote_requests_booked', key: 'booked_by_id') */
  booked_by?: Maybe<User>;
  /** @manyToOne(field: 'quote_requests', key: 'quote_request_template_id') */
  quote_request_template?: Maybe<QuoteRequestTemplate>;
  closed_at?: Maybe<Scalars['GraphbackDateTime']>;
  /** @manyToOne(field: 'quote_requests_closed', key: 'closed_by_id') */
  closed_by?: Maybe<User>;
  /** @db(type: 'text') */
  closed_reason?: Maybe<Scalars['String']>;
  /** @default(value: false) */
  requested_from_web_app?: Maybe<Scalars['Boolean']>;
  outlook_mode_when_requested?: Maybe<OutlookMode>;
  /** @oneToOne(field: 'load', key: 'load_id') */
  load?: Maybe<Load>;
  /** @transient */
  recipients_pending_response: Array<Contact>;
  /** @transient */
  recipients_status_all: Array<QuoteContactStatus>;
  goods_value?: Maybe<Scalars['Float']>;
  /** @db(type: 'text') */
  carrier_routing_pref_notes?: Maybe<Scalars['String']>;
  canceled_at?: Maybe<Scalars['GraphbackDateTime']>;
  /** @manyToOne(field: 'quote_requests_canceled', key: 'canceled_by_id') */
  canceled_by?: Maybe<User>;
  /** @db(type: 'text') */
  canceled_reason?: Maybe<Scalars['String']>;
  original_qr_id?: Maybe<Scalars['Int']>;
};


/**
 * @db(name: 'quote_requests')
 * @model(create: false, find: true, findOne: true, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type QuoteRequestQuotesArgs = {
  filter?: Maybe<QuoteFilter>;
};


/**
 * @db(name: 'quote_requests')
 * @model(create: false, find: true, findOne: true, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type QuoteRequestFcl_QuotesArgs = {
  filter?: Maybe<FclQuoteFilter>;
};


/**
 * @db(name: 'quote_requests')
 * @model(create: false, find: true, findOne: true, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type QuoteRequestAir_QuotesArgs = {
  filter?: Maybe<AirQuoteFilter>;
};


/**
 * @db(name: 'quote_requests')
 * @model(create: false, find: true, findOne: true, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type QuoteRequestLtl_QuotesArgs = {
  filter?: Maybe<LtlQuoteFilter>;
};


/**
 * @db(name: 'quote_requests')
 * @model(create: false, find: true, findOne: true, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type QuoteRequestFcl_Quote_ChargesArgs = {
  filter?: Maybe<FclQuoteChargeFilter>;
};


/**
 * @db(name: 'quote_requests')
 * @model(create: false, find: true, findOne: true, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type QuoteRequestAir_Quote_ChargesArgs = {
  filter?: Maybe<AirQuoteChargeFilter>;
};


/**
 * @db(name: 'quote_requests')
 * @model(create: false, find: true, findOne: true, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type QuoteRequestStopsArgs = {
  filter?: Maybe<StopFilter>;
};


/**
 * @db(name: 'quote_requests')
 * @model(create: false, find: true, findOne: true, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type QuoteRequestDrayage_Load_SpecsArgs = {
  filter?: Maybe<DrayageLoadSpecFilter>;
};


/**
 * @db(name: 'quote_requests')
 * @model(create: false, find: true, findOne: true, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type QuoteRequestFtl_Load_SpecsArgs = {
  filter?: Maybe<FtlLoadSpecFilter>;
};


/**
 * @db(name: 'quote_requests')
 * @model(create: false, find: true, findOne: true, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type QuoteRequestRecipientsArgs = {
  filter?: Maybe<ContactsQuoteRequestsFilter>;
};

export type QuoteRequestFilter = {
  id?: Maybe<IdInput>;
  guid?: Maybe<StringInput>;
  mode?: Maybe<StringInput>;
  type?: Maybe<StringInput>;
  shipper_id?: Maybe<IdInput>;
  user_id?: Maybe<IdInput>;
  ltl_load_spec_id?: Maybe<IdInput>;
  fcl_load_spec_id?: Maybe<IdInput>;
  air_load_spec_id?: Maybe<IdInput>;
  note?: Maybe<StringInput>;
  unloading_details?: Maybe<StringInput>;
  is_hazardous?: Maybe<BooleanInput>;
  hazardous_goods_details?: Maybe<StringInput>;
  insurance_required?: Maybe<BooleanInput>;
  created_at?: Maybe<GraphbackDateTimeInput>;
  updated_at?: Maybe<GraphbackDateTimeInput>;
  deadline_respond_at?: Maybe<GraphbackDateTimeInput>;
  is_visible?: Maybe<BooleanInput>;
  state?: Maybe<StringInput>;
  reference_number?: Maybe<StringInput>;
  requested_at?: Maybe<GraphbackDateTimeInput>;
  email_html_body?: Maybe<StringInput>;
  portex_id?: Maybe<StringInput>;
  booked_quote_id?: Maybe<IdInput>;
  booked_fcl_quote_id?: Maybe<IdInput>;
  booked_air_quote_id?: Maybe<IdInput>;
  booked_ltl_quote_id?: Maybe<IdInput>;
  booked_at?: Maybe<GraphbackDateTimeInput>;
  is_plain_text?: Maybe<BooleanInput>;
  booked_by_id?: Maybe<IdInput>;
  quote_request_template_id?: Maybe<IdInput>;
  closed_at?: Maybe<GraphbackDateTimeInput>;
  closed_by_id?: Maybe<IdInput>;
  closed_reason?: Maybe<StringInput>;
  requested_from_web_app?: Maybe<BooleanInput>;
  outlook_mode_when_requested?: Maybe<StringInput>;
  load_id?: Maybe<IdInput>;
  goods_value?: Maybe<FloatInput>;
  carrier_routing_pref_notes?: Maybe<StringInput>;
  canceled_at?: Maybe<GraphbackDateTimeInput>;
  canceled_by_id?: Maybe<IdInput>;
  canceled_reason?: Maybe<StringInput>;
  original_qr_id?: Maybe<IntInput>;
  and?: Maybe<Array<QuoteRequestFilter>>;
  or?: Maybe<Array<QuoteRequestFilter>>;
  not?: Maybe<QuoteRequestFilter>;
};

export type QuoteRequestResultList = {
  __typename?: 'QuoteRequestResultList';
  items: Array<Maybe<QuoteRequest>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
};

export enum QuoteRequestState {
  /** @summary The QuoteRequest has been created, but has not been inserted (or sent) */
  Draft = 'DRAFT',
  /** @summary The QuoteRequest has been inserted into the email in the Outlook Add In (but we do not know if the email was sent) */
  Requested = 'REQUESTED',
  /** @summary The "newQuoteRequest" txn email has been sent via the platform */
  Sent = 'SENT',
  /** @summary quotes/fcl_quotes/air_quotes (at least 1) have come back for the QuoteRequest */
  Returned = 'RETURNED',
  /** @summary The Shipper booked a quote/fcl_quote/air_quote (booked_quote/booked_fcl_quote/booked_air_quote) for the QuoteRequest */
  Booked = 'BOOKED',
  /**
   * @summary The Shipper has clicked "Close Quote" in the web app.
   * @description This is a terminal state for a Quote Request that the Shipper wants to close (e.g. it has been booked off platform).
   */
  Closed = 'CLOSED',
  /**
   * @summary The Shipper has clicked "Cancel Quote" on a "Booked" QuoteRequest or quote in the web app.
   * @description This is a terminal state for a Quote Request that the Shipper wants to cancel. The expected transition for canceling a QuoteRequest is from 'BOOKED' --> 'CANCELED'
   */
  Canceled = 'CANCELED'
}

/**
 * @db(name: 'quote_request_templates')
 * @model(create: false, find: true, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type QuoteRequestTemplate = Node & {
  __typename?: 'QuoteRequestTemplate';
  id: Scalars['ID'];
  /** @db(type: 'text') */
  mode?: Maybe<Mode>;
  /**
   * @manyToOne(field: 'quote_request_templates', key: 'shipper_id')
   * @index
   */
  shipper: Shipper;
  /**
   * @manyToOne(field: 'quote_request_templates', key: 'user_id')
   * @index
   */
  user: User;
  /** @oneToMany(field: 'quote_request_template', key: 'quote_request_template_id') */
  quote_requests: Array<QuoteRequest>;
  /** @oneToMany(field: 'quote_request_template', key: 'quote_request_template_id') */
  stop_templates: Array<StopTemplate>;
  /** @oneToMany(field: 'quote_request_template', key: 'quote_request_template_id') */
  drayage_load_spec_templates: Array<DrayageLoadSpecTemplate>;
  /** @oneToMany(field: 'quote_request_template', key: 'quote_request_template_id') */
  ftl_load_spec_templates: Array<FtlLoadSpecTemplate>;
  /** @oneToOne(field: 'ltl_load_spec_template', key: 'ltl_load_spec_template_id') */
  ltl_load_spec_template?: Maybe<LtlLoadSpecTemplate>;
  /** @oneToOne(field: 'fcl_load_spec_template', key: 'fcl_load_spec_template_id') */
  fcl_load_spec_template?: Maybe<FclLoadSpecTemplate>;
  /** @oneToOne(field: 'air_load_spec_template', key: 'air_load_spec_template_id') */
  air_load_spec_template?: Maybe<AirLoadSpecTemplate>;
  /** @db(type: 'text') */
  note?: Maybe<Scalars['String']>;
  /** @db(type: 'text') */
  unloading_details?: Maybe<UnloadingDetails>;
  is_hazardous?: Maybe<Scalars['Boolean']>;
  /** @db(type: 'text') */
  hazardous_goods_details?: Maybe<Scalars['String']>;
  /** @default(value: false) */
  insurance_required?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
  name?: Maybe<Scalars['String']>;
  goods_value?: Maybe<Scalars['Float']>;
};


/**
 * @db(name: 'quote_request_templates')
 * @model(create: false, find: true, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type QuoteRequestTemplateQuote_RequestsArgs = {
  filter?: Maybe<QuoteRequestFilter>;
};


/**
 * @db(name: 'quote_request_templates')
 * @model(create: false, find: true, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type QuoteRequestTemplateStop_TemplatesArgs = {
  filter?: Maybe<StopTemplateFilter>;
};


/**
 * @db(name: 'quote_request_templates')
 * @model(create: false, find: true, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type QuoteRequestTemplateDrayage_Load_Spec_TemplatesArgs = {
  filter?: Maybe<DrayageLoadSpecTemplateFilter>;
};


/**
 * @db(name: 'quote_request_templates')
 * @model(create: false, find: true, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type QuoteRequestTemplateFtl_Load_Spec_TemplatesArgs = {
  filter?: Maybe<FtlLoadSpecTemplateFilter>;
};

export type QuoteRequestTemplateFilter = {
  id?: Maybe<IdInput>;
  mode?: Maybe<StringInput>;
  shipper_id?: Maybe<IdInput>;
  user_id?: Maybe<IdInput>;
  ltl_load_spec_template_id?: Maybe<IdInput>;
  fcl_load_spec_template_id?: Maybe<IdInput>;
  air_load_spec_template_id?: Maybe<IdInput>;
  note?: Maybe<StringInput>;
  unloading_details?: Maybe<StringInput>;
  is_hazardous?: Maybe<BooleanInput>;
  hazardous_goods_details?: Maybe<StringInput>;
  insurance_required?: Maybe<BooleanInput>;
  created_at?: Maybe<GraphbackDateTimeInput>;
  updated_at?: Maybe<GraphbackDateTimeInput>;
  name?: Maybe<StringInput>;
  goods_value?: Maybe<FloatInput>;
  and?: Maybe<Array<QuoteRequestTemplateFilter>>;
  or?: Maybe<Array<QuoteRequestTemplateFilter>>;
  not?: Maybe<QuoteRequestTemplateFilter>;
};

export type QuoteRequestTemplateResultList = {
  __typename?: 'QuoteRequestTemplateResultList';
  items: Array<Maybe<QuoteRequestTemplate>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
};

export enum QuoteStatus {
  /** 'DRAFT' quote status is currently unimplemented */
  Draft = 'DRAFT',
  /**
   * @summary A 'booked' quote status is related to the 'BOOKED' state of a QuoteRequest.
   * @description The expected transition for a QuoteStatus is 'SUBMITTED' --> 'BOOKED'. There should only ever be one booked quote/fcl_quote/air_quote per QuoteRequest.
   */
  Booked = 'BOOKED',
  /**
   * @summary A quote is considered inactive if a submitter inactivates their quote (e.g. re-submitting an FTL quote from the same submitter email)
   * @description The expected transition for a QuoteStatus is 'SUBMITTED' --> 'INACTIVE'
   */
  Inactive = 'INACTIVE',
  /** @summary A 'submitted' quote is considered an active quote */
  Submitted = 'SUBMITTED',
  /**
   * @summary This quote status is related to a booked quote request being canceled.
   * @description The expected transition for a QuoteStatus is 'BOOKED' --> 'CANCELED'. There should only ever be one canceled quote/fcl_quote/air_quote per QuoteRequest.
   */
  Canceled = 'CANCELED'
}

export enum QuoteType {
  Cheapest = 'CHEAPEST',
  Fastest = 'FASTEST',
  Both = 'BOTH',
  ForwarderPreference = 'FORWARDER_PREFERENCE',
  Additional = 'ADDITIONAL'
}

export type QuoteTypes = AirQuote | FclQuote | LtlQuote | Quote;

export type ReplaceAccessorialsInput = {
  ltlLoadSpecId: Scalars['ID'];
  accessorialIds: Array<Scalars['ID']>;
};

export enum RequestType {
  Quote = 'QUOTE',
  Pickup = 'PICKUP'
}

export type ResultList = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
};

export enum RoutingType {
  P2P = 'P2P',
  P2D = 'P2D',
  D2P = 'D2P',
  D2D = 'D2D'
}

export type SendNewQuoteRequestEmailInput = {
  quoteRequestId: Scalars['ID'];
  contactIds?: Maybe<Array<Scalars['ID']>>;
};

export type SendTestEmailInput = {
  email: Scalars['String'];
};

export type SendTransactionalEmailInput = {
  type: Scalars['String'];
  email: Scalars['String'];
  /** You can also provide a portex_id for this value (e.g. PTX-123456) */
  quoteRequestId?: Maybe<Scalars['ID']>;
  cc?: Maybe<Array<Scalars['String']>>;
  bcc?: Maybe<Array<Scalars['String']>>;
  subject?: Maybe<Scalars['String']>;
  typeSuffix?: Maybe<Scalars['String']>;
  quotesIDs?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type SendTransactionalEmailResult = {
  __typename?: 'SendTransactionalEmailResult';
  success: Scalars['Boolean'];
  html: Scalars['String'];
  subject: Scalars['String'];
  from: Scalars['String'];
  to: Array<Scalars['String']>;
  cc: Array<Scalars['String']>;
  bcc: Array<Scalars['String']>;
  replyTo: Scalars['String'];
  domain: Scalars['String'];
};

export enum ServiceLevel {
  Standard = 'STANDARD',
  Express = 'EXPRESS',
  Deferred = 'DEFERRED',
  ForwarderPreference = 'FORWARDER_PREFERENCE',
  Additional = 'ADDITIONAL',
  NonStop = 'NON_STOP'
}

/**
 * @db(name: 'shippers')
 * @model(create: false, find: false, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type Shipper = Company & Node & {
  __typename?: 'Shipper';
  id: Scalars['ID'];
  name: Scalars['String'];
  phone_number?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  /** @oneToMany(field: 'shipper', key: 'shipper_id') */
  addresses: Array<Address>;
  /** @oneToMany(field: 'shipper', key: 'shipper_id') */
  stops: Array<Stop>;
  /** @oneToMany(field: 'shipper', key: 'shipper_id') */
  stop_templates: Array<StopTemplate>;
  /** @oneToMany(field: 'shipper', key: 'shipper_id') */
  employees: Array<User>;
  /** @oneToMany(field: 'shipper', key: 'shipper_id') */
  contacts: Array<Contact>;
  /** @oneToMany(field: 'shipper', key: 'shipper_id') */
  address_contacts: Array<AddressContact>;
  /** @oneToMany(field: 'shipper', key: 'shipper_id') */
  ftl_load_specs: Array<FtlLoadSpec>;
  /** @oneToMany(field: 'shipper', key: 'shipper_id') */
  ftl_load_spec_templates: Array<FtlLoadSpecTemplate>;
  /** @oneToMany(field: 'shipper', key: 'shipper_id') */
  drayage_load_specs: Array<DrayageLoadSpec>;
  /** @oneToMany(field: 'shipper', key: 'shipper_id') */
  drayage_load_spec_templates: Array<DrayageLoadSpecTemplate>;
  /** @oneToMany(field: 'shipper', key: 'shipper_id') */
  ltl_load_specs: Array<LtlLoadSpec>;
  /** @oneToMany(field: 'shipper', key: 'shipper_id') */
  ltl_load_spec_templates: Array<LtlLoadSpecTemplate>;
  /** @oneToMany(field: 'shipper', key: 'shipper_id') */
  fcl_load_specs: Array<FclLoadSpec>;
  /** @oneToMany(field: 'shipper', key: 'shipper_id') */
  fcl_load_spec_templates: Array<FclLoadSpecTemplate>;
  /** @oneToMany(field: 'shipper', key: 'shipper_id') */
  air_load_specs: Array<AirLoadSpec>;
  /** @oneToMany(field: 'shipper', key: 'shipper_id') */
  air_load_spec_templates: Array<AirLoadSpecTemplate>;
  /** @oneToMany(field: 'shipper', key: 'shipper_id') */
  package_groups: Array<PackageGroup>;
  /** @oneToMany(field: 'shipper', key: 'shipper_id') */
  package_group_templates: Array<PackageGroupTemplate>;
  /** @oneToMany(field: 'shipper', key: 'shipper_id') */
  air_package_groups: Array<AirPackageGroup>;
  /** @oneToMany(field: 'shipper', key: 'shipper_id') */
  air_package_group_templates: Array<AirPackageGroupTemplate>;
  /** @oneToMany(field: 'shipper', key: 'shipper_id') */
  containers: Array<Container>;
  /** @oneToMany(field: 'shipper', key: 'shipper_id') */
  container_templates: Array<ContainerTemplate>;
  /** @oneToMany(field: 'shipper', key: 'shipper_id') */
  quote_requests: Array<QuoteRequest>;
  /** @oneToMany(field: 'shipper', key: 'shipper_id') */
  quote_request_templates: Array<QuoteRequestTemplate>;
  /** @oneToMany(field: 'shipper', key: 'shipper_id') */
  quotes: Array<Quote>;
  /** @oneToMany(field: 'shipper', key: 'shipper_id') */
  fcl_quotes: Array<FclQuote>;
  /** @oneToMany(field: 'shipper', key: 'shipper_id') */
  air_quotes: Array<AirQuote>;
  /** @oneToMany(field: 'shipper', key: 'shipper_id') */
  ltl_quotes: Array<LtlQuote>;
  /** @oneToMany(field: 'shipper', key: 'shipper_id') */
  fcl_quote_charges: Array<FclQuoteCharge>;
  /** @oneToMany(field: 'shipper', key: 'shipper_id') */
  air_quote_charges: Array<AirQuoteCharge>;
  /** @oneToMany(field: 'shipper', key: 'shipper_id') */
  contacts_quote_requests: Array<ContactsQuoteRequests>;
  /** @oneToMany(field: 'shipper', key: 'shipper_id') */
  accessorials_ltl_load_specs: Array<AccessorialsLtlLoadSpecs>;
  /** @oneToMany(field: 'shipper', key: 'shipper_id') */
  accessorials_ltl_load_spec_templates: Array<Maybe<AccessorialsLtlLoadSpecTemplates>>;
  /** @oneToMany(field: 'shipper', key: 'shipper_id') */
  trucks: Array<Maybe<Truck>>;
  /** @oneToMany(field: 'shipper', key: 'shipper_id') */
  truck_template: Array<Maybe<TruckTemplate>>;
  /** @default(value: false) */
  is_internal?: Maybe<Scalars['Boolean']>;
  /** @default(value: true) */
  is_active?: Maybe<Scalars['Boolean']>;
  /** @transient */
  has_made_quote_request?: Maybe<Scalars['Boolean']>;
  /** @oneToMany(field: 'shipper', key: 'shipper_id') */
  loads: Array<Maybe<Load>>;
  /** @oneToMany(field: 'shipper', key: 'shipper_id') */
  files_quotes: Array<FilesQuotes>;
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
};


/**
 * @db(name: 'shippers')
 * @model(create: false, find: false, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type ShipperAddressesArgs = {
  filter?: Maybe<AddressFilter>;
};


/**
 * @db(name: 'shippers')
 * @model(create: false, find: false, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type ShipperStopsArgs = {
  filter?: Maybe<StopFilter>;
};


/**
 * @db(name: 'shippers')
 * @model(create: false, find: false, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type ShipperStop_TemplatesArgs = {
  filter?: Maybe<StopTemplateFilter>;
};


/**
 * @db(name: 'shippers')
 * @model(create: false, find: false, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type ShipperEmployeesArgs = {
  filter?: Maybe<UserFilter>;
};


/**
 * @db(name: 'shippers')
 * @model(create: false, find: false, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type ShipperContactsArgs = {
  filter?: Maybe<ContactFilter>;
};


/**
 * @db(name: 'shippers')
 * @model(create: false, find: false, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type ShipperAddress_ContactsArgs = {
  filter?: Maybe<AddressContactFilter>;
};


/**
 * @db(name: 'shippers')
 * @model(create: false, find: false, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type ShipperFtl_Load_SpecsArgs = {
  filter?: Maybe<FtlLoadSpecFilter>;
};


/**
 * @db(name: 'shippers')
 * @model(create: false, find: false, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type ShipperFtl_Load_Spec_TemplatesArgs = {
  filter?: Maybe<FtlLoadSpecTemplateFilter>;
};


/**
 * @db(name: 'shippers')
 * @model(create: false, find: false, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type ShipperDrayage_Load_SpecsArgs = {
  filter?: Maybe<DrayageLoadSpecFilter>;
};


/**
 * @db(name: 'shippers')
 * @model(create: false, find: false, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type ShipperDrayage_Load_Spec_TemplatesArgs = {
  filter?: Maybe<DrayageLoadSpecTemplateFilter>;
};


/**
 * @db(name: 'shippers')
 * @model(create: false, find: false, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type ShipperLtl_Load_SpecsArgs = {
  filter?: Maybe<LtlLoadSpecFilter>;
};


/**
 * @db(name: 'shippers')
 * @model(create: false, find: false, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type ShipperLtl_Load_Spec_TemplatesArgs = {
  filter?: Maybe<LtlLoadSpecTemplateFilter>;
};


/**
 * @db(name: 'shippers')
 * @model(create: false, find: false, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type ShipperFcl_Load_SpecsArgs = {
  filter?: Maybe<FclLoadSpecFilter>;
};


/**
 * @db(name: 'shippers')
 * @model(create: false, find: false, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type ShipperFcl_Load_Spec_TemplatesArgs = {
  filter?: Maybe<FclLoadSpecTemplateFilter>;
};


/**
 * @db(name: 'shippers')
 * @model(create: false, find: false, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type ShipperAir_Load_SpecsArgs = {
  filter?: Maybe<AirLoadSpecFilter>;
};


/**
 * @db(name: 'shippers')
 * @model(create: false, find: false, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type ShipperAir_Load_Spec_TemplatesArgs = {
  filter?: Maybe<AirLoadSpecTemplateFilter>;
};


/**
 * @db(name: 'shippers')
 * @model(create: false, find: false, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type ShipperPackage_GroupsArgs = {
  filter?: Maybe<PackageGroupFilter>;
};


/**
 * @db(name: 'shippers')
 * @model(create: false, find: false, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type ShipperPackage_Group_TemplatesArgs = {
  filter?: Maybe<PackageGroupTemplateFilter>;
};


/**
 * @db(name: 'shippers')
 * @model(create: false, find: false, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type ShipperAir_Package_GroupsArgs = {
  filter?: Maybe<AirPackageGroupFilter>;
};


/**
 * @db(name: 'shippers')
 * @model(create: false, find: false, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type ShipperAir_Package_Group_TemplatesArgs = {
  filter?: Maybe<AirPackageGroupTemplateFilter>;
};


/**
 * @db(name: 'shippers')
 * @model(create: false, find: false, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type ShipperContainersArgs = {
  filter?: Maybe<ContainerFilter>;
};


/**
 * @db(name: 'shippers')
 * @model(create: false, find: false, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type ShipperContainer_TemplatesArgs = {
  filter?: Maybe<ContainerTemplateFilter>;
};


/**
 * @db(name: 'shippers')
 * @model(create: false, find: false, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type ShipperQuote_RequestsArgs = {
  filter?: Maybe<QuoteRequestFilter>;
};


/**
 * @db(name: 'shippers')
 * @model(create: false, find: false, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type ShipperQuote_Request_TemplatesArgs = {
  filter?: Maybe<QuoteRequestTemplateFilter>;
};


/**
 * @db(name: 'shippers')
 * @model(create: false, find: false, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type ShipperQuotesArgs = {
  filter?: Maybe<QuoteFilter>;
};


/**
 * @db(name: 'shippers')
 * @model(create: false, find: false, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type ShipperFcl_QuotesArgs = {
  filter?: Maybe<FclQuoteFilter>;
};


/**
 * @db(name: 'shippers')
 * @model(create: false, find: false, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type ShipperAir_QuotesArgs = {
  filter?: Maybe<AirQuoteFilter>;
};


/**
 * @db(name: 'shippers')
 * @model(create: false, find: false, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type ShipperLtl_QuotesArgs = {
  filter?: Maybe<LtlQuoteFilter>;
};


/**
 * @db(name: 'shippers')
 * @model(create: false, find: false, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type ShipperFcl_Quote_ChargesArgs = {
  filter?: Maybe<FclQuoteChargeFilter>;
};


/**
 * @db(name: 'shippers')
 * @model(create: false, find: false, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type ShipperAir_Quote_ChargesArgs = {
  filter?: Maybe<AirQuoteChargeFilter>;
};


/**
 * @db(name: 'shippers')
 * @model(create: false, find: false, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type ShipperContacts_Quote_RequestsArgs = {
  filter?: Maybe<ContactsQuoteRequestsFilter>;
};


/**
 * @db(name: 'shippers')
 * @model(create: false, find: false, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type ShipperAccessorials_Ltl_Load_SpecsArgs = {
  filter?: Maybe<AccessorialsLtlLoadSpecsFilter>;
};


/**
 * @db(name: 'shippers')
 * @model(create: false, find: false, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type ShipperAccessorials_Ltl_Load_Spec_TemplatesArgs = {
  filter?: Maybe<AccessorialsLtlLoadSpecTemplatesFilter>;
};


/**
 * @db(name: 'shippers')
 * @model(create: false, find: false, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type ShipperTrucksArgs = {
  filter?: Maybe<TruckFilter>;
};


/**
 * @db(name: 'shippers')
 * @model(create: false, find: false, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type ShipperTruck_TemplateArgs = {
  filter?: Maybe<TruckTemplateFilter>;
};


/**
 * @db(name: 'shippers')
 * @model(create: false, find: false, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type ShipperLoadsArgs = {
  filter?: Maybe<LoadFilter>;
};


/**
 * @db(name: 'shippers')
 * @model(create: false, find: false, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type ShipperFiles_QuotesArgs = {
  filter?: Maybe<FilesQuotesFilter>;
};

/**
 * @db(name: 'signup_survey_shippers')
 * @model(create: false, find: false, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type SignupSurveyShipper = Node & {
  __typename?: 'SignupSurveyShipper';
  id: Scalars['ID'];
  /**
   * @oneToOne(field: 'user', key: 'user_id')
   * @unique
   */
  user: User;
  /** @default(value: false) */
  mode_ftl: Scalars['Boolean'];
  /** @default(value: false) */
  mode_fcl: Scalars['Boolean'];
  /** @default(value: false) */
  mode_air: Scalars['Boolean'];
  /** @default(value: 'MODE_VOLUME_0') */
  volume_ftl: ModeVolume;
  /** @default(value: 'MODE_VOLUME_0') */
  volume_fcl: ModeVolume;
  /** @default(value: 'MODE_VOLUME_0') */
  volume_air: ModeVolume;
  partner_count: PartnerCount;
  created_at: Scalars['GraphbackDateTime'];
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
};

export type SoftDeletable = {
  deleted_by?: Maybe<User>;
  deleted_at?: Maybe<Scalars['GraphbackDateTime']>;
};

export enum SortDirectionEnum {
  Desc = 'DESC',
  Asc = 'ASC'
}

export enum SortDirectionFilterEnum {
  Desc = 'DESC',
  Asc = 'ASC'
}

/**
 * @db(name: 'stops')
 * @model(create: true, find: false, findOne: false, update: true, delete: true, subCreate: false, subUpdate: false, subDelete: false)
 */
export type Stop = Node & {
  __typename?: 'Stop';
  id: Scalars['ID'];
  /**
   * @manyToOne(field: 'stops', key: 'shipper_id')
   * @index
   */
  shipper: Shipper;
  /**
   * @manyToOne(field: 'stops', key: 'address_id')
   * @index
   */
  address?: Maybe<Address>;
  /**
   * @manyToOne(field: 'stops', key: 'quote_request_id')
   * @unique(name: 'quote_request_position_unique')
   */
  quote_request: QuoteRequest;
  /** @unique(name: 'quote_request_position_unique') */
  position: Scalars['Int'];
  /** @db(type: 'text') */
  note?: Maybe<Scalars['String']>;
  booking_notes: Scalars['String'];
  /** @default(value: false) */
  is_time_tbd?: Maybe<Scalars['Boolean']>;
  /** @default(value: false) */
  is_na?: Maybe<Scalars['Boolean']>;
  start?: Maybe<Scalars['GraphbackDateTime']>;
  end?: Maybe<Scalars['GraphbackDateTime']>;
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
  reference_number?: Maybe<Scalars['String']>;
  distance_from_prior_stop?: Maybe<Scalars['Int']>;
};

export type StopAddressPayload = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  address_1?: Maybe<Scalars['String']>;
  address_2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  province_code?: Maybe<Scalars['String']>;
  province_name?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  country_name?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['String']>;
  lon?: Maybe<Scalars['String']>;
  google_place_id?: Maybe<Scalars['String']>;
  google_place_description?: Maybe<Scalars['String']>;
  port_id?: Maybe<Scalars['String']>;
  port_name?: Maybe<Scalars['String']>;
  airport_id?: Maybe<Scalars['String']>;
  airport_name?: Maybe<Scalars['String']>;
  airport_iata_code?: Maybe<Scalars['String']>;
  hours_start?: Maybe<Scalars['GraphbackDateTime']>;
  hours_end?: Maybe<Scalars['GraphbackDateTime']>;
  address_contacts?: Maybe<Array<AddressContactPayload>>;
  type?: Maybe<AddressType>;
};

export type StopFilter = {
  id?: Maybe<IdInput>;
  shipper_id?: Maybe<IdInput>;
  address_id?: Maybe<IdInput>;
  quote_request_id?: Maybe<IdInput>;
  position?: Maybe<IntInput>;
  note?: Maybe<StringInput>;
  booking_notes?: Maybe<StringInput>;
  is_time_tbd?: Maybe<BooleanInput>;
  is_na?: Maybe<BooleanInput>;
  start?: Maybe<GraphbackDateTimeInput>;
  end?: Maybe<GraphbackDateTimeInput>;
  created_at?: Maybe<GraphbackDateTimeInput>;
  updated_at?: Maybe<GraphbackDateTimeInput>;
  reference_number?: Maybe<StringInput>;
  distance_from_prior_stop?: Maybe<IntInput>;
  and?: Maybe<Array<StopFilter>>;
  or?: Maybe<Array<StopFilter>>;
  not?: Maybe<StopFilter>;
};

export type StopImpersonationInput = {
  switchTo?: Maybe<SwitchShipperEnum>;
};

export type StopPayload = {
  id?: Maybe<Scalars['ID']>;
  address?: Maybe<StopAddressPayload>;
  note?: Maybe<Scalars['String']>;
  is_time_tbd?: Maybe<Scalars['Boolean']>;
  is_na?: Maybe<Scalars['Boolean']>;
  start?: Maybe<Scalars['GraphbackDateTime']>;
  end?: Maybe<Scalars['GraphbackDateTime']>;
  reference_number?: Maybe<Scalars['String']>;
};

export type StopPayloadForRoutingDetails = {
  position: Scalars['Int'];
  address?: Maybe<StopAddressPayload>;
};

/**
 * @db(name: 'stop_templates')
 * @model(create: false, find: false, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type StopTemplate = Node & {
  __typename?: 'StopTemplate';
  id: Scalars['ID'];
  /**
   * @manyToOne(field: 'stop_templates', key: 'shipper_id')
   * @index
   */
  shipper: Shipper;
  /**
   * @manyToOne(field: 'stop_templates', key: 'address_id')
   * @index
   */
  address?: Maybe<Address>;
  /**
   * @manyToOne(field: 'stop_templates', key: 'quote_request_template_id')
   * @unique(name: 'quote_request_template_position_unique')
   */
  quote_request_template: QuoteRequestTemplate;
  /** @unique(name: 'quote_request_template_position_unique') */
  position: Scalars['Int'];
  /** @db(type: 'text') */
  note?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
};

export type StopTemplateFilter = {
  id?: Maybe<IdInput>;
  shipper_id?: Maybe<IdInput>;
  address_id?: Maybe<IdInput>;
  quote_request_template_id?: Maybe<IdInput>;
  position?: Maybe<IntInput>;
  note?: Maybe<StringInput>;
  created_at?: Maybe<GraphbackDateTimeInput>;
  updated_at?: Maybe<GraphbackDateTimeInput>;
  and?: Maybe<Array<StopTemplateFilter>>;
  or?: Maybe<Array<StopTemplateFilter>>;
  not?: Maybe<StopTemplateFilter>;
};

export type StringFilterInput = {
  eq?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  contains?: Maybe<Scalars['String']>;
};

export type StringInput = {
  ne?: Maybe<Scalars['String']>;
  eq?: Maybe<Scalars['String']>;
  le?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  ge?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
};

export type SubmitAirQuoteInput = {
  submitter_email: Scalars['String'];
  submitter_tz?: Maybe<Scalars['String']>;
  valid_until: Scalars['GraphbackDateTime'];
  notes_charges_insurance?: Maybe<Scalars['String']>;
  notes_charges_miscellaneous?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  service_level: ServiceLevel;
  origin_airport: AirQuoteAirportPayload;
  via_airport?: Maybe<AirQuoteAirportPayload>;
  destination_airport: AirQuoteAirportPayload;
  min_transit_time: Scalars['Int'];
  max_transit_time: Scalars['Int'];
  quote_charges: Array<AirQuoteChargePayload>;
  carrier?: Maybe<Scalars['String']>;
  volumetric_weight: Scalars['Float'];
  dim_factor: Scalars['Float'];
};

export type SubmitFclQuoteInput = {
  submitter_email: Scalars['String'];
  submitter_tz?: Maybe<Scalars['String']>;
  valid_until: Scalars['GraphbackDateTime'];
  notes_charges_insurance?: Maybe<Scalars['String']>;
  notes_charges_miscellaneous?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  type: QuoteType;
  origin_port: FclQuotePortPayload;
  via_port?: Maybe<FclQuotePortPayload>;
  destination_port: FclQuotePortPayload;
  min_transit_time: Scalars['Int'];
  max_transit_time: Scalars['Int'];
  quote_charges: Array<FclQuoteChargePayload>;
  carrier?: Maybe<Scalars['String']>;
  capacity_guarantee: Scalars['Boolean'];
  inland_mode_of_transport?: Maybe<InlandModeOfTransportType>;
  destination_rail_ramp?: Maybe<FclQuotePortPayload>;
};

export type SubmitLtlQuoteInput = {
  quote_request_guid: Scalars['String'];
  submitter_email: Scalars['String'];
  submitter_tz?: Maybe<Scalars['String']>;
  valid_until: Scalars['GraphbackDateTime'];
  total_amount: Scalars['Float'];
  company_name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  min_transit_time?: Maybe<Scalars['Int']>;
  max_transit_time?: Maybe<Scalars['Int']>;
  carrier_name: Scalars['String'];
};

export type SubmitQuoteInput = {
  quote_request_guid: Scalars['String'];
  submitter_email: Scalars['String'];
  submitter_tz?: Maybe<Scalars['String']>;
  valid_until: Scalars['GraphbackDateTime'];
  rate_per_load: Scalars['Float'];
  company_name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
};

export type SubmitQuoteRequestInput = {
  id: Scalars['ID'];
  email_html_body?: Maybe<Scalars['String']>;
  outlook_mode_when_requested?: Maybe<OutlookMode>;
};

export enum SwitchShipperEnum {
  Test = 'TEST',
  Demo = 'DEMO',
  Internal = 'INTERNAL'
}

export type SwitchShipperInput = {
  switchTo: SwitchShipperEnum;
};

export type Tag = SoftDeletable & Node & {
  __typename?: 'Tag';
  id: Scalars['ID'];
  tag: Scalars['String'];
  /** config is a computed field, not in the DB */
  config?: Maybe<TagConfig>;
  deleted_at?: Maybe<Scalars['GraphbackDateTime']>;
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
  deleted_by?: Maybe<User>;
};

export type TagConfig = {
  __typename?: 'TagConfig';
  group: Scalars['Int'];
  color: Scalars['String'];
};

/**
 * @db(name: 'temp_tokens')
 * @model(create: false, find: false, findOne: true, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type TempToken = Node & {
  __typename?: 'TempToken';
  id: Scalars['ID'];
  /** @unique */
  guid: Scalars['String'];
  expires_at: Scalars['GraphbackDateTime'];
  /** @default(value: true) */
  is_valid?: Maybe<Scalars['Boolean']>;
  /** @default(value: false) */
  is_single_use?: Maybe<Scalars['Boolean']>;
  type: TempTokenType;
  /**
   * ------
   * ------ begin temp token type specific fields ------
   * ------
   */
  quote_request_guid?: Maybe<Scalars['String']>;
  signup_email?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['ID']>;
  company_type?: Maybe<CompanyType>;
  email_to_verify?: Maybe<Scalars['String']>;
  /**
   * ------
   * ------ end temp token type specific fields ------
   * ------
   */
  last_used_at?: Maybe<Scalars['GraphbackDateTime']>;
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
};

export enum TempTokenType {
  Resource = 'RESOURCE',
  Signup = 'SIGNUP',
  EmailVerification = 'EMAIL_VERIFICATION'
}

export enum TempUnit {
  F = 'F',
  C = 'C'
}

export enum TrailerSize {
  T_26 = 'T_26',
  T_40 = 'T_40',
  T_48 = 'T_48',
  T_53 = 'T_53',
  C_20 = 'C_20',
  C_40 = 'C_40',
  C_20Hc = 'C_20_HC',
  C_40Hc = 'C_40_HC',
  C_45Hc = 'C_45_HC',
  Na = 'NA'
}

/**
 * @db(name: 'transportation_providers')
 * @model(create: false, find: false, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type TransportationProvider = Company & Node & {
  __typename?: 'TransportationProvider';
  id: Scalars['ID'];
  name: Scalars['String'];
  phone_number?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  /** @oneToMany(field: 'transportation_provider', key: 'transportation_provider_id') */
  employees: Array<User>;
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
};


/**
 * @db(name: 'transportation_providers')
 * @model(create: false, find: false, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type TransportationProviderEmployeesArgs = {
  filter?: Maybe<UserFilter>;
};

/**
 * @db(name: 'trucks')
 * @model(create: false, find: false, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type Truck = Node & {
  __typename?: 'Truck';
  id: Scalars['ID'];
  /**
   * @manyToOne(field: 'trucks', key: 'shipper_id')
   * @index
   */
  shipper: Shipper;
  /** @db(type: 'text') */
  trailer_size?: Maybe<TrailerSize>;
  /** @db(type: 'text') */
  trailer_type?: Maybe<FtlTrailerType>;
  /** @db(type: 'text') */
  reference_number?: Maybe<Scalars['String']>;
  /** @manyToOne(field: 'trucks', key: 'ftl_load_spec_id') */
  ftl_load_spec?: Maybe<FtlLoadSpec>;
  /** @oneToOne(field: 'ltl_load_spec', key: 'ltl_load_spec_id') */
  ltl_load_spec?: Maybe<LtlLoadSpec>;
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
};

export type TruckFilter = {
  id?: Maybe<IdInput>;
  shipper_id?: Maybe<IdInput>;
  trailer_size?: Maybe<StringInput>;
  trailer_type?: Maybe<StringInput>;
  reference_number?: Maybe<StringInput>;
  ftl_load_spec_id?: Maybe<IdInput>;
  ltl_load_spec_id?: Maybe<IdInput>;
  created_at?: Maybe<GraphbackDateTimeInput>;
  updated_at?: Maybe<GraphbackDateTimeInput>;
  and?: Maybe<Array<TruckFilter>>;
  or?: Maybe<Array<TruckFilter>>;
  not?: Maybe<TruckFilter>;
};

export type TruckPayload = {
  reference_number: Scalars['String'];
};

/**
 * @db(name: 'truck_templates')
 * @model(create: false, find: false, findOne: false, update: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type TruckTemplate = Node & {
  __typename?: 'TruckTemplate';
  id: Scalars['ID'];
  /**
   * @manyToOne(field: 'truck_template', key: 'shipper_id')
   * @index
   */
  shipper: Shipper;
  /** @db(type: 'text') */
  trailer_size?: Maybe<TrailerSize>;
  /** @db(type: 'text') */
  trailer_type?: Maybe<FtlTrailerType>;
  /** @oneToOne(field: 'ftl_load_spec_template_id', key: 'ftl_load_spec_template_id') */
  ftl_load_spec_template?: Maybe<FtlLoadSpecTemplate>;
  /** @oneToOne(field: 'ltl_load_spec_template_id', key: 'ltl_load_spec_template_id') */
  ltl_load_spec_template?: Maybe<LtlLoadSpecTemplate>;
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
};

export type TruckTemplateFilter = {
  id?: Maybe<IdInput>;
  shipper_id?: Maybe<IdInput>;
  trailer_size?: Maybe<StringInput>;
  trailer_type?: Maybe<StringInput>;
  ftl_load_spec_template_id?: Maybe<IdInput>;
  ltl_load_spec_template_id?: Maybe<IdInput>;
  created_at?: Maybe<GraphbackDateTimeInput>;
  updated_at?: Maybe<GraphbackDateTimeInput>;
  and?: Maybe<Array<TruckTemplateFilter>>;
  or?: Maybe<Array<TruckTemplateFilter>>;
  not?: Maybe<TruckTemplateFilter>;
};

/**
 * @description Containers require Unloading Details to help the Freight Forwarder understand wether or not the container needs to be unloaded by hand (Live Unload) or just dropped off at the destination - where the trucker may pick up a different empty container to bring back to the port (Drop & Pick) or they may come back later to pick up the container after it has been unloaded (potentially a few days later - this is called a Drop).
 * @see https://portexpro.atlassian.net/browse/POR-2327
 */
export enum UnloadingDetails {
  /**
   * @summary This is actually "Drop and Pick" not just "Drop"
   * @example "Drop and Pick"
   * @description This means that the FF needs to tell the trucker to drop off the container at the destination and pick up another container to bring back to the port.
   * @see https://www.flexport.com/glossary/drop-and-pick/#:~:text=A%20drop%20and%20pick%20is%20when%20the%20truck%20driver%20drops,containers%20arriving%20every%20few%20days.
   */
  Drop = 'DROP',
  /**
   * @description This means that the FF needs to pay the truck driver an hourly fee to wait for the container to be unloaded. The truck driver is not unloading the container, but they must wait at the destination until the container is unloaded so that they can bring the container back to the port. This is important because the Port will charge the truck driver, who will then charge the FF, who will then charge the shipper a daily fee that the container is not at the port (called a detention fee).
   * @example "Live Unload"
   * @see https://www.container-xchange.com/blog/chassis-charges-and-soc/#:~:text=Chassis%20Surcharge%20Explained,-Chassis%20is%20used&text=It%20can%20typically%20range%20from,is%20demurrage%20and%20detention%20fees.
   * @see https://www.flexport.com/help/34-trucking-fees-fcl/
   */
  Live = 'LIVE'
}

export type UpdateAddressLabelInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  booking_notes?: Maybe<Scalars['String']>;
};

export type UpdateCargoDetailsInput = {
  quoteRequestId: Scalars['ID'];
  /** @deprecated(reason: "Old feature -- must always be true") */
  useAirPackageGroups: Scalars['Boolean'];
  /** Required -- must be included */
  airPackageGroups?: Maybe<Array<AirPackageGroupPayload>>;
  /** @deprecated(reason: "Old feature that is no longer supported") */
  cargoDetails?: Maybe<CargoDetailsAir>;
};

export type UpdateContainersForQuoteRequestInput = {
  quote_request_id: Scalars['ID'];
  containers: Array<ContainerPayload>;
};

export type UpdatePartnerInput = {
  id: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  company_name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  tag_ids?: Maybe<Array<Scalars['ID']>>;
};

export type UpdateQuoteRequestInput = {
  id: Scalars['ID'];
  type?: Maybe<RequestType>;
  note?: Maybe<Scalars['String']>;
  unloading_details?: Maybe<UnloadingDetails>;
  is_hazardous?: Maybe<Scalars['Boolean']>;
  hazardous_goods_details?: Maybe<Scalars['String']>;
  deadline_respond_at?: Maybe<Scalars['GraphbackDateTime']>;
  reference_number?: Maybe<Scalars['String']>;
  email_html_body?: Maybe<Scalars['String']>;
  is_plain_text?: Maybe<Scalars['Boolean']>;
  insurance_required?: Maybe<Scalars['Boolean']>;
  goods_value?: Maybe<Scalars['Float']>;
  carrier_routing_pref_notes?: Maybe<Scalars['String']>;
};

export type UpdateQuoteRequestQuoteTypesInput = {
  id: Scalars['ID'];
  carrierRoutingPrefNotes?: Maybe<Scalars['String']>;
  fclQuoteTypes?: Maybe<Array<QuoteType>>;
  fclCapacityGuarenteeRequired?: Maybe<Scalars['Boolean']>;
  fclDirectSailingRequired?: Maybe<Scalars['Boolean']>;
  airServiceLevel?: Maybe<Array<ServiceLevel>>;
};

export type UpdateQuoteRequestRecipientsInput = {
  quoteRequestId: Scalars['ID'];
  recipientIds: Array<Scalars['ID']>;
};

export type UpdateQuoteRequestRoutingDetailsInput = {
  id: Scalars['ID'];
  mode: Mode;
  oceanIncoterms?: Maybe<OceanIncoterms>;
  airIncoterms?: Maybe<AirIncoterms>;
  routing_type: RoutingType;
  stops: Array<StopPayloadForRoutingDetails>;
  cargo_ready_date: Scalars['GraphbackDateTime'];
  target_delivery_date?: Maybe<Scalars['GraphbackDateTime']>;
  carrierRoutingPrefNotes?: Maybe<Scalars['String']>;
};

export type UpdateQuoteRequestRoutingInput = {
  quoteRequestId: Scalars['ID'];
  routingType: RoutingType;
};

export type UpdateQuoteRequestWeightUnitInput = {
  quoteRequestId: Scalars['ID'];
  weightUnit: WeightUnit;
};

export type UpdateSelfInput = {
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
};

export type UpdateStopDetailsInput = {
  stop: UpdateStopDetailsPayload;
};

export type UpdateStopDetailsPayload = {
  id: Scalars['ID'];
  address?: Maybe<StopAddressPayload>;
  note?: Maybe<Scalars['String']>;
  is_time_tbd?: Maybe<Scalars['Boolean']>;
  is_na?: Maybe<Scalars['Boolean']>;
  start?: Maybe<Scalars['GraphbackDateTime']>;
  end?: Maybe<Scalars['GraphbackDateTime']>;
  reference_number?: Maybe<Scalars['String']>;
  booking_notes?: Maybe<Scalars['String']>;
};

export type UpdateStopsForQuoteRequestInput = {
  quoteRequestId: Scalars['ID'];
  stops: Array<StopPayload>;
};

export type UpdateTeamMemberInput = {
  id: Scalars['ID'];
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type UpdateTruckInput = {
  id: Scalars['ID'];
  reference_number?: Maybe<Scalars['String']>;
};

export type UpdateTrucksForQuoteRequestInput = {
  quoteRequestId: Scalars['ID'];
  trucks: Array<TruckPayload>;
};

/**
 * @db(name: 'users')
 * @model(create: false, update: false, find: false, findOne: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type User = Node & {
  __typename?: 'User';
  id: Scalars['ID'];
  /** @default(value: false) */
  portex_admin?: Maybe<Scalars['Boolean']>;
  /** @default(value: false) */
  portex_super_admin?: Maybe<Scalars['Boolean']>;
  /** @default(value: false) */
  impersonating?: Maybe<Scalars['Boolean']>;
  /** @unique */
  auth_0_user_id?: Maybe<Scalars['String']>;
  /** @oneToOne(field: 'user_default', key: 'user_default_id') */
  user_default?: Maybe<UserDefault>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  /** @unique */
  email: Scalars['String'];
  /** @default(value: true) */
  is_active?: Maybe<Scalars['Boolean']>;
  /** @default(value: false) */
  is_claimed?: Maybe<Scalars['Boolean']>;
  /** @manyToOne(field: 'employees', key: 'shipper_id') */
  shipper?: Maybe<Shipper>;
  /** @manyToOne(field: 'employees', key: 'transportation_provider_id') */
  transportation_provider?: Maybe<TransportationProvider>;
  /** @oneToMany(field: 'user', key: 'user_id') */
  quote_requests: Array<QuoteRequest>;
  /** @oneToMany(field: 'user', key: 'user_id') */
  quote_request_templates: Array<QuoteRequestTemplate>;
  /** @oneToMany(field: 'user', key: 'user_id') */
  contacts: Array<Contact>;
  /** @oneToMany(field: 'booked_by', key: 'booked_by_id') */
  quote_requests_booked: Array<QuoteRequest>;
  /** @oneToMany(field: 'closed_by', key: 'closed_by_id') */
  quote_requests_closed: Array<QuoteRequest>;
  /** @oneToMany(field: 'canceled_by', key: 'canceled_by_id') */
  quote_requests_canceled: Array<QuoteRequest>;
  /** @oneToMany(field: 'deleted_by', key: 'deleted_by_id') */
  deleted_contacts: Array<Contact>;
  /** @oneToMany(field: 'deleted_by', key: 'deleted_by_id') */
  deleted_addresses: Array<Address>;
  /** @oneToMany(field: 'deleted_by', key: 'deleted_by_id') */
  deleted_loads: Array<Load>;
  /** @oneToMany(field: 'deleted_by', key: 'deleted_by_id') */
  deleted_files: Array<File>;
  /** @oneToMany(field: 'created_by', key: 'created_by_id') */
  files: Array<File>;
  /** @transient */
  feature_flag_demo: Scalars['Boolean'];
  /** @transient */
  feature_flag_internal: Scalars['Boolean'];
  last_action_at?: Maybe<Scalars['GraphbackDateTime']>;
  job_title?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
};


/**
 * @db(name: 'users')
 * @model(create: false, update: false, find: false, findOne: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type UserQuote_RequestsArgs = {
  filter?: Maybe<QuoteRequestFilter>;
};


/**
 * @db(name: 'users')
 * @model(create: false, update: false, find: false, findOne: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type UserQuote_Request_TemplatesArgs = {
  filter?: Maybe<QuoteRequestTemplateFilter>;
};


/**
 * @db(name: 'users')
 * @model(create: false, update: false, find: false, findOne: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type UserContactsArgs = {
  filter?: Maybe<ContactFilter>;
};


/**
 * @db(name: 'users')
 * @model(create: false, update: false, find: false, findOne: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type UserQuote_Requests_BookedArgs = {
  filter?: Maybe<QuoteRequestFilter>;
};


/**
 * @db(name: 'users')
 * @model(create: false, update: false, find: false, findOne: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type UserQuote_Requests_ClosedArgs = {
  filter?: Maybe<QuoteRequestFilter>;
};


/**
 * @db(name: 'users')
 * @model(create: false, update: false, find: false, findOne: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type UserQuote_Requests_CanceledArgs = {
  filter?: Maybe<QuoteRequestFilter>;
};


/**
 * @db(name: 'users')
 * @model(create: false, update: false, find: false, findOne: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type UserDeleted_ContactsArgs = {
  filter?: Maybe<ContactFilter>;
};


/**
 * @db(name: 'users')
 * @model(create: false, update: false, find: false, findOne: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type UserDeleted_AddressesArgs = {
  filter?: Maybe<AddressFilter>;
};


/**
 * @db(name: 'users')
 * @model(create: false, update: false, find: false, findOne: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type UserDeleted_LoadsArgs = {
  filter?: Maybe<LoadFilter>;
};


/**
 * @db(name: 'users')
 * @model(create: false, update: false, find: false, findOne: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type UserDeleted_FilesArgs = {
  filter?: Maybe<FileFilter>;
};


/**
 * @db(name: 'users')
 * @model(create: false, update: false, find: false, findOne: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type UserFilesArgs = {
  filter?: Maybe<FileFilter>;
};

/**
 * @db(name: 'user_defaults')
 * @model(create: false, update: true, find: false, findOne: false, delete: false, subCreate: false, subUpdate: false, subDelete: false)
 */
export type UserDefault = Node & {
  __typename?: 'UserDefault';
  id: Scalars['ID'];
  /**
   * @unique
   * @oneToOne(field: 'user', key: 'user_id')
   */
  user: User;
  /**
   * @default(value: false)
   * @deprecated Old feature that is no longer supported
   */
  plain_text_email: Scalars['Boolean'];
  /** @default(value: false) */
  per_truck_notes: Scalars['Boolean'];
  created_at?: Maybe<Scalars['GraphbackDateTime']>;
  updated_at?: Maybe<Scalars['GraphbackDateTime']>;
};

export type UserFilter = {
  id?: Maybe<IdInput>;
  portex_admin?: Maybe<BooleanInput>;
  portex_super_admin?: Maybe<BooleanInput>;
  impersonating?: Maybe<BooleanInput>;
  auth_0_user_id?: Maybe<StringInput>;
  user_default_id?: Maybe<IdInput>;
  first_name?: Maybe<StringInput>;
  last_name?: Maybe<StringInput>;
  email?: Maybe<StringInput>;
  is_active?: Maybe<BooleanInput>;
  is_claimed?: Maybe<BooleanInput>;
  shipper_id?: Maybe<IdInput>;
  transportation_provider_id?: Maybe<IdInput>;
  last_action_at?: Maybe<GraphbackDateTimeInput>;
  job_title?: Maybe<StringInput>;
  phone_number?: Maybe<StringInput>;
  created_at?: Maybe<GraphbackDateTimeInput>;
  updated_at?: Maybe<GraphbackDateTimeInput>;
  and?: Maybe<Array<UserFilter>>;
  or?: Maybe<Array<UserFilter>>;
  not?: Maybe<UserFilter>;
};

export type ViewPublicQuoteRequestInput = {
  quoteRequestId: Scalars['ID'];
};

export enum VolumeFormat {
  Volume = 'VOLUME',
  Dimensions = 'DIMENSIONS'
}

export enum WeightUnit {
  Kg = 'KG',
  Lb = 'LB'
}

// begin: append-generated-types.ts

export enum PortexHeaders {
    TempToken = 'x-portex-temp-token',
    ApiVersion = 'x-portex-api-version',
    ClientName = 'x-portex-client-name',
    ClientVersion = 'x-portex-client-version',
    OutlookContextDiagnostics = 'x-portex-outlook-context-diagnostics',
}

export enum PortexClientName {
    Outlook = 'outlook-add-in',
    WebApp = 'portex-web-app',
    Unknown = 'unknown-client-name',
}

export enum PortexQueryParams {
    TempToken = 'tempToken',
    InitialScreen = 'initialScreen',
}

/** @see https://www.notion.so/Insights-v2-f79b9d155c94463d806de25400ced8f4 @deprecated new Insights V2 (100% enabled flag: analyticsV2Reports) makes this obsolete */
export enum InsightReportColumnsFTL {
    /** @summary this is the `id` for the `quotes` table */
    'quote id',
    'quote request id',
    'portex id',
    'PO / reference number',

    /** @summary quote's associated company_name || submitter_email */
    'partner',

    'total amount',
    'rate per truck',
    'truck quantity',

    'pickup city',
    'pickup state',
    'pickup location',
    'pickup zip',
    'pickup est date',
    'pickup est time',

    'delivery city',
    'delivery state',
    'delivery location',
    'delivery zip',
    'delivery est date',
    'delivery est time',

    'qtd transit time',

    'equipment',
    'weight',
    'KG/LB',

    'requested date',
    'booked date',
}

/** @see https://www.notion.so/Insights-v2-f79b9d155c94463d806de25400ced8f4 @deprecated new Insights V2 (100% enabled flag: analyticsV2Reports) makes this obsolete */
export enum InsightReportColumnsFCL {
    /** @summary this is the `id` for the `fcl_quotes` table */
    'quote id',
    'quote request id',
    'portex id',
    'PO / reference number',

    /** @summary quote's associated company_name || submitter_email */
    'partner',

    'total amount',
    'cost per container',
    'container quantity',
    'incoterms',
    'origin port',
    'destination port',

    'origin city',
    'origin country code',
    /** @summary address_name || address_1 || google_places_description */
    'origin location',
    'origin zip',

    'destination city',
    'destination country code',
    /** @summary address_name || address_1 || google_places_description */
    'destination location',
    'destination zip',

    'cargo ready date',
    'target delivery date',
    /** @summary This value is derived from: cargo_ready_date + max_transit_time (days) @see https://portexworkspace.slack.com/archives/C01FJR3TXR8/p1657572077805589 */
    'max est delivery date',
    'min qtd transit time',
    'max qtd transit time',

    'equipment',
    'total weight (kg)',

    'carrier',

    'requested date',
    'booked date',
}

/** @see https://www.notion.so/Insights-v2-f79b9d155c94463d806de25400ced8f4 @deprecated new Insights V2 (100% enabled flag: analyticsV2Reports) makes this obsolete */
export enum InsightReportColumnsAIR {
    /** @summary this is the `id` for the `air_quotes` table */
    'quote id',
    'quote request id',
    'portex id',
    'PO / reference number',

    /** @summary quote's associated company_name || submitter_email */
    'partner',

    'total amount',
    'freight cost per kg',
    'total weight (kg)',
    'chargeable weight',
    'cbm',
    'item quantity',

    'incoterms',
    'origin airport code',
    'destination airport code',

    'origin city',
    'origin country code',
    'origin location',
    'origin zip',

    'destination city',
    'destination country code',
    'destination location',
    'destination zip',

    'cargo ready date',
    'target delivery date',
    /** @summary This value is derived from: cargo_ready_date + max_transit_time (days) @see https://portexworkspace.slack.com/archives/C01FJR3TXR8/p1657572077805589 */
    'max est delivery date',
    'min qtd transit time',
    'max qtd transit time',

    'carrier',

    'requested date',
    'booked date',
}

/** @see https://www.notion.so/Insights-v2-f79b9d155c94463d806de25400ced8f4 @deprecated new Insights V2 (100% enabled flag: analyticsV2Reports) makes this obsolete */
export enum InsightReportColumnsLTL {
    /** @summary this is the `id` for the `ltl_quotes` table */
    'quote id',
    'quote request id',
    'portex id',
    'PO / reference number',

    /** @summary quote's associated company_name || submitter_email */
    'partner',

    'total amount',
    'goods value',

    'origin city',
    'origin country code',
    /** @summary address_name || address_1 || google_places_description */
    'origin location',
    'origin zip',
    'pickup est date',
    'pickup est time',

    'destination city',
    'destination country code',
    /** @summary address_name || address_1 || google_places_description */
    'destination location',
    'destination zip',
    'delivery est date',
    'delivery est time',

    'requested transit time',
    'min qtd transit time',
    'max qtd transit time',

    'equipment',
    'total weight (lb)',
    'freight classes',
    'nmfc codes',

    'requested date',
    'booked date',
}

/** @deprecated new Insights V2 (100% enabled flag: analyticsV2Reports) makes this obsolete */
export type InsightReportColumns =
    | typeof InsightReportColumnsAIR
    | typeof InsightReportColumnsFCL
    | typeof InsightReportColumnsFTL
    | typeof InsightReportColumnsLTL;

/**
 * @description auth0 lock options for 'initialScreen'
 * @see https://auth0.com/docs/libraries/lock/lock-configuration#initialscreen-string-
 */
export enum ShipperSignupHint {
    Signup = 'signUp',
    Login = 'login',
}

export const WEB_APP_SIGNUP_PATHNAME = 'signup';

// end: append-generated-types.ts
