import { oneMinuteInMilliseconds } from "constants/time/oneMinuteInMilliseconds";

import { QueryArgs, useGetShipperLoadsQuery } from "api/rest/loads/getShipperLoadsApi";
import isUndefined from "lodash/isUndefined";
import omitBy from "lodash/omitBy";
import { TransportationMode } from "pages/shipper/pages/quotes/types/TransportationMode";

import { useShipperLoadsIndexSliceSelector } from "../store/shipperLoadsIndexStore";

const transportationModeMap: Record<TransportationMode, QueryArgs["queryParams"]["mode"]> = {
  ALL: undefined,
  FTL: ["FTL"],
  LTL: ["LTL"],
  DRAYAGE: ["DRAYAGE"],
  TRUCKING: [],
  OCEAN: [],
  AIR: [],
  INTERMODAL: ["INTERMODAL"],
  LTL_PARTIAL: ["LTL_PARTIAL"],
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useGetShipperLoadsQueryParams = () => {
  const {
    searchQuery,
    shipmentStates,
    transportationMode,
    pickupFromFilter,
    pickupToFilter,
    deliveryFromFilter,
    deliveryToFilter,
    ownersFilter,
    onlyUnseenNotifications,
  } = useShipperLoadsIndexSliceSelector((store) => store.shipperLoadsIndexFilterSlice);

  const ownerIds = ownersFilter.map((owner) => owner.id);

  const queryParams: QueryArgs["queryParams"] = {
    mode: transportationModeMap[transportationMode],
    status: shipmentStates.length ? shipmentStates : undefined,
    search: searchQuery || undefined,
    pickup_from: pickupFromFilter,
    pickup_to: pickupToFilter,
    delivery_from: deliveryFromFilter,
    delivery_to: deliveryToFilter,
    owner: !!ownerIds.length ? ownerIds : undefined,
    only_unseen_notifications: onlyUnseenNotifications,
  };

  return omitBy(queryParams, isUndefined);
};

// Ignore this eslint error because we want the inferred return type
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useGetShipperLoads = () => {
  const queryParams = useGetShipperLoadsQueryParams();

  return {
    ...useGetShipperLoadsQuery(
      {
        queryParams: queryParams,
      },
      {
        pollingInterval: 5 * oneMinuteInMilliseconds,
      }
    ),
  };
};
