import { FC } from "react";

import { QuoteRequest } from "api/rest/quote-requests";
import { useGetBrokerQuoteRequestQuery } from "api/rest/quote-requests/getBrokerQuoteRequest";
import useLDFlag from "hooks/useLDFlag";

import LiveQuoteRequestRatesContainer from "./containers/LiveQuoteRequestRatesContainer";
import { useIsCurrentUserFeatured } from "./hooks/useIsCurrentUserFeatured";
import ConditionalGridLayout from "./views/ConditionalGridLayout";

type FeaturedBrokerDashboardProps = {
  quoteRequestGuid: QuoteRequest["guid"];
};

const FeaturedBrokerDashboard: FC<FeaturedBrokerDashboardProps> = ({ children, quoteRequestGuid }) => {
  const enableFeaturedBrokerDashboard = useLDFlag("enableFeaturedBrokerDashboard");
  const getBrokerQuoteRequestQuery = useGetBrokerQuoteRequestQuery(
    { urlParams: { quoteRequestGuid: quoteRequestGuid ?? "should-skip" } },
    { skip: !quoteRequestGuid }
  );
  const quoteRequest = getBrokerQuoteRequestQuery.data?.data.quoteRequest;
  const { isCurrentUserFeatured } = useIsCurrentUserFeatured();
  const isFeatured = isCurrentUserFeatured({ shipperId: quoteRequest?.shipper_id });

  if (!quoteRequest) {
    return null;
  }

  const displayDashboard = !!enableFeaturedBrokerDashboard && !!isFeatured;

  if (!children && displayDashboard) {
    return <LiveQuoteRequestRatesContainer quoteRequestId={quoteRequest.id} />;
  }

  return (
    <ConditionalGridLayout
      leftContent={<>{children}</>}
      rightContent={<>{displayDashboard && <LiveQuoteRequestRatesContainer quoteRequestId={quoteRequest.id} />}</>}
      displayRightContent={displayDashboard}
    />
  );
};

export default FeaturedBrokerDashboard;
