import { VFC } from "react";

import { Button, portexColor } from "@portex-pro/ui-components";
import { BrokerShipmentSource } from "api/rest/shipments/types";
import CursorPaginationTableControlsView from "components/pagination/CursorPaginationTableControlsView";
import RequestsIndexEquipmentCellView from "components/requests-index/RequestsIndexEquipmentCellView";
import RequestsIndexInfoCellView from "components/requests-index/RequestsIndexInfoCellView";
import RequestsIndexLoadCellView from "components/requests-index/RequestsIndexLoadCellView";
import RequestsIndexModeCellView from "components/requests-index/RequestsIndexModeCellView";
import RequestsIndexStopCellView from "components/requests-index/RequestsIndexStopCellView";
import TableView, { TableViewColumns, TableViewRows } from "components/TableView";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

interface BrokerRequestsIndexTableViewProps {
  items: BrokerShipmentSource[];
  paginationOptions: {
    next: () => void;
    prev: () => void;
    setTake: (take: number) => void;
    take: number;
    page: number;
    total: number;
  };
  isLoading?: boolean;
}

const BrokerRequestsIndexTableView: VFC<BrokerRequestsIndexTableViewProps> = ({
  items,
  paginationOptions,
  isLoading,
}) => {
  const { t } = useTranslation(["broker"]);

  const columns: TableViewColumns<typeof items[number]> = [
    {
      name: t("broker:requestsIndexTable_columns_name_request"),
      renderCell: (item) => <RequestsIndexInfoCellView item={item} broker />,
      cellProps: { style: { minWidth: 200, verticalAlign: "top", wordWrap: "break-word" } },
    },
    {
      name: t("broker:requestsIndexTable_columns_name_shipper"),
      renderCell: (item) => <div className="text-small">{item.shipper.name}</div>,
      cellProps: { style: { minWidth: 200, verticalAlign: "top", wordWrap: "break-word" } },
    },
    {
      name: t("broker:requestsIndexTable_columns_name_origin"),
      renderCell: (item) => <RequestsIndexStopCellView item={item} position="origin" />,
      cellProps: { style: { minWidth: 230, verticalAlign: "top" } },
    },
    {
      name: t("broker:requestsIndexTable_columns_name_destination"),
      renderCell: (item) => <RequestsIndexStopCellView item={item} position="destination" />,
      cellProps: { style: { minWidth: 230, verticalAlign: "top" } },
    },
    {
      name: t("broker:requestsIndexTable_columns_name_mode"),
      renderCell: (item) => <RequestsIndexModeCellView item={item} />,
      headerCellProps: { width: 80 },
      cellProps: { style: { verticalAlign: "top" } },
    },
    {
      name: t("broker:requestsIndexTable_columns_name_load"),
      renderCell: (item) => <RequestsIndexLoadCellView item={item} />,
      headerCellProps: { width: 60 },
      cellProps: { style: { verticalAlign: "top" } },
    },
    {
      name: t("broker:requestsIndexTable_columns_name_equipment"),
      renderCell: (item) => <RequestsIndexEquipmentCellView item={item} />,
      headerCellProps: { width: 150 },
      cellProps: { style: { verticalAlign: "top" } },
    },
  ];

  const rows: TableViewRows<typeof items[number]> = {
    headerCellProps: {
      style: {
        backgroundColor: "#fff",
        borderBottom: `1px solid ${portexColor.grey200}`,
        whiteSpace: "nowrap",
      },
    },
    endingIcon: (item) => {
      const defaultCopy = item.responses_count
        ? t("broker:quotesButton_submitAnother")
        : t(`broker:quotesButton_awaitingQuotes_${item.origin}`);
      const buttonConfigByStatus: Record<
        string,
        { copy: string; variant: "contained" | "outlined"; className?: string; disabled?: boolean } | undefined
      > = {
        REQUESTED: {
          copy: defaultCopy,
          variant: "contained",
        },
        RETURNED: {
          copy: defaultCopy,
          variant: "outlined",
          className: "[&&&]:bg-white [&&&]:hover:bg-blue-100 [&&&]:hover:text-brandBlue",
        },
        BOOKED: {
          copy: t("broker:quotesButton_viewShipment"),
          variant: "outlined",
          className:
            "[&&&]:bg-white [&&&]:text-green-500 [&&&]:border-green-500 [&&&]:hover:bg-green-100 [&&&]:hover:border-green-500 [&&&]:hover:text-green-500",
        },
        CLOSED: {
          copy: defaultCopy,
          variant: "outlined",
          className: "[&&&]:bg-white",
          disabled: true,
        },
        CANCELED: {
          copy: t("broker:quotesButton_canceled"),
          variant: "outlined",
          className: "[&&&]:bg-white [&&&]:text-red-500 [&&&]:border-red-500",
          disabled: true,
        },
        CONFIRMED: {
          copy: t("broker:quotesButton_viewShipment"),
          variant: "outlined",
          className:
            "[&&&]:bg-white [&&&]:text-green-500 [&&&]:border-green-500 [&&&]:hover:bg-green-100 [&&&]:hover:border-green-500 [&&&]:hover:text-green-500",
        },
        REJECTED: {
          copy: t("broker:quotesButton_rejected"),
          variant: "outlined",
          className:
            "[&&&]:bg-white [&&&]:text-red-500 [&&&]:border-red-500 [&&&]:hover:bg-red-100 [&&&]:hover:border-red-500 [&&&]:hover:text-red-500",
        },
      };

      const buttonConfig = buttonConfigByStatus[item.status as string];
      const url = new URL(item.broker_link);

      return (
        <Button
          style={{ minWidth: "max-content" }}
          className={buttonConfig?.className}
          disabled={buttonConfig?.disabled}
          color="primary"
          variant={buttonConfig?.variant}
          fullWidth
          component={Link}
          to={{ pathname: url.pathname, search: url.search }}
        >
          {buttonConfig?.copy}
        </Button>
      );
    },
    endingIconCellProps: {
      style: {
        verticalAlign: "top",
        width: "200px",
      },
      width: "200px",
      align: "right",
    },
  };

  return (
    <>
      <TableView
        tableProps={{ style: { borderCollapse: "separate" } }}
        rows={rows}
        columns={columns}
        items={items}
        isLoading={isLoading}
      />
      <CursorPaginationTableControlsView {...paginationOptions} />
    </>
  );
};

export default BrokerRequestsIndexTableView;
