export enum ModeEnum {
  FTL = "FTL",
  LTL = "LTL",
  FCL = "FCL",
  AIR = "AIR",
  INTERMODAL = "INTERMODAL",
  LCL = "LCL",
  DRAYAGE = "DRAYAGE",
  LTL_PARTIAL = "LTL_PARTIAL",
}

export type Mode = `${ModeEnum}`;

export type ModeQuotes = Extract<Mode, "FTL" | "LTL" | "FCL" | "AIR" | "DRAYAGE">;
export type ModeShipments = Extract<Mode, "FTL" | "LTL" | "INTERMODAL" | "DRAYAGE" | "LTL_PARTIAL">;
export type ModeRfp = Extract<Mode, "FTL" | "INTERMODAL" | "LTL_PARTIAL">;
