import { FC, VFC } from "react";

import { Button } from "@portex-pro/ui-components";
import { FeaturedBrokerQuoteRequestData } from "api/rest/featured-brokers";
import classNames from "classnames";
import Frame from "components/Frame";
import ScrollableView from "components/ScrollableView";
import StatusView from "components/StatusView";
import TableView from "components/TableView";
import take from "lodash/take";
import { useTranslation } from "react-i18next";
import { formatUSD } from "utils/formatCurrency";

import { useFeaturedBrokerQuoteAssessment } from "../hooks/useFeaturedBrokerQuoteAssessment";

type LiveQuoteRequestRatesViewProps = {
  data: FeaturedBrokerQuoteRequestData;
  numOfVisibleQuotesToDisplay: number;
  displayViewAllButton: boolean;
  onClickViewAll?: () => void;
};

const LiveQuoteRequestRatesViewFrame: FC = ({ children }) => {
  const { t } = useTranslation(["featuredBroker"]);
  return (
    <Frame title={t("featuredBroker:liveQuotesTitle")} className="w-full h-full">
      {children}
    </Frame>
  );
};

const LiveQuoteRequestRatesView: VFC<LiveQuoteRequestRatesViewProps> & { Frame: typeof Frame } = ({
  data,
  numOfVisibleQuotesToDisplay,
  displayViewAllButton,
  onClickViewAll,
}) => {
  const { t } = useTranslation(["featuredBroker"]);
  const visibleQuotes = take(data.quotes, numOfVisibleQuotesToDisplay);
  const { assessment } = useFeaturedBrokerQuoteAssessment({ data });
  const AssessmentIcon = assessment.Icon;

  let items: typeof visibleQuotes;
  if (assessment.type === "cold-no-quotes") {
    items = [{ amount: 0, isOwner: true }];
  } else if (assessment.type === "cold-awaiting-owner-quote") {
    items = [{ amount: 0, isOwner: true }, ...visibleQuotes];
  } else {
    items = visibleQuotes;
  }

  return (
    <LiveQuoteRequestRatesViewFrame>
      <div className="flex flex-col h-full">
        <div className="py-4 px-2 grid grid-cols-[45%_55%]">
          <div className="col-span-1 content-end">
            <div className="w-full justify-items-center">
              <AssessmentIcon style={{ maxWidth: 250 }} width="100%" height="100%" />
            </div>
          </div>
          <div className="col-span-1 flex flex-col leading-5 gap-y-2 justify-center px-4 max-w-[40ch]">
            <span className="font-bold text-2xl">
              {data.ownerQuote && formatUSD(data.ownerQuote.amount)}
              {assessment.type === "cold-no-quotes" && t("featuredBroker:pendingQuotesTitle")}
              {assessment.type === "cold-awaiting-owner-quote" && t("featuredBroker:pendingQuote")}
            </span>
            <span>{assessment.message}</span>
          </div>
        </div>

        <div className="flex-auto">
          <ScrollableView>
            <TableView
              items={items}
              columns={[
                {
                  name: t("featuredBroker:quoteColumn"),
                  renderCell: (item, rowIndex) => (
                    <>
                      <span className="w-6 h-6 mr-2 font-bold bg-grey-100 text-grey-500 inline-flex items-center justify-center rounded-full">
                        {rowIndex + 1}
                      </span>
                      {item.isOwner ? t("featuredBroker:you") : t("featuredBroker:quote", { count: rowIndex + 1 })}
                    </>
                  ),
                },
                {
                  name:
                    data.quoteAmountType === "PER_LOAD"
                      ? t("featuredBroker:perLoadColumn")
                      : t("featuredBroker:totalAmountColumn"),
                  renderCell: (item) => (
                    <>
                      {item.amount ? (
                        formatUSD(item.amount)
                      ) : (
                        <StatusView pill className="bg-grey-200 text-black capitalize tracking-widest">
                          {t("featuredBroker:pendingQuote")}
                        </StatusView>
                      )}
                    </>
                  ),
                },
              ]}
              tableProps={{ style: { borderCollapse: "separate" } }}
              tableContainerProps={{ style: { scrollbarWidth: "thin" } }}
              rows={{
                headerCellProps: { style: { top: -1 } }, // style fix for overscroll above sticky table header -- seems due to content rendered above the sticky table
                cellProps(item) {
                  return {
                    className: classNames({
                      "[&&]:font-bold": item.isOwner,

                      "bg-green-100": item.isOwner && assessment.type === "good",
                      "[&&]:text-green-500": item.isOwner && assessment.type === "good",

                      "bg-yellow-100": item.isOwner && assessment.type === "mild",
                      "[&&]:text-yellow-600": item.isOwner && assessment.type === "mild",

                      "bg-red-100": item.isOwner && assessment.type === "bad",
                      "[&&]:text-red-500": item.isOwner && assessment.type === "bad",
                    }),
                  };
                },
              }}
            />
          </ScrollableView>
        </div>

        <div className="p-2">
          {!!displayViewAllButton && (
            <Button fullWidth variant="outlined" color="primary" onClick={onClickViewAll}>
              {t("featuredBroker:viewAll", { count: data.quotes.length })}
            </Button>
          )}
        </div>
      </div>
    </LiveQuoteRequestRatesViewFrame>
  );
};

LiveQuoteRequestRatesView.Frame = LiveQuoteRequestRatesViewFrame;
export default LiveQuoteRequestRatesView;
