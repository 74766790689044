import React, { ComponentProps } from "react";

import classNames from "classnames";
import { useFilesContext } from "components/file-uploads/FilesControl";
import Frame from "components/Frame";

import ChatContentView from "./ChatContentView";
import ChatHeadingView from "./ChatHeadingView";

interface ChatViewProps {
  isFullScreen: boolean;
  ChatHeadingViewProps: ComponentProps<typeof ChatHeadingView>;
  ChatContentViewProps: ComponentProps<typeof ChatContentView>;
  roundedFrame?: boolean;
}

const ChatView: React.VFC<ChatViewProps> = (props) => {
  const { ChatHeadingViewProps, ChatContentViewProps, roundedFrame = true } = props;
  const { onDeleteFileFromFeature, useGetFiles } = useFilesContext();
  const allFiles = useGetFiles();

  const removeFilesOnSend = () => {
    allFiles.map((fileWrapper) => onDeleteFileFromFeature(fileWrapper.localId));
  };

  return (
    <Frame
      title={<ChatHeadingView {...ChatHeadingViewProps} />}
      style={{ height: "100%" }}
      className={classNames({ "rounded-none": !roundedFrame })}
    >
      <ChatContentView
        {...ChatContentViewProps}
        addMessage={(...args) => {
          ChatContentViewProps.addMessage(...args);
          removeFilesOnSend();
        }}
      />
    </Frame>
  );
};

export default ChatView;
