import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";

import { gql, ObservableQuery, TypedDocumentNode, useMutation } from "@apollo/client";
import { CheckCircle, Timer as TimerIcon, Info } from "@material-ui/icons";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { DatePicker } from "@material-ui/pickers";
import {
  Alert,
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Icon,
  makeStyles,
  MenuItem,
  Paper,
  SelectInput,
  TextInput,
  Tooltip,
  Typography,
} from "@portex-pro/ui-components";
import BrokerReferralsContainer from "features/broker-referrals/BrokerReferralsContainer";
import FeaturedBrokerDashboard from "features/featured-brokers/FeaturedBrokerDashboard";
import BrokerLaneBenchmark from "features/lane-benchmark/BrokerLaneBenchmark";
import useLDFlag from "hooks/useLDFlag";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import { Trans, useTranslation } from "react-i18next";
import { isQuoteRequestStateTerminal } from "utils/isQuoteRequestStateTerminal";

import {
  Maybe,
  Mutation,
  MutationSubmitQuoteArgs,
  PublicQuoteRequest,
  Query,
  QuoteRequestState,
} from "../../../../api/types/generated-types";
import HtmlTitle from "../../../../components/HtmlTitle";
import PortexAppBar from "../../../../components/PortexAppBar";
import ThrottledTextInput from "../../../../components/ThrottledTextInput";
import { NON_BREAKING_SPACE } from "../../../../constants";
import { useOnApolloError } from "../../../../hooks/useOnApolloError";
import { serializeNotes } from "../../../../utils/serializeNotes";
import { toLuxon } from "../../../../utils/toLuxon";
import { toLuxonRelative } from "../../../../utils/toLuxonRelative";
import { validateEmail } from "../../../../utils/validateEmail";
import QuoteSubmissionDetailsFTL from "../../../shipper/components/QuoteSubmissionDetailsFTL";
import { useSubmitterStore } from "../../../shipper/pages/quotes/hooks/useSubmitterStore";
import QuoteSubmissionClosed from "./QuoteSubmissionClosed";

const TOP_OF_PAGE_SELECTOR = "quote-submission-top-of-page";

const SUBMIT_QUOTE: TypedDocumentNode<{ submitQuote: Mutation["submitQuote"] }, MutationSubmitQuoteArgs> = gql`
  mutation ($input: SubmitQuoteInput!) {
    submitQuote(input: $input)
  }
`;

const renderFloat = (value: string) => {
  const [intString, decimalString] = value.split(".");

  if (intString === "") {
    return "";
  }

  const modifiedInt = new Intl.NumberFormat("en-US").format(Number(intString));

  if (decimalString === "") {
    return `${modifiedInt}.`;
  }

  return decimalString ? `${modifiedInt}.${decimalString.substring(0, 2)}` : `${modifiedInt}`;
};

const UrgentQuoteRequestAlert = ({
  deadlineRespondAt,
  booked,
}: {
  deadlineRespondAt: PublicQuoteRequest["deadline_respond_at"];
  booked: boolean;
}): ReactElement => {
  const { t } = useTranslation(["common", "broker"]);
  if (!deadlineRespondAt) return <></>;

  const luxon = toLuxon(deadlineRespondAt);

  const formattedDate = luxon.toFormat("LLL d");
  const formattedTime = luxon.toFormat("t");
  const fromNow = toLuxonRelative(luxon);
  const formatted = `${formattedDate}, ${formattedTime} (${fromNow})`;

  const variant = booked ? "standard" : "filled";

  const alertMessage = t("broker:quoteSubmission.message", { formatted });

  return (
    <Box my={2}>
      <Alert severity="error" variant={variant} icon={<TimerIcon />}>
        {booked ? <s>{alertMessage}</s> : alertMessage}
      </Alert>
    </Box>
  );
};

type FieldValidity = {
  amount: Maybe<boolean>;
  date: Maybe<boolean>;
  email: Maybe<boolean>;
};

const useStyles = makeStyles(() => ({
  tooltipBox: {
    marginBottom: "0.75em",
    "& .MuiFormLabel-root": {
      color: "rgba(0, 0, 0, 0.87)",
      fontWeight: "bold",
      lineHeight: 1.2,
    },
  },
}));

interface QuoteSubmissionFTLProps {
  publicQuoteRequest: PublicQuoteRequest;
  refetchPublicQuoteRequest: ObservableQuery<Pick<Query, "getPublicQuoteRequest">>["refetch"];
}

const QuoteSubmissionFTL = ({
  publicQuoteRequest,
  refetchPublicQuoteRequest,
}: QuoteSubmissionFTLProps): ReactElement => {
  const { t } = useTranslation(["common", "broker"]);
  // default end of day to 5pm today
  let initValidOption = DateTime.now().set({ hour: 17 }).startOf("hour");
  // if current time is after 5pm today, default to tomorrow instead
  if (initValidOption < DateTime.now()) {
    initValidOption = initValidOption.plus({ days: 1 });
  }
  const quoteValidityOptions = [
    { label: t("broker:quoteValidityOptions.endOfDay"), time: initValidOption },
    { label: t("broker:quoteValidityOptions.next30Mins"), time: DateTime.now().plus({ minutes: 30 }) },
    { label: t("broker:quoteValidityOptions.nextHour"), time: DateTime.now().plus({ hour: 1 }) },
    { label: t("broker:quoteValidityOptions.next2Hours"), time: DateTime.now().plus({ hour: 2 }) },
    { label: t("broker:quoteValidityOptions.next3Hours"), time: DateTime.now().plus({ hour: 3 }) },
    { label: t("broker:quoteValidityOptions.next4Hours"), time: DateTime.now().plus({ hour: 4 }) },
    { label: t("broker:quoteValidityOptions.next5Hours"), time: DateTime.now().plus({ hour: 5 }) },
    { label: t("broker:quoteValidityOptions.next6Hours"), time: DateTime.now().plus({ hour: 6 }) },
  ];

  const classes = useStyles();
  const { onApolloError } = useOnApolloError({ componentName: "QuoteSubmissionFTL" });
  const { enqueueSnackbar } = useSnackbar();
  const { submitterEmail, setSubmitterEmail } = useSubmitterStore();
  const enableBrokerAppBar = useLDFlag("enableBrokerAppBar");
  const enableBrokerQuotesPage = useLDFlag("enableBrokerQuotesPage");

  const [quoteSubmitted, setQuoteSubmitted] = useState<boolean>(false);
  const [amountValue, setAmountValue] = useState<string>("");
  const [email, setEmail] = useState<string>(submitterEmail);
  const [quoteNotes, setQuoteNotes] = useState<string>("");
  const [valid, setValid] = useState<FieldValidity>({
    amount: null,
    date: true, // Initial date is valid because default value is set to initValidOption
    email: null,
  });
  // Set initial values to quoteValidityOptions[0]
  const [validityTimeOption, setValidityTimeOption] = useState<number | null>(0);
  const [validUntil, setValidUntil] = useState<DateTime>(initValidOption);

  const [validityText, setValidityText] = useState("");
  useEffect(() => {
    const localeString = validUntil?.toLocaleString(DateTime.DATETIME_FULL);
    setValidityText(`${t("broker:quoteSubmission.validityText")} ${localeString}`);
  }, [t, validUntil, validityTimeOption]);

  const [submitQuote, { loading: submittingQuote }] = useMutation(SUBMIT_QUOTE, {
    onError: async (apolloError) => {
      onApolloError("submitQuote")(apolloError);
      const { data } = await refetchPublicQuoteRequest();
      if (
        data.getPublicQuoteRequest?.state === QuoteRequestState.Booked ||
        data.getPublicQuoteRequest?.state === QuoteRequestState.Closed ||
        data.getPublicQuoteRequest?.state === QuoteRequestState.Canceled
      ) {
        document.getElementById(TOP_OF_PAGE_SELECTOR)?.scrollIntoView({ behavior: "smooth" });
      }
    },
  });

  const { guid, deadline_respond_at, shipper_name, state } = publicQuoteRequest;

  const isTerminalState = isQuoteRequestStateTerminal(state);

  const canSubmitQuote = useMemo(() => {
    return (
      email && guid && amountValue && !isTerminalState && validUntil && !Object.values(valid).some((v) => v === false)
    );
  }, [amountValue, isTerminalState, email, guid, valid, validUntil]);

  const handleSubmitQuote = useCallback(async () => {
    if (canSubmitQuote && amountValue) {
      const valid_until = validUntil;
      const submitter_tz = valid_until?.toLocal().zoneName;

      if (!valid_until || !submitter_tz) return;

      const { data: submitted } = await submitQuote({
        variables: {
          input: {
            quote_request_guid: guid,
            submitter_email: email,
            valid_until: valid_until.setZone(submitter_tz, { keepLocalTime: true }).toJSDate(),
            submitter_tz: submitter_tz,
            rate_per_load: parseFloat(amountValue.replaceAll(",", "")),
            notes: serializeNotes(quoteNotes),
          },
        },
      });

      setSubmitterEmail(email);

      if (!!submitted) {
        setQuoteSubmitted(!!submitted);
        enqueueSnackbar(t("broker:quoteSubmission.successText"), {
          variant: "success",
          preventDuplicate: true,
        });
      }
    } else {
      enqueueSnackbar(t("broker:quoteSubmission.warningText"), {
        variant: "warning",
        preventDuplicate: true,
      });
    }
  }, [
    amountValue,
    canSubmitQuote,
    email,
    enqueueSnackbar,
    guid,
    quoteNotes,
    setSubmitterEmail,
    submitQuote,
    t,
    validUntil,
  ]);

  const renderAmountBreakdown = useCallback(
    (amountValue: string) => {
      const amount = renderFloat(amountValue);
      const truckCount = publicQuoteRequest.truck_quantity ?? 1;
      const total = renderFloat((Number(amountValue) * truckCount).toFixed(2));

      return (
        <Trans i18nKey="quoteSubmissionFTL.amountBreakdown" ns="broker">
          ${{ amount }} x {{ truckCount }} truck(s) = ${{ total }}
        </Trans>
      );
    },
    [publicQuoteRequest.truck_quantity]
  );

  const quoteHasExpiration = !!deadline_respond_at;
  const submitQuoteFormHeading = isTerminalState
    ? t("broker:quoteClosed")
    : quoteSubmitted
    ? t("broker:quoteSubmitted")
    : t("broker:submitQuote");

  return (
    <>
      <HtmlTitle title={`${t("broker:submitQuoteForm.title")} ${shipper_name}`} />
      <PortexAppBar
        shipperName={shipper_name}
        useMarketingUrl
        hidePortexLogo={!!enableBrokerAppBar}
        backButtonTo={!!enableBrokerAppBar && !!enableBrokerQuotesPage && `/broker/quotes`}
      />

      <FeaturedBrokerDashboard quoteRequestGuid={publicQuoteRequest.guid}>
        <Box id={TOP_OF_PAGE_SELECTOR} />
        <>
          {isTerminalState ? <QuoteSubmissionClosed publicQuoteRequest={publicQuoteRequest} /> : null}
          {quoteHasExpiration && (
            <UrgentQuoteRequestAlert deadlineRespondAt={deadline_respond_at} booked={isTerminalState} />
          )}

          <Paper className={"Por-outlined-light"} elevation={8}>
            <QuoteSubmissionDetailsFTL publicQuoteRequest={publicQuoteRequest} />

            <Box bgcolor="grey.50" px={2} py={3}>
              <form name="submitQuote" noValidate>
                <Typography variant="h6" gutterBottom>
                  <strong>{submitQuoteFormHeading}</strong>
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth margin="dense">
                      <Box className={classes.tooltipBox} display="inline-flex" alignItems="center">
                        <FormLabel required>{t("broker:submitQuoteForm.amountPerTruck.label")}</FormLabel>
                        <Box width={8} />
                        <Tooltip
                          title={
                            <Trans i18nKey="submitQuoteForm.amountPerTruck.tooltip" ns="broker">
                              Enter quote amount per load here.
                              <br />
                              This should include all fees.
                            </Trans>
                          }
                          arrow
                          placement="top-start"
                        >
                          <Icon as={Info} />
                        </Tooltip>
                      </Box>
                      <TextInput
                        value={renderFloat(amountValue)}
                        color="primary"
                        fullWidth
                        disabled={quoteSubmitted || isTerminalState}
                        error={valid.amount === false}
                        required={true}
                        startIcon={<Box ml={1}>$</Box>}
                        onChange={({ target: { value } }) => {
                          if (valid.amount === false && value !== "") {
                            setValid((v) => ({ ...v, amount: true }));
                          }

                          // Only allow number and .
                          let floatString = "";
                          if (value) {
                            floatString = value.replace(/[^0-9\.]/g, "");

                            // Only allow one decimal
                            if (floatString.split(".").length > 2) floatString = floatString.replace(/\.+$/, "");
                            if (floatString.split(".").length === 2) {
                              const parts = floatString.split(".");
                              floatString = `${parts[0]}.${parts[1].substring(0, 2)}`;
                            }
                          }

                          setAmountValue(floatString);
                        }}
                        onBlur={() => setValid((v) => ({ ...v, amount: amountValue !== "" }))}
                      />
                      <FormHelperText error={valid.amount === false}>
                        {valid.amount === false
                          ? t("broker:submitQuoteForm.amountPerTruck.helperText")
                          : amountValue
                          ? renderAmountBreakdown(amountValue)
                          : NON_BREAKING_SPACE}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <FormControl fullWidth margin="dense">
                      <Box className={classes.tooltipBox} display="inline-flex" alignItems="center">
                        <FormLabel required>{t("broker:submitQuoteForm.quoteValidity.label")}</FormLabel>
                        <Box width={8} />
                        <Tooltip title={t("broker:submitQuoteForm.quoteValidity.tooltip")} arrow placement="top-start">
                          <Icon as={Info} />
                        </Tooltip>
                      </Box>
                      <Grid container alignItems="center">
                        <Grid item xs={12} sm={5}>
                          <SelectInput
                            startIcon={<Icon as={TimerIcon} palette="grey" />}
                            fullWidth
                            style={{ flex: 1 }}
                            SelectProps={{
                              // @ts-expect-error: not sure why this is warning as a missing prop, but it works
                              renderValue: (value: string) => quoteValidityOptions[Number(value)].label,
                            }}
                            value={validityTimeOption}
                            onChange={(e) => {
                              const value = e.target.value;
                              const selectedTime = quoteValidityOptions[Number(value)].time;
                              if (valid.date === false) {
                                setValid((v) => ({ ...v, date: true }));
                              }
                              setValidityTimeOption(Number(e.target.value));
                              setValidUntil(selectedTime);
                            }}
                            onBlur={() => setValid((v) => ({ ...v, date: validUntil !== null }))}
                            error={valid.date === false || (!!valid.amount && !valid.date)}
                            disabled={quoteSubmitted || isTerminalState}
                          >
                            <MenuItem style={{ display: "none" }} disabled value={"Today"}>
                              {t("broker:quoteValidityOptions.today")}
                            </MenuItem>
                            {quoteValidityOptions.map((option, index) => {
                              return (
                                <MenuItem value={index} key={option.label}>
                                  {option.label}
                                </MenuItem>
                              );
                            })}
                          </SelectInput>
                        </Grid>
                        <Grid item xs={12} sm={1}>
                          <Typography color="textSecondary" style={{ margin: "0 0.75rem" }}>
                            <strong>{t("broker:or")}</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <DatePicker
                            // @ts-expect-error: not sure why this is warning as a missing prop, but it works
                            TextFieldComponent={TextInput}
                            placeholder={t("broker:submitQuoteForm.quoteValidity.placeholder")}
                            disableToolbar
                            disablePast
                            disabled={quoteSubmitted || isTerminalState}
                            autoOk
                            PopoverProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              onBlur: () =>
                                setValid((v) => ({
                                  ...v,
                                  date: validUntil !== null,
                                })),
                            }}
                            fullWidth
                            variant="inline"
                            color="primary"
                            error={valid.date === false || (!!valid.amount && !valid.date)}
                            required={true}
                            minDate={DateTime.now()}
                            value={validUntil}
                            onChange={(date) => {
                              if (!date) return;
                              const selectedDateAtEndOfBusinessDay = date.set({ hour: 17 }).startOf("hour");
                              if (valid.date === false) {
                                setValid((v) => ({ ...v, date: true }));
                              }
                              setValidUntil(selectedDateAtEndOfBusinessDay);
                              setValidityTimeOption(null);
                            }}
                            startIcon={<Icon as={DateRangeIcon} palette="grey" />}
                          />
                        </Grid>
                      </Grid>
                      <FormHelperText error={valid.date === false} style={{ fontSize: "14px" }}>
                        {valid.date === false
                          ? t("broker:submitQuoteForm.quoteValidity.helperText")
                          : validityText || NON_BREAKING_SPACE}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                <BrokerLaneBenchmark quoteRequest={publicQuoteRequest} />
                <FormControl fullWidth margin="dense">
                  <FormLabel required>{t("broker:submitQuoteForm.emailAddress.label")}</FormLabel>
                  <TextInput
                    disabled={quoteSubmitted || isTerminalState}
                    placeholder="your.email@example.com"
                    value={email}
                    fullWidth
                    error={valid.email === false}
                    required={true}
                    onChange={(event) => {
                      const nextEmail = event.target.value.trim();

                      if (valid.email === false && validateEmail(nextEmail)) {
                        setValid((v) => ({ ...v, email: true }));
                      }

                      setEmail(nextEmail);
                    }}
                    onBlur={() => setValid((v) => ({ ...v, email: validateEmail(email) }))}
                  />
                  <FormHelperText error>
                    {valid.email === false ? t("broker:submitQuoteForm.emailAddress.helperText") : NON_BREAKING_SPACE}
                  </FormHelperText>
                </FormControl>
                <ThrottledTextInput
                  disabled={quoteSubmitted || isTerminalState}
                  label={t("common:additionalNotes")}
                  placeholder={t("broker:submitQuoteForm.additionalNotes.placeholder")}
                  multiline
                  rows={4}
                  margin="normal"
                  value={quoteNotes}
                  onChange={setQuoteNotes}
                />

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={8}>
                    <Box>
                      {quoteHasExpiration && !quoteSubmitted ? (
                        <UrgentQuoteRequestAlert deadlineRespondAt={deadline_respond_at} booked={isTerminalState} />
                      ) : null}
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={quoteSubmitted ? 12 : 4}>
                    <Box textAlign="right" pt={2}>
                      {quoteSubmitted ? (
                        <Button
                          size="large"
                          variant={"contained"}
                          color={"primary"}
                          className="Por-bg-green"
                          fullWidth
                          startIcon={<CheckCircle style={{ fontSize: 24 }} />}
                          style={{ pointerEvents: "none" }}
                        >
                          {t("broker:submitted")}
                        </Button>
                      ) : (
                        <Button
                          size="large"
                          variant="contained"
                          color="primary"
                          fullWidth
                          onClick={handleSubmitQuote}
                          disabled={!canSubmitQuote || submittingQuote || quoteSubmitted || isTerminalState}
                        >
                          <Box py={"1px"}>{isTerminalState ? t("broker:quoteClosed") : t("broker:submitQuote")}</Box>
                        </Button>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </Box>
            <BrokerReferralsContainer isSubmitted={quoteSubmitted} brokerEmail={submitterEmail} />
          </Paper>
        </>
      </FeaturedBrokerDashboard>
    </>
  );
};

export default QuoteSubmissionFTL;
