import { oneMinuteInMilliseconds } from "constants/time/oneMinuteInMilliseconds";

import { useGetShipmentSourcesQuery } from "api/rest/shipments";
import { QueryArgs } from "api/rest/shipments/getShipmentSources/types";
import { ShipperSourceDispatchRequestStatus, ShipperSourceQuoteRequestStatus } from "api/rest/shipments/types";
import { useRequestManagementSliceSelector } from "app/pages/request-management/store/requestManagementStore";
import isUndefined from "lodash/isUndefined";
import omitBy from "lodash/omitBy";
import { TransportationMode } from "pages/shipper/pages/quotes/types/TransportationMode";

const modeFilterMap: Record<TransportationMode, QueryArgs["queryParams"]["mode"]> = {
  ALL: undefined,
  FTL: ["FTL"],
  LTL: ["LTL"],
  DRAYAGE: ["DRAYAGE"],
  TRUCKING: ["FTL", "LTL", "DRAYAGE", "LTL_PARTIAL"],
  OCEAN: ["FCL"],
  AIR: ["AIR"],
  INTERMODAL: ["INTERMODAL"],
  LTL_PARTIAL: ["LTL_PARTIAL"],
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useMakeShipmentSourcesQueryParams = () => {
  const {
    modeFilter,
    originFilter,
    statusFilter,
    searchFilter,
    pickupFromFilter,
    pickupToFilter,
    deliveryFromFilter,
    deliveryToFilter,
    ownersFilter,
    bulkEditFrom,
    bulkEditTo,
    onlyUnseenNotifications,
  } = useRequestManagementSliceSelector((state) => state.requestManagementIndexSlice);

  const ownerIds = ownersFilter.map((owner) => owner.id);

  const quoteStatusFilter = statusFilter
    .filter((status) => status.startsWith("QUOTES_"))
    .map((quoteStatus) => quoteStatus.replace("QUOTES_", "") as ShipperSourceQuoteRequestStatus);

  const dispatchStatusFilter = statusFilter
    .filter((status) => status.startsWith("DISPATCHES_"))
    .map((dispatchStatus) => dispatchStatus.replace("DISPATCHES_", "") as ShipperSourceDispatchRequestStatus);

  const queryParams: QueryArgs["queryParams"] = {
    mode: modeFilterMap[modeFilter],
    origin: !!originFilter.length ? originFilter : undefined,
    quote_request_state: !!statusFilter.length ? quoteStatusFilter : undefined,
    dispatch_request_status: !!statusFilter.length ? dispatchStatusFilter : undefined,
    search: searchFilter || undefined,
    pickup_from: pickupFromFilter ?? bulkEditFrom,
    pickup_to: pickupToFilter ?? bulkEditTo,
    delivery_from: deliveryFromFilter,
    delivery_to: deliveryToFilter,
    owner: !!ownerIds.length ? ownerIds : undefined,
    onlyUnseenNotifications,
  };

  return omitBy(queryParams, isUndefined);
};

// Ignore this eslint error because we want the inferred return type
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useRequestsList = () => {
  const queryParams = useMakeShipmentSourcesQueryParams();

  return {
    ...useGetShipmentSourcesQuery(
      {
        queryParams: queryParams,
      },
      {
        pollingInterval: 5 * oneMinuteInMilliseconds,
      }
    ),
  };
};
