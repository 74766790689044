import { FC, useEffect, useMemo, useState } from "react";

import { useMutation } from "@apollo/client";
import { Box, Grid } from "@portex-pro/ui-components";
import { useEnhancedFindFilesForQuoteQuery } from "api/graphql/fileUploads/enhancedFindFilesForQuote";
import { Mode } from "api/graphql/generated";
import { useUpdateQuoteMutation } from "api/rest/quotes/updateQuoteApi";
import { LtlQuote, QuoteRequest, QuoteStatus, WeightUnit } from "api/types/generated-types";
import ChatContainer from "components/chat/ChatContainer";
import NotFound404 from "components/errors/NotFound404";
import FilesControl from "components/file-uploads/FilesControl";
import { useOnApolloError } from "hooks/useOnApolloError";
import filter from "lodash/filter";
import find from "lodash/find";
import noop from "lodash/noop";
import orderBy from "lodash/orderBy";
import { useSnackbar } from "notistack";
import { ModeEnum } from "types/Mode";
import { useBoolean } from "usehooks-ts";
import { deserializeNotes } from "utils/deserializeNotes";
import getQuoteRequestInfo from "utils/getQuoteRequestInfo";
import { renderSerializedNotes } from "utils/renderSerializedNotes";
import { serializeNotes } from "utils/serializeNotes";

import { BOOK_QUOTE } from "../QuoteDetailsPage";
import { getQuoteInfo } from "../utils/getQuoteInfo";
import AlertBookedSuccessView from "./components/AlertBookedSuccessView";
import BookNowView from "./components/BookNowView";
import QuoteBookedView from "./components/QuoteBookedView";
import QuoteDetailsView, { QuoteDetailsViewProps } from "./components/QuoteDetailsView";
import QuoteHistoryView from "./components/QuoteHistoryView";
import QuoteInfoBannerView from "./components/QuoteInfoBannerView";
import StopDetailsEditContainer from "./components/StopDetailsEditContainer";
import PoDetailsContainer from "./FtlReviewBookingBody/PoDetails/components/PoDetailsContainer";
import CargoDetailsView from "./LtlReviewBookingBody/components/CargoDetailsView";

type LtlReviewBookingBodyProps = {
  quoteRequest: QuoteRequest;
  quoteId: string;
  shipmentId: number | null;
  onQuoteBooked?: () => Promise<unknown>;
};

const LtlReviewBookingBody: FC<LtlReviewBookingBodyProps> = ({ quoteRequest, quoteId, shipmentId, onQuoteBooked }) => {
  const { isQuoteRequestBooked, isQuoteRequestCanceled, isQuoteRequestClosed, closedAt, canceledAt } =
    getQuoteRequestInfo(quoteRequest);

  const { onApolloError } = useOnApolloError({ componentName: "FtlReviewBookingBody" });
  const { enqueueSnackbar } = useSnackbar();

  const [confirmed, setConfirmed] = useState(true);
  const isEditingStops = useBoolean(false);
  const isUpdatingStopDetails = useBoolean(false);
  const isEditingReferenceNumber = useBoolean(false);
  const [stopIndexBeingEdited, setStopIndexBeingEdited] = useState(0);
  const [bookingQuote, setBookingQuote] = useState(false);
  const [bookingNotes, setBookingNotes] = useState<LtlQuote["booking_notes"]>(null);
  const [isBookedDialogOpen, setIsBookedDialogOpen] = useState(false);

  const makeStopDetailsElementId = (index: number) => `stop-details-element-id-${index}`;

  const [bookQuote, { loading: bookQuoteLoading }] = useMutation(BOOK_QUOTE, { onError: onApolloError("bookQuote") });
  const [updateQuote] = useUpdateQuoteMutation();

  const allQuotes = useMemo(() => quoteRequest.ltl_quotes ?? [], [quoteRequest?.ltl_quotes]);

  const quote = useMemo(() => {
    return find(allQuotes, ["id", quoteId]);
  }, [allQuotes, quoteId]);

  const { data: files } = useEnhancedFindFilesForQuoteQuery(
    { requestParams: { mode: Mode.Ltl, quoteId: quote?.id ?? "" } },
    { pollingInterval: 5000, skip: !quote }
  );

  const inactiveQuotes = useMemo(() => {
    return orderBy(
      filter(allQuotes, (q) => q.status === QuoteStatus.Inactive),
      "created_at",
      "desc"
    );
  }, [allQuotes]);

  useEffect(() => {
    if (bookingNotes === null && quote?.booking_notes) {
      setBookingNotes(deserializeNotes(quote.booking_notes));
    }
  }, [bookingNotes, quote?.booking_notes]);

  const handleBookNow = async () => {
    if (!confirmed) {
      enqueueSnackbar(
        "Please review the booking details and confirm that you have read them by selecting the checkbox.",
        { variant: "warning", preventDuplicate: true }
      );
      return;
    }

    const scrollTo = (elementId: string) =>
      document.getElementById(elementId)?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });

    const unsavedSnack = () =>
      enqueueSnackbar("Please review your unsaved changes", { variant: "warning", preventDuplicate: true });

    if (isEditingStops.value) {
      const elementToScrollTo = makeStopDetailsElementId(stopIndexBeingEdited);

      scrollTo(elementToScrollTo);
      unsavedSnack();
      return;
    }

    try {
      setBookingQuote(true);
      const { data } = await bookQuote({
        variables: {
          input: {
            quote_id: quoteId,
            quote_request_id: quoteRequest.id,
            booking_notes: serializeNotes(bookingNotes),
          },
        },
      });

      if (data?.bookQuote === true) {
        onQuoteBooked?.();

        setIsBookedDialogOpen(true);
      }
    } catch (e) {
      throw e;
    } finally {
      setBookingQuote(false);
    }
  };

  const handleCloseQuoteBookedDialog = () => setIsBookedDialogOpen(false);

  if (!quote) return <NotFound404 />;

  const quoteInfo = getQuoteInfo(quote, quoteRequest);
  const { isExpired, brokerName, contactEmail } = quoteInfo;
  const brokerNotes = quote.notes ?? "";
  const carrier = quote.carrier_name ?? "";
  const mode = `Trucking - ${quoteRequest.mode}`;
  const quoteBooked = quote.status === QuoteStatus.Booked;
  const quoteCanceled = quote.status === QuoteStatus.Canceled;
  const reference = quoteRequest.reference_number;
  const trucks = quoteRequest.ltl_load_spec?.truck ? [quoteRequest.ltl_load_spec.truck] : [];

  const quoteDetails: QuoteDetailsViewProps["details"] = [
    {
      label: "PO / Reference No.",
      value: reference,
      hide: !reference,
    },
    {
      label: "Mode",
      value: mode,
    },
    {
      label: "Carrier",
      value: carrier,
    },
    {
      label: `${brokerName === contactEmail ? "Broker" : brokerName} Booking Notes`,
      value: renderSerializedNotes(brokerNotes),
      hide: !brokerNotes,
    },
  ];

  const disableBookNow =
    quoteBooked ||
    isQuoteRequestBooked ||
    bookingQuote ||
    isQuoteRequestClosed ||
    isQuoteRequestCanceled ||
    bookQuoteLoading ||
    isUpdatingStopDetails.value;

  return (
    <Box p={3}>
      <Grid container spacing={3}>
        <Grid container item md={8} spacing={2}>
          <Grid item xs={12}>
            <QuoteInfoBannerView quoteInfo={quoteInfo} omitFields={["quoteRatePerLoad"]} />
          </Grid>

          {quoteBooked ? (
            <Grid item xs={12}>
              <AlertBookedSuccessView />
            </Grid>
          ) : null}

          <Grid item xs={12}>
            <QuoteDetailsView details={quoteDetails} />

            <Grid id="reference_number_edit" item xs={12}>
              <PoDetailsContainer
                quoteRequest={quoteRequest}
                trucks={trucks}
                onEditing={isEditingReferenceNumber.setValue}
                disabled={disableBookNow}
                onUpdate={onQuoteBooked}
              />
            </Grid>

            <StopDetailsEditContainer
              quoteRequest={quoteRequest}
              disableBookNow={disableBookNow}
              makeStopDetailsElementId={makeStopDetailsElementId}
              onEditing={(isEditing, index) => {
                isEditingStops.setValue(isEditing);
                setStopIndexBeingEdited(index);
              }}
              onUpdating={isUpdatingStopDetails.setValue}
            />

            <CargoDetailsView
              packageGroups={quoteRequest.ltl_load_spec?.package_groups || []}
              totalWeight={quoteRequest.ltl_load_spec?.weight ?? 0}
              weightUnit={quoteRequest.ltl_load_spec?.weight_unit ?? WeightUnit.Lb}
            />

            <QuoteHistoryView quote={quote} inactiveQuotes={inactiveQuotes} />
          </Grid>
        </Grid>
        <QuoteBookedView
          isOpen={isBookedDialogOpen}
          handleClose={handleCloseQuoteBookedDialog}
          handleConfirm={() => noop}
          shipmentId={shipmentId}
        />
        <Grid item md={4}>
          <FilesControl
            fileIds={files?.findFilesForQuote?.map((file) => file.id) ?? []}
            dropzoneOptions={{
              disabled: disableBookNow,
            }}
            onMultiUploadSuccess={(results) => {
              return updateQuote({
                urlParams: { quoteId: Number(quote.id) },
                body: { quoteType: ModeEnum.LTL, files: results.map((result) => result.fileId) },
              }).unwrap();
            }}
          >
            <BookNowView
              bookingNotes={bookingNotes ?? quote.booking_notes ?? ""}
              brokerName={brokerName}
              canceledAt={canceledAt}
              closedAt={closedAt}
              confirmed={confirmed}
              disableBookNow={disableBookNow}
              isExpired={isExpired}
              isQuoteRequestBooked={isQuoteRequestBooked}
              isQuoteRequestCanceled={isQuoteRequestCanceled}
              isQuoteRequestClosed={isQuoteRequestClosed}
              onChangeBookingNotes={setBookingNotes}
              onChangeConfirmed={setConfirmed}
              onClickBookNow={handleBookNow}
              quoteBooked={quoteBooked}
              quoteCanceled={quoteCanceled}
              quoteTotal={quote.total_amount}
              shipmentId={shipmentId}
              mode={Mode.Ltl}
            />
          </FilesControl>
          {!!quote.conversation_id && (
            <Box mt="32px" height={600}>
              <ChatContainer conversationId={quote.conversation_id} maxFiles={4} />
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default LtlReviewBookingBody;
