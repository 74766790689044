import { useEffect, VFC } from "react";

import { ArrowBack } from "@material-ui/icons";
import { Button } from "@portex-pro/ui-components";
import ConversationsContainer from "components/chat/ConversationsContainer";
import { conversationsSliceActions } from "components/chat/store/conversationsSlice";
import PortexLink from "components/PortexLink";
import useLDFlag from "hooks/useLDFlag";
import { useTranslation } from "react-i18next";
import { Redirect, useParams } from "react-router-dom";
import { NumberParam, useQueryParam } from "use-query-params";

/** variant "quotes" is for the old quotes pages */
const QuoteConversationsContainer: VFC<{ variant: "quotes" | "requests" }> = ({ variant }) => {
  const { quoteRequestId } = useParams<{ quoteRequestId: string }>();
  const { t } = useTranslation(["chat"]);
  const enableQuoteConversationsTab = useLDFlag("enableQuoteConversationsTab");
  const setState = conversationsSliceActions.useSetState();
  const [selectedConversationIdQueryParam] = useQueryParam("selectedConversationId", NumberParam);

  useEffect(() => {
    if (!!selectedConversationIdQueryParam) {
      setState({ selectedConversationId: selectedConversationIdQueryParam });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedConversationIdQueryParam]);

  const toRequestsPage =
    variant === "requests" ? `/shipper/requests/quotes/${quoteRequestId}` : `/shipper/quotes/${quoteRequestId}`;

  if (!enableQuoteConversationsTab) {
    return <Redirect to="/shipper/requests" />;
  }

  return (
    <div className="flex flex-col h-full w-full items-start space-y-2">
      <Button color="primary" startIcon={<ArrowBack />} component={PortexLink} to={toRequestsPage}>
        {t("chat:backToQuotes")}
      </Button>

      <div className="flex flex-col h-full w-full overflow-hidden">
        <ConversationsContainer quoteRequestId={Number(quoteRequestId)} />
      </div>
    </div>
  );
};

export default QuoteConversationsContainer;
